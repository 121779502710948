import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { login_cred } from 'src/app/login-creds';

@Component({
  selector: 'app-nav-search',
  templateUrl: './nav-search.component.html',
  styleUrls: ['./nav-search.component.scss']
})
export class NavSearchComponent implements OnInit {
  public isSearch: boolean;

  constructor(private router: Router) { }

  ngOnInit() { }

  search(event) {
    if (window.localStorage.getItem('integration_type') == "1") {
      this.router.navigateByUrl('trackpayout/report/search/' + event.target.value);
      this.isSearch = false;
    }else{
      this.router.navigateByUrl('trackpayout/report-tally/search/' + event.target.value);
      this.isSearch = false;
    }
    
  }

}
