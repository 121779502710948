import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rupeeConverter'
})
export class RupeeConverterPipe implements PipeTransform {

  transform(value: number) {
    if (value == null) {
      value = 0;
    }
    return value;
  }

}
