import { NgModule } from '@angular/core';
import { CommonModule ,DecimalPipe} from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AlertModule, BreadcrumbModule, CardModule, ModalModule } from './components';
import { DataFilterPipe } from './components/data-table/data-filter.pipe';
import { TodoListRemoveDirective } from './components/todo/todo-list-remove.directive';
import { TodoCardCompleteDirective } from './components/todo/todo-card-complete.directive';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ClickOutsideModule } from 'ng-click-outside';

import { HttpClientModule } from '@angular/common/http';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

import { SpinnerComponent } from './components/spinner/spinner.component';
import { ApexChartComponent } from './components/chart/apex-chart/apex-chart.component';
import {ApexChartService} from './components/chart/apex-chart/apex-chart.service';
import { ToastComponent } from './components/toast/toast.component';
import {ToastService} from './components/toast/toast.service';
import { GalleryComponent } from './components/gallery/gallery.component';
import {LightboxModule} from 'ngx-lightbox';
import {NgxPaginationModule} from 'ngx-pagination';
import {NgxSpinnerModule} from 'ngx-spinner';
import {NgbTabsetModule} from '@ng-bootstrap/ng-bootstrap';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import {RupeeConverterPipe} from '../../trackpayout/shared/pipe/rupee-converter.pipe';
import {PaymentStatusPipe} from '../../trackpayout/shared/pipe/payment-status.pipe';
import {NvePveConverterPipe} from '../../trackpayout/shared/pipe/nve-pve-converter.pipe';

import {ExportToCsvService} from '../../trackpayout/shared/service/export-to-csv.service';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgSelectModule } from '@ng-select/ng-select';

import { ContextMenuModule, ContextMenuService } from 'ngx-contextmenu';
import { EasyGridModule } from './easy-grid/easy-grid.module';

/*import 'hammerjs';
import 'mousetrap';
import { GalleryModule } from '@ks89/angular-modal-gallery';*/

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  imports: [
    CommonModule,
    PerfectScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    AlertModule,
    CardModule,
    BreadcrumbModule,
    ModalModule,
    ClickOutsideModule,
    LightboxModule,
    HttpClientModule,
    NgxPaginationModule,
    NgxSpinnerModule,
    NgIdleKeepaliveModule.forRoot(),
    NgMultiSelectDropDownModule,
    NgSelectModule,
    NgbTabsetModule,
    TooltipModule,
    ContextMenuModule,
  ],
  exports: [
    CommonModule,
    PerfectScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    AlertModule,
    CardModule,
    BreadcrumbModule,
    ModalModule,
    DataFilterPipe,
    TodoListRemoveDirective,
    TodoCardCompleteDirective,
    ClickOutsideModule,
    SpinnerComponent,
    ApexChartComponent,
    GalleryComponent,
    ToastComponent,
    HttpClientModule,
    NgxPaginationModule,
    RupeeConverterPipe,
    PaymentStatusPipe,
    NvePveConverterPipe,
    NgxSpinnerModule,
    NgIdleKeepaliveModule,
    NgMultiSelectDropDownModule,
    NgSelectModule,
    NgbTabsetModule,
    ContextMenuModule,
    EasyGridModule,
  ],
  declarations: [
    DataFilterPipe,
    TodoListRemoveDirective,
    TodoCardCompleteDirective,
    SpinnerComponent,
    ApexChartComponent,
    ToastComponent,
    GalleryComponent,
    PaymentStatusPipe,
    RupeeConverterPipe,
    NvePveConverterPipe,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    ApexChartService,
    ToastService,
    ExportToCsvService,
    RupeeConverterPipe,
    DecimalPipe,
    ContextMenuService
  ]
})
export class SharedModule { }
