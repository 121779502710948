import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { login_cred } from '../../login-creds';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  TOKEN = 'Bearer ' + window.localStorage.getItem('token');

  constructor(private httpClient: HttpClient) { }

  get() {
    const companyId = window.localStorage.getItem('company_id');
    const logged_user = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/dashboard/standard/read/?company_id=` + companyId + '&logged_user=' + logged_user + '&fy=' + fy, { headers: headers });
  }

  getCalendarDetails() {
    const companyId = window.localStorage.getItem('company_id');
    const logged_user = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/dashboard/standard/getcalendardetails/?company_id=` + companyId + '&logged_user=' + logged_user + '&fy=' + fy, { headers: headers });
  }

  getCustomerSummary() {
    const companyId = window.localStorage.getItem('company_id');
    const logged_user = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/dashboard/standard/getCustomerSummery/?company_id=` + companyId + '&logged_user=' + logged_user + '&fy=' + fy, { headers: headers });
  }

  getByDate(date: string) {
    const companyId = window.localStorage.getItem('company_id');
    const logged_user = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/reports/bydate/?company_id=` + companyId + '&logged_user=' + logged_user + '&date=' + date + '&fy=' + fy, { headers: headers });
  }

  getByAmount() {
    const companyId = window.localStorage.getItem('company_id');
    const logged_user = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/dashboard/byamount/read/?company_id=` + companyId + '&logged_user=' + logged_user + '&fy=' + fy, { headers: headers });
  }

  //new API for demo
  getByInvoices() {
    const companyId = window.localStorage.getItem('company_id');
    const logged_user = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/dashboard/byinvoice/read/?company_id=` + companyId + '&logged_user=' + logged_user + '&fy=' + fy, { headers: headers });
  }

  getByInvoicesAmount() {
    const companyId = window.localStorage.getItem('company_id');
    const logged_user = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/dashboard/standard/readInvoiceAmount/?company_id=` + companyId + '&logged_user=' + logged_user + '&fy=' + fy, { headers: headers });
  }

  isUserAutherised() {
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/isauthorised?user_id=${userId}`, { headers: headers });
  }

  get_tally_dashboard() {
    const companyId = window.localStorage.getItem('company_id');
    const fy = window.localStorage.getItem('fy');
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/dashboard/get_tally_dashboard/?company_id=` + companyId + '&user_id=' + userId + '&fy=' + fy, { headers: headers });
  }

  //bydate_tally_bill
  get_tally_bill_by_date(date: string) {
    const companyId = window.localStorage.getItem('company_id');
    const logged_user = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/dashboard/bydate_tally_bill_by_date/?company_id=` + companyId + '&logged_user=' + logged_user + '&date=' + date + '&fy=' + fy, { headers: headers });
  }
  get_outstanding_bill_dashboard() {
    const companyId = window.localStorage.getItem('company_id');
    const fy = window.localStorage.getItem('fy');
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/settlements/bill_outstanding?company_id=${companyId}&user_id=${userId}&fy=${fy}`, { headers: headers });
  }
  get_tally_calendar_details() {
    const companyId = window.localStorage.getItem('company_id');
    const logged_user = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/dashboard/get_tally_calendar_details/?company_id=` + companyId + '&logged_user=' + logged_user + '&fy=' + fy, { headers: headers });
  }

  get_tally_ByAmount() {
    const companyId = window.localStorage.getItem('company_id');
    const logged_user = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/dashboard/get_tally_by_amount_dashboard/?company_id=` + companyId + '&logged_user=' + logged_user + '&fy=' + fy, { headers: headers });
  }

  get_all_company_consolidated_dashboard(companies: any[]) {
    const companyId = window.localStorage.getItem('company_id');
    const logged_user = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/dashboard/get_all_company_consolidated/?fy=${fy}&companies=${companies}&user_id=${userId}&company_id=${companyId}`);
  }

  getVchtypeSummry(Fromdts: string, Todts: string) {
    const companyId = window.localStorage.getItem('company_id');
    const logged_user = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    let TPO_v_fromdate = Fromdts;
    let TPO_v_todate = Todts;//'2022-03-31';
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/dashboard/get_current_month_dashboard?company_id=` + companyId + '&fromdate=' + TPO_v_fromdate + '&todate=' + TPO_v_todate, { headers: headers });
  }

  getOverCreditLimit() {
    const companyId = window.localStorage.getItem('company_id');
    const fy = window.localStorage.getItem('fy');
    const userid = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/getOvercreditlimit/?company_id=` + companyId + `&user_id=` + userid, { headers: headers });
  }

  getHighestOutstanding() {
    const companyId = window.localStorage.getItem('company_id');
    const fy = window.localStorage.getItem('fy');
    const userid = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/getHighestOutstanding/?company_id=` + companyId + `&user_id=` + userid, { headers: headers });
  }

  getAllLedger() {
    const companyId = window.localStorage.getItem('company_id');
    const fy = window.localStorage.getItem('fy');
    const userid = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/dashboard/byledger/get_all_ledger/?company_id=` + companyId + `&user_id=` + userid + `&fy=` + fy, { headers: headers });
  }

  get_Ledger_by_id(ledger_id: any) {
    const companyId = window.localStorage.getItem('company_id');
    const fy = window.localStorage.getItem('fy');
    const userid = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/dashboard/byledger/get_by_ledger/?company_id=${companyId}&user_id=${userid}&ledger_id=${ledger_id}&fy=${fy}`, { headers: headers });
  }
  get_ItemWiseQty() {
    const companyId = window.localStorage.getItem('company_id');
    const fy = window.localStorage.getItem('fy');
    const userid = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/dashboard/byledger/get_ItemWiseQty/?company_id=${companyId}&user_id=${userid}`, { headers: headers });

  }
  get_ledger_outstanding() {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/dashboard/get_ledger_outstanding_dashboard/?company_id=${companyId}&user_id=${userId}`, { headers: headers });
  }
  get_all_stock_items() {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const stock_item_name = '';
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    //return this.httpClient.get(`${environment.api_rul}/tallytcpV2/masters/get_stock_item_dashboard?company_id=${companyId}`, { headers: headers });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/masters//stockitemDetailsDashBoard?company_id=${companyId}&fy=${fy}&stockitemname=${stock_item_name}`, { headers: headers });

  }
  get_stock_items_by_id(stock_item_name: any) {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/masters/stockitemDetailsDashBoard?company_id=${companyId}&fy=${fy}&stockitemname=${stock_item_name}`, { headers: headers });
  }




  getItemValue(stockItemName: any) {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/masters/selectedstockitemDetails?company_id=${companyId}&fy=${fy}&stockitemname=${stockItemName}`, { headers: headers });
  }



  
  get_stock_item_details() {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/masters/stockitemDetailsDashBoard?company_id=${companyId}&fy=${fy}&stockitemName=`, { headers: headers });

  }

  // get_stock_items_by_id(stock_item_name) {
  //   const companyId = window.localStorage.getItem('company_id');
  //   const userId = window.localStorage.getItem('user_id');
  //   const fy = window.localStorage.getItem('fy');
  //   const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
  //   return this.httpClient.get(`${environment.api_rul}/tallytcpV2/masters/stockitemDetailsDashBoard?company_id=${companyId}&fy=${fy}&stockitemName=${stock_item_name}`, { headers: headers });
  // }


  get_topSellingItemscurmonth() {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/masters/topSellingItemscurmonth?company_id=${companyId}&fy=${fy}`, { headers: headers });

  }

  get_topSellingItemsprevmonth() {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/masters/topSellingItemsprevmonth?company_id=${companyId}&fy=${fy}`, { headers: headers });

  }

  get_yearwisedashboard(startdate: string, enddate: string) {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/yearwisedashboard?company_id=${companyId}&firstdate=${startdate}&enddate=${enddate}`, { headers: headers });
  }

  get_weeklyreport(from_date, to_date) {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/monthly_report?company_id=${companyId}&fy=${fy}&from_date=${from_date}&to_date=${to_date}`, { headers: headers });

  }

  //
  get_monthly_download_report() {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    // return this.httpClient.get(`${environment.api_rul}/tallytcpV2/send_monthly_report_email?company_id=${companyId}&fy=${fy}&pdf_request=${true}`, { headers: headers });
    window.open(`${environment.api_rul}/tallytcpV2/send_monthly_report_email/?company_id=${companyId}&fy=${fy}&pdf_request=${true}`);
  }

  get_dashboard_followupdetailst() {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/followupdetailsdashboard?company_id=${companyId}&fy=${fy}`, { headers: headers });

  }

}
