import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { MarkasdeliveredComponent } from '../app/markasdelivered/markasdelivered/markasdelivered.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { AuthGuardService } from './trackpayout/shared/service/auth-guard.service';
import { LoginGuardService } from './trackpayout/shared/service/login-guard.service';



import { QuotationstatusComponent } from './quotationstatus/quotationstatus.component';
import { BilldiscountResponseComponent } from './billdiscount-response/billdiscount-response.component';
import { LegalsupportResponseComponent } from './legalsupport-response/legalsupport-response.component';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: '313out41yap141',
        pathMatch: 'full'
      },
      {
        path: '313out41yap141',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./trackpayout/trackpayout.module').then(module => module.TrackpayoutModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./demo/dashboard/dashboard.module').then(module => module.DashboardModule)
      },
      {
        path: 'quickbooks',
        loadChildren: () => import('./quickbooks/quickbooks.module').then(module => module.QuickbooksModule)
      },
      {
        path: 'layout',
        loadChildren: () => import('./demo/pages/layout/layout.module').then(module => module.LayoutModule)
      },
      {
        path: 'basic',
        loadChildren: () => import('./demo/ui-elements/ui-basic/ui-basic.module').then(module => module.UiBasicModule)
      },
      {
        path: 'forms',
        loadChildren: () => import('./demo/pages/form-elements/form-elements.module').then(module => module.FormElementsModule)
      },
      {
        path: 'tbl-bootstrap',
        loadChildren: () => import('./demo/pages/tables/tbl-bootstrap/tbl-bootstrap.module').then(module => module.TblBootstrapModule)
      },
      {
        path: 'charts',
        loadChildren: () => import('./demo/pages/core-chart/core-chart.module').then(module => module.CoreChartModule)
      },
      {
        path: 'maps',
        loadChildren: () => import('./demo/pages/core-maps/core-maps.module').then(module => module.CoreMapsModule)
      },
      {
        path: 'sample-page',
        loadChildren: () => import('./demo/pages/sample-page/sample-page.module').then(module => module.SamplePageModule)
      }
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () => import('./demo/pages/authentication/authentication.module').then(module => module.AuthenticationModule)
      }
    ]
  },
  {
    path: 'markaspaid',
    loadChildren: () => import('./markaspaid/markaspaid.module').then(module => module.MarkaspaidModule)
  },
  {
    path: 'markasdelivered',
    loadChildren: () => import('./markasdelivered/markasdelivered.module').then(module => module.MarkasdeliveredModule)
  },
  {
    path: 'vendorform',
    loadChildren: () => import('./vendor-form/vendor-form.module').then(module => module.VendorFormModule)
  },
  // {
  //   path :'quotationstatusfeedback',
  //   loadChildren: () => import('./quotationstatusfeedback/quotationstatusfeedback.module').then(module => module.QuotationstatusfeedbackModule)
  // }
  
  { path: 'quotationstatus', component:QuotationstatusComponent  },
  { path: 'billdiscount_status', component:BilldiscountResponseComponent  },
  { path: 'legal_support_status', component:LegalsupportResponseComponent  },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
