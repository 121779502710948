import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { data } from 'jquery';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { login_cred } from '../../login-creds';

@Injectable({
  providedIn: 'root'
})

export class LeadService {
  TOKEN = 'Bearer ' + window.localStorage.getItem('token');
  constructor(private httpClient: HttpClient) { }

  public EventValue = new Subject<any>();

  EventValueChange(data) {
    // console.log("data", data)
    this.EventValue.next(data)
  }

  onboard(data) {
    const userId = window.localStorage.getItem('user_id');
    const companyId = window.localStorage.getItem('company_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.post(`${environment.api_rul}/lead/create/?company_id=${companyId}&user_id=${userId}&fy=${fy}`, data);

  }

  CreateQuote(data) {
    const userId = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const companyId = window.localStorage.getItem('company_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.post(`${environment.api_rul}/lead/quote/?company_id=` + companyId + `&user_id=` + userId + `&fy=` + fy, data);
  }

  reject(remark, id) {
    console.log(id, remark)
    const userId = window.localStorage.getItem('user_id');
    const companyId = window.localStorage.getItem('company_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/lead/reject/?company_id=` + companyId + `&user_id=` + userId + '&id=' + id + '&remark=' + remark);

  }
  approve(id) {
    // console.log(id)
    const userId = window.localStorage.getItem('user_id');
    const companyId = window.localStorage.getItem('company_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/lead/approve/?company_id=` + companyId + `&user_id=` + userId + '&id=' + id);

  }
  get(offset, limit, search_by = "", search_query = "") {
    console.log('Hell0')
    const userId = window.localStorage.getItem('user_id');
    const companyId = window.localStorage.getItem('company_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/lead/get?company_id=${companyId}&user_id=${userId}&fy=${fy}&offset=${offset}&limit=${limit}&search_col=${search_by}&search_query=${search_query}`, { headers: headers });

  }
  get_payment_status(invoice_number) {
    console.log("Method called from service")
    const userId = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const companyId = window.localStorage.getItem('company_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/lead/get_payment_status?company_id=${companyId}&user_id=${userId}&invoice_no=${invoice_number}&fy=${fy}`, { headers: headers });
  }

  get_count() {
    const userId = window.localStorage.getItem('user_id');
    const companyId = window.localStorage.getItem('company_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/lead/get_count?company_id=${companyId}&fy=${fy}&user_id=${userId}`, { headers: headers });

  }

  getProduct(lead_code) {

    const userId = window.localStorage.getItem('user_id');
    const companyId = window.localStorage.getItem('company_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/lead/getProduct/?company_id=` + companyId + `&user_id=` + userId + `&lead_code=` + lead_code);

  }

  EditLead(data) {
    const userId = window.localStorage.getItem('user_id');
    const companyId = window.localStorage.getItem('company_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.post(`${environment.api_rul}/lead/edit?company_id=` + companyId + `&user_id=` + userId, data);
  }

  DeleteProduct(id) {
    const userId = window.localStorage.getItem('user_id');
    const companyId = window.localStorage.getItem('company_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/lead/delete/?company_id=` + companyId + `&user_id=` + userId + `&id=` + id);

  }
  Add(data) {
    const userId = window.localStorage.getItem('user_id');
    const companyId = window.localStorage.getItem('company_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.post(`${environment.api_rul}/lead/addlead/?company_id=` + companyId + `&user_id=` + userId, data);

  }
  getQuote(offset, limit, search_by = "", search_query = "") {
    console.log('Hell0')
    const userId = window.localStorage.getItem('user_id');
    const companyId = window.localStorage.getItem('company_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/lead/getQuote?company_id=${companyId}&fy=${fy}&user_id=${userId}&offset=${offset}&limit=${limit}&search_col=${search_by}&search_query=${search_query}`, { headers: headers });
  }

  get_all_Quote() {
    const userId = window.localStorage.getItem('user_id');
    const companyId = window.localStorage.getItem('company_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/lead/get_all_Quote?company_id=${companyId}&user_id=${userId}&fy=${fy}`, { headers: headers });
  }

  getQuoteDetails(quote_no) {
    const userId = window.localStorage.getItem('user_id');
    const companyId = window.localStorage.getItem('company_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/lead/getQuoteDetails/?company_id=${companyId}&fy=${fy}&user_id=${userId}&quote_no=${quote_no}`, { headers: headers });
  }

  download_Quotation(quote_no) {
    const userId = window.localStorage.getItem('user_id');
    const companyId = window.localStorage.getItem('company_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    window.open(`${environment.api_rul}/lead/download_Quotation/?company_id=${companyId}&fy=${fy}&user_id=${userId}&quote_no=${quote_no}`);
  }


  get_customer() {
    const userId = window.localStorage.getItem('user_id');
    const companyId = window.localStorage.getItem('company_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/lead/get_customer?company_id=` + companyId + `&user_id=` + userId, { headers: headers });
  }
  get_customer_masters() {
    const userId = window.localStorage.getItem('user_id');
    const companyId = window.localStorage.getItem('company_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/lead/get_customer?company_id=` + companyId + `&user_id=` + userId, { headers: headers });
  }

  get_all_sales_executive() {
    const userId = window.localStorage.getItem('user_id');
    const companyId = window.localStorage.getItem('company_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/masters/sales_person/get_all_sales_executive?company_id=` + companyId + `&user_id=` + userId, { headers: headers });
  }
  get_existing_quote(lead_id) {
    const userId = window.localStorage.getItem('user_id');
    const companyId = window.localStorage.getItem('company_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/lead/get_existing_quote?company_id=` + companyId + `&user_id=` + userId + `&lead_id=` + lead_id, { headers: headers });
  }

  UpdateQuoteOrderDetail(quote_id, order_no, order_date) {
    const userId = window.localStorage.getItem('user_id');
    const companyId = window.localStorage.getItem('company_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/lead/UpdateQuoteOrderDetail?company_id=${companyId}&user_id=${userId}&quote_no=${quote_id}&order_no=${order_no}&order_date=${order_date}`, { headers: headers });
  }

  UpdateQuoteInvoiceDetail(quote_id, invoice_no, invoice_date) {
    const userId = window.localStorage.getItem('user_id');
    const companyId = window.localStorage.getItem('company_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/lead/UpdateQuoteInvoiceDetail?company_id=${companyId}&user_id=${userId}&quote_no=${quote_id}&invoice_no=${invoice_no}&invoice_date=${invoice_date}`, { headers: headers });
  }

  delete_lead(lead_no) {
    const userId = window.localStorage.getItem('user_id');
    const companyId = window.localStorage.getItem('company_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/lead/delete_lead?company_id=${companyId}&user_id=${userId}&lead_code=${lead_no}`, { headers: headers });
  }

  delete_quote(quote_no) {
    const userId = window.localStorage.getItem('user_id');
    const companyId = window.localStorage.getItem('company_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/lead/delete_quote?company_id=${companyId}&user_id=${userId}&quote_no=${quote_no}`, { headers: headers });
  }
}
