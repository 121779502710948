<div class="navbar navbar-dark bg-dark shadow-sm">
    <div class="container d-flex justify-content-between">
        <a href="" class="navbar-brand d-flex align-items-center">
            <strong>Trackpayout</strong>
        </a>
    </div>
</div>


<div class="container">
    <hr>
    <app-card cardTitle="Acknowledge Delivery for invoice ( {{invoiceno}} )" blockClass="table-border-style">
        <form [formGroup]="markasdeliveredFormGroup">
            <app-alert *ngIf="deliveredformSuccess" type="success">Thank you for your acknowledgment!</app-alert>
            <app-alert *ngIf="deliveredformInvalid" type="success">There is no submission for invoice ({{invoiceno}})!</app-alert>
            <app-alert *ngIf="deliveredformExist" type="success">Invalid details found or Acknowledge for this invoice has been already submitted!
            </app-alert>
            <app-alert *ngIf="deliveredformError" type="danger">There was a problem while acknowledging the invoice!
                Please try later</app-alert>

            <div class="form-row" *ngIf="!deliveredformError && !deliveredformExist && !deliveredformSuccess">
                <div class="col-md-6">
                    <div class="form-group">
                        <!-- <label for="description">Description</label> -->
                        <textarea formControlName="received_txt" class="form-control" id="received_txt"
                            placeholder="Message..."
                            [ngClass]="{ 'is-invalid': getFormControls.received_txt.errors && (getFormControls.received_txt.dirty || getFormControls.received_txt.touched) }"
                            required> </textarea>
                        <div *ngIf="getFormControls.received_txt.errors && (getFormControls.received_txt.dirty || getFormControls.received_txt.touched)"
                            class="invalid-feedback">
                            <div *ngIf="getFormControls.received_txt.errors && (getFormControls.received_txt.dirty || getFormControls.received_txt.touched)"
                                class="error">
                                Message is required
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-footer" *ngIf="!deliveredformError && !deliveredformExist" >
                <button type="submit" class="btn btn-primary mr-0 rounded_" [disabled]="markasdeliveredFormGroup.invalid" (click)="markAsDelivered();">Submit</button>
            </div>
        </form>
    </app-card>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "line-scale" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>
<div class="footer">
    <a href="https://contisofttechno.com" target="_blank" style="text-align: center;">
        <p> Powered By <b>Contisoft Technologies LLP</b></p>
    </a>
</div>