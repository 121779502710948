import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ReportService } from 'src/app/trackpayout/report/report.service';
import { ReportTallyService } from 'src/app/trackpayout/report-tally/report-tally.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { login_cred } from 'src/app/login-creds';
import { error } from 'protractor';
import { NgxSpinnerService } from 'ngx-spinner';
import { DashboardService } from 'src/app/trackpayout/dashboard/dashboard.service';
import { ExportToCsvService } from '../../../../../../app/trackpayout/shared/service/export-to-csv.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AuthService } from 'src/app/trackpayout/shared/service/auth.service';

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig]
})

export class NavRightComponent implements OnInit {

  offset = 1;
  limit = 10;
  FYEnabled = false;
  TodaysCount = 0;
  PaidNotificationCount = 0;
  FinancialYears: any = [];
  CompanyEnabled = false;
  Companies: any = [];
  selectedItems = [];
  dropdownSettings = {};
  dropdownList = [];
  CurrentFinancialYear = "";
  CurrentCompany = "";
  /* pagination Info */
  //initializing p to one
  p: number = 1;
  /* pagination Info */
  pageSize = 10;


  debit_amount = 0
  credit_amount = 0
  lastTallySyncDetail = "";
  itemsPerPage = 10;
  config: any = {

    itemsPerPage: this.itemsPerPage,
    currentPage: 1,
    totalItems: 0
  };
  noData = false;
  removeSuccess = false;
  removeError = false;
  InvoiceId = 0;
  Invoiceindex = 0;

  is_edit = false;
  is_delete = false;

  Invoices = [];
  InvoiceCount = 0;
  InvoiceAmountapi;
  InvoiceAmount = 0;
  integration_type_manual = false;




  dr = " Dr"
  cr = " Cr"
  constructor(private router: Router,
    private reportService: ReportService,
    private ReportTallyService: ReportTallyService,
    private httpClient: HttpClient,
    private exportService: ExportToCsvService,
    private dashboardService: DashboardService,
    private ngxService: NgxSpinnerService, private authService: AuthService) {
    this.CurrentFinancialYear = window.localStorage.getItem('fy');
    this.CurrentCompany = window.localStorage.getItem('company_id');
  }

  ngOnInit() {
    this.getFinancialYears();
    this.getCompanies();
    this.getTodaysExpiry();
    this.getTodaysTallyExpiry();
    this.readInvoices();
    this.getInvoiceAmount();
    this.getLastTallySyncDetail()




    this.dropdownList = [
      { item_id: 1, item_text: '2019-20' },
      { item_id: 2, item_text: '2018-19' },
      { item_id: 3, item_text: 'All Years' },
      { item_id: 4, item_text: '2017-18' },

    ];
    this.selectedItems = [
      // { item_id: 3, item_text: 'All Years' },

    ];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  exportToCSV() {
    if (window.localStorage.getItem('integration_type') == '1') {
      if (this.Invoices.length <= 0) {
        this.noData = true;
        setTimeout(() => {
          this.noData = false;
        }, 10000);
      } else {
        this.ngxService.show();
        this.exportService.exportCSV(this.Invoices);
        this.ngxService.hide();
      }
    } else {
      if (this.Invoices.length <= 0) {
        this.noData = true;
        setTimeout(() => {
          this.noData = false;
        }, 10000);
      } else {
        this.ngxService.show();
        this.exportService.exportTallyUpComingDueBillCSV(this.Invoices);
        this.ngxService.hide();
      }
    }

  }



  getLastTallySyncDetail() {
    this.ngxService.show();
    if (window.localStorage.getItem('integration_type') != '1') {
      this.reportService.getLastTallySyncDetail().subscribe(result => {
        if (result['status'] === 200) {
          this.lastTallySyncDetail = result['data']['last_sync_date_time']
        }
      })
    }
    this.ngxService.hide();

  }

  amountDifferentiation(value) {
    let final;
    let val = Math.abs(value)
    if (val >= 10000000) {
      final = (val / 10000000).toFixed(2) + ' Cr';
    } else if (val >= 100000) {
      final = (val / 100000).toFixed(2) + ' Lac';
    } else if (val >= 1000) {
      final = (val / 1000).toFixed(2) + ' K';
    }

    if (final == undefined) {
      return '0 K';
    }
    return final;
  }

  getInvoiceAmount() {

    if (window.localStorage.getItem('integration_type') == '1') {
      this.integration_type_manual = true
      this.dashboardService.getByInvoicesAmount().subscribe(result => {
        if (result['status'] == 200) {
          this.InvoiceAmountapi = result['data'][0]['total_amount'];
          this.InvoiceCount = result['data'][0]['count'];
          this.InvoiceAmount = this.InvoiceAmountapi;
        }
      }, error => {
        console.log(error);
      });
    } else {
      this.reportService.get_due_days_bill_oustanding_debtors(this.offset, this.limit, 1, 15).subscribe(result => {
        if (result['status'] == 200) {
          this.Invoices = result['New_Ref_Bill'];
          console.log("Hellow Shivam ", this.Invoices)
          this.Invoices = this.Invoices.filter(e => e.bill_balance_amount < 0 || e.bill_balance_amount > 0)

          this.InvoiceCount = this.Invoices.length;
          for (let i = 0; i < this.InvoiceCount; i++) {

            if (this.Invoices[i].bill_Is_cr_dr == 'Yes') {
              this.debit_amount += parseFloat(this.Invoices[i].bill_balance_amount);
              this.Invoices[i]['balance_amount'] = this.Invoices[i]['bill_balance_amount'] + this.dr;
            } else {
              this.credit_amount += parseFloat(this.Invoices[i].bill_balance_amount);
              this.Invoices[i]['balance_amount'] = this.Invoices[i]['bill_balance_amount'] + this.cr;
            }

          }

          if (this.debit_amount < this.credit_amount) {
            this.InvoiceAmount = this.credit_amount - this.debit_amount
          } else {
            this.InvoiceAmount = this.debit_amount - this.credit_amount
          }

          this.ngxService.hide();
        } else if (result['status'] == 201) {

          this.ngxService.hide();
        } else {

          this.ngxService.hide();
        }
      }, error => {
        console.log(error);
      });
    }
  }

  onItemSelect(item: any) {
  }

  onSelectAll(items: any) {
  }

  logout() {

    this.authService.logout().subscribe(result => {
      if (result['status'] == 200) {
        this.router.navigate(['/auth/signin']);
        window.localStorage.clear();
      }
    })

    // if (confirm("Are you sure you want to logout?")) {
    //   window.localStorage.clear();
    //   this.router.navigate(['/auth/signin']);
    // }

  }

  profile() {
    this.router.navigate(['/313out41yap141/profile']);
  }

  handlingnan(sgst, cgst, igst, basic) {
    if (isNaN(this.taxAmount(sgst, cgst, igst, basic))) {
      return 0;
    }
    return parseFloat(sgst) + parseFloat(cgst) + parseFloat(igst) + parseFloat(basic);
  }

  taxAmount(sgst, cgst, igst, basic) {
    return parseFloat(sgst) + parseFloat(cgst) + parseFloat(igst) + parseFloat(basic);
  }

  getTodaysExpiry() {
    this.reportService.getByDueDays(0, 0).subscribe(result => {
      if (result['status'] == 200) {
        this.TodaysCount = result['data'].length;
        console.log(this.TodaysCount);

      }
    }, error => {

    });

    this.reportService.getMarkAsPaidNotifications().subscribe(result => {
      if (result['status'] == 200) {
        this.PaidNotificationCount = result['count'];
      }
    }, error => {
      console.log(error);
    });
  }

  getTodaysTallyExpiry() {
    this.ReportTallyService.get_due_days_bill_oustanding_debtors(this.offset, this.limit, 0, 0, "", "").subscribe(result => {
      if (result['status'] === 200) {
        this.TodaysCount = result['count'];
      }
    }, error => {

    });
  }


  remainingDays(date) {
    let date1 = new Date(date);
    let date2 = new Date();
    var diff = (date1.getTime() - date2.getTime());
    return Math.ceil(diff / (1000 * 3600 * 24));
  }

  readInvoices() {
    this.ngxService.show();
    if (window.localStorage.getItem('integration_type') == '1') {
      this.integration_type_manual = true;
      this.reportService.getByDueDays(0, 7).subscribe(result => {
        if (result['status'] === 200) {
          this.Invoices = result['data'];
          if (this.Invoices.length <= 0) {
            this.noData = true;
            setTimeout(() => {
              this.noData = false;
            }, 10000);
          }
          for (var i = 0; i < this.Invoices.length; i++) {
            this.Invoices[i]['remaining_days'] = this.remainingDays(this.Invoices[i]['payment_due_date']);
            this.Invoices[i]['invoice_date'] = this.Invoices[i]['invoice_date'].substring(0, 10);
            this.Invoices[i]['payment_due_date'] = this.Invoices[i]['payment_due_date'].substring(0, 10);
            if (this.Invoices[i]['payment_date'] != null) {
              this.Invoices[i]['payment_date'] = this.Invoices[i]['payment_date'].substring(0, 10);
            }
          }
          this.ngxService.hide();
        } else {
          this.ngxService.hide();
        }
      }, error => {

      });
    } else {
      this.reportService.get_due_days_bill_oustanding_debtors(this.offset, this.limit, 1, 15).subscribe(result => {
        if (result['status'] == 200) {
          this.Invoices = result['New_Ref_Bill'];

          this.Invoices = this.Invoices.filter(e => e.bill_balance_amount < 0 || e.bill_balance_amount > 0)
          this.ngxService.hide();
        } else if (result['status'] == 201) {

          this.ngxService.hide();
        } else {

          this.ngxService.hide();
        }
      }, error => {
        console.log(error);
      });

    }
  }

  pageChanged(event) {
    this.config.currentPage = event;
  }

  itemsPerPageChanged() {
    this.config.itemsPerPage = this.itemsPerPage;
  }

  getFinancialYears() {
    let TOKEN = 'Bearer ' + window.localStorage.getItem('token');
    const companyId = window.localStorage.getItem('company_id');
    const logged_user = window.localStorage.getItem('user_id');
    const integration_type = window.localStorage.getItem('integration_type');
    if (integration_type == '1') {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': TOKEN });
      this.httpClient.get(`${environment.api_rul}/financialyear/read/?company_id=` + companyId + '&logged_user=' + logged_user, { headers: headers }).subscribe(result => {
        if (result['status'] == '200') {
          this.FinancialYears = result['data'];
        }
      }, error => {
        console.log(error);
      });
    } else {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': TOKEN });
      this.httpClient.get(`${environment.api_rul}/tallytcpV2/financialyear/read/?company_id=` + companyId + '&logged_user=' + logged_user, { headers: headers }).subscribe(result => {
        if (result['status'] == '200') {
          this.FinancialYears = result['data'];
        }
      }, error => {
        console.log(error);
      });
    }

  }

  getCompanies() {
    let TOKEN = 'Bearer ' + window.localStorage.getItem('token');
    const subscriptionId = window.localStorage.getItem('subscription_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': TOKEN });
    this.httpClient.get(`${environment.api_rul}/masters/company/read/?subscription_id=` + subscriptionId, { headers: headers }).subscribe(result => {
      if (result['status'] == '200') {
        this.Companies = result['data']['rows'];
      }
    }, error => {
      console.log(error);
    });
  }

  onFinancialYearChange(year) {
    if (year) {
      window.localStorage.setItem('fy', year);
      window.location.reload();
    }
  }

  onCompanyChange(company) {
    if (company) {
      window.localStorage.setItem('company_id', company);
      window.location.reload();
      // let TOKEN = 'Bearer ' + window.localStorage.getItem('token');
      // const company_id = window.localStorage.getItem("company_id");
      // const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': TOKEN });
      // this.httpClient.get(`${environment.api_rul}/masters/user/userbyCompanyid/read/?company_id=` + company_id, { headers: headers }).subscribe(result => {
      //   console.log(result);
      //   if (result['status'] == '200') {
      //     window.localStorage.setItem('user_id', result['data']['id']);
      //     window.location.reload();
      //   }
      // }, error => {
      //   console.log(error);
      // });
    }
  }


}
