import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { login_cred } from '../login-creds';

@Injectable({
  providedIn: 'root'
})
export class QuotationstatusService {



  TOKEN = 'Bearer ' + window.localStorage.getItem('token');
  constructor(private httpClient: HttpClient) { }

  update_quotationstatus(company_id: any,quote_no:any,fy:any,status:any,remark:any) {
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/crm/update_quotation_status/?company_id=` + company_id + `&quote_no=` + quote_no + `&fy=` + fy+ `&status=` + status+ `&remark=` + remark);
  }
}
