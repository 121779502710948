import { Injectable, Input } from '@angular/core';

export interface NavigationItem {
  task: string;
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];

}

const NavigationItemsContisoftPannel = [
  {
    id: 'trackpayout',
    // title: 'Trackpayout',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'collapse',
        classes: 'nav-item',
        icon: 'feather icon-home',
        children: [
          {
            id: 'standard',
            title: 'Standard',
            type: 'item',
            url: '/313out41yap141/dashboard/standard',
          },
          {
            id: 'amount',
            title: 'By Amount',
            type: 'item',
            url: '/313out41yap141/dashboard/amount',
          },
          {
            id: 'invoices',
            title: 'By Invoices',
            type: 'item',
            url: '/313out41yap141/dashboard/invoice',
          },
          // {
          //   id: 'analysis-report',
          //   title: 'Analysis Report',
          //   type: 'item',
          //   url: '/313out41yap141/dashboard/analysis-report',
          // },
        ]
      },
      {
        id: 'invoice',
        title: 'Invoice',
        type: 'item',
        classes: 'nav-item',
        icon: 'feather icon-plus',
        url: '/313out41yap141/invoice/create',
      },
      {
        id: 'push-invoice',
        title: 'Invoice Submission',
        type: 'item',
        classes: 'nav-item',
        icon: 'feather icon-thumbs-up',
        url: '/313out41yap141/pushinvoice',
      },
      {
        id: 'report',
        title: 'Reports',
        type: 'collapse',
        classes: 'nav-item',
        icon: 'feather icon-file-text',
        children: [
          {
            id: 'statement',
            title: 'Ledger Statement',
            type: 'item',
            url: '/313out41yap141/report/statement',
          },
          {
            id: 'all',
            title: 'All Invoices',
            type: 'item',
            url: '/313out41yap141/report/all',
          },
          // {
          //   id: 'indue',
          //   title: 'Due Invoices',
          //   type: 'item',
          //   url: '/313out41yap141/report/indue',
          // },
          // {
          //   id: 'overdue',
          //   title: 'Overdue Invoices',
          //   type: 'item',
          //   url: '/313out41yap141/report/overdue',
          // },
          {
            id: 'paid',
            title: 'Paid Invoices',
            type: 'item',
            url: '/313out41yap141/report/paid',
          },
          {
            id: 'payment-receipts',
            title: 'Payment Receipts',
            type: 'item',
            url: '/313out41yap141/report/payment-receipts',
          },
          {
            id: 'payment-updation',
            title: 'Update Payment',
            type: 'item',
            url: '/313out41yap141/report/payment-updation',
          },
          {
            id: 'unpaid',
            title: 'Unpaid Invoices',
            type: 'item',
            url: '/313out41yap141/report/unpaid',
          },
          {
            id: 'byduedays',
            title: 'By Due Days',
            type: 'collapse',
            classes: 'nav-item',
            children: [
              {
                id: 'todays',
                // tslint:disable-next-line: quotemark
                title: "Today's Payment",
                type: 'item',
                url: '/313out41yap141/report/byduedays/todays',
              },
              {
                id: '7days',
                title: '7 Days',
                type: 'item',
                url: '/313out41yap141/report/byduedays/7',
              },
              {
                id: '15days',
                title: '15 Days',
                type: 'item',
                url: '/313out41yap141/report/byduedays/15',
              },
              {
                id: '30days',
                title: '30 Days',
                type: 'item',
                url: '/313out41yap141/report/byduedays/30',
              },
              {
                id: '60days',
                title: '60 Days',
                type: 'item',
                url: '/313out41yap141/report/byduedays/60',
              },
              {
                id: '90days',
                title: '90 Days',
                type: 'item',
                url: '/313out41yap141/report/byduedays/90',
              },
              {
                id: 'toofar',
                title: 'Too Far',
                type: 'item',
                url: '/313out41yap141/report/byduedays/toofar',
              },
            ]
          },
          {
            id: 'bymasters',
            title: 'By Masters',
            type: 'collapse',
            classes: 'nav-item',
            children: [
              {
                id: 'acmanager',
                // tslint:disable-next-line: quotemark
                title: "By Account Manager",
                type: 'item',
                url: '/313out41yap141/report/bymasters/acmanager',
              },
              {
                id: 'category',
                title: 'By Category',
                type: 'item',
                url: '/313out41yap141/report/bymasters/category',
              },
              {
                id: 'customer',
                title: 'By Customer',
                type: 'item',
                url: '/313out41yap141/report/bymasters/customer',
              },
              {
                id: 'location',
                title: 'By Location',
                type: 'item',
                url: '/313out41yap141/report/bymasters/location',
              },
            ]
          },
          {
            id: 'canceled',
            title: 'Inv. Cancelation Report',
            type: 'item',
            url: '/313out41yap141/report/cancelation-report',
          },
        ]
      },
      {
        id: '',
        title: 'Ageing Report',
        type: 'item',
        classes: 'nav-item',
        icon: 'feather icon-clipboard',
        url: '/313out41yap141/report/ageing',
      },
      {
        id: 'reminder',
        title: 'Reminder',
        type: 'item',
        classes: 'nav-item',
        icon: 'feather icon-bell-off',
        url: '/313out41yap141/reminder',
      },
      {
        id: 'followup',
        title: 'Follow Up',
        type: 'item',
        classes: 'nav-item',
        icon: 'feather icon-mail',
        url: '/313out41yap141/followup',
      },
      {
        id: 'clinets-performance',
        title: 'Clients Performance',
        type: 'item',
        classes: 'nav-item',
        icon: 'feather icon-activity',
        url: '/313out41yap141/clients',
      },
      {
        id: 'task',
        title: 'Task',
        type: 'item',
        classes: 'nav-item',
        icon: 'feather icon-clock',
        url: '/313out41yap141/task',
        badge: { type: 'badge badge-pill badge-danger', title: '5' }
      },
      {
        id: 'bill-discount',
        title: 'Bill Discount',
        type: 'item',
        classes: 'nav-item',
        icon: 'feather icon-percent',
        url: '/313out41yap141/bill-discount',
      },
      {
        id: 'statutory',
        title: 'Statutory',
        type: 'collapse',
        classes: 'nav-item',
        icon: 'feather icon-clipboard',
        children: [
          {
            id: 'msme',
            title: 'MSME',
            type: 'item',
            url: '/313out41yap141/administrator/statutory/msme',
          },
          {
            id: 'gst',
            title: 'GST',
            type: 'item',
            url: '/313out41yap141/administrator/statutory/gst',
          }
        ]
      },
      {
        id: 'administrator',
        title: 'Administrator',
        type: 'collapse',
        classes: 'nav-item',
        icon: 'feather icon-settings',
        children: [
          {
            id: 'acmanager',
            title: 'Account Manager',
            type: 'item',
            url: '/313out41yap141/administrator/acmanager',
          },
          {
            id: 'bankdetails',
            title: 'Bank Details',
            type: 'item',
            url: '/313out41yap141/administrator/bankdetails',
          },
          {
            id: 'category',
            title: 'Category',
            type: 'item',
            url: '/313out41yap141/administrator/category',
          },
          {
            id: 'company',
            title: 'Company',
            type: 'item',
            url: '/313out41yap141/administrator/company',
          },
          {
            id: 'customer',
            title: 'Customer',
            type: 'item',
            url: '/313out41yap141/administrator/customer',
          },
          {
            id: 'user',
            title: 'User',
            type: 'item',
            url: '/313out41yap141/administrator/user',
          },
          {
            id: 'enablenotification',
            title: 'Enable Notification',
            type: 'item',
            url: '/313out41yap141/administrator/enablenotification',
          },
        ]
      },
      {
        id: 'contisoftpannel',
        title: 'Contisoft Pannel',
        type: 'collapse',
        classes: 'nav-item',
        icon: 'feather icon-clipboard',
        children: [
          {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/313out41yap141/contisoftpannel/dashboard',
          },
          {
            id: 'subscriptions',
            title: 'Subscriptions',
            type: 'item',
            url: '/313out41yap141/contisoftpannel/customer',
          },
          {
            id: 'companies',
            title: 'Companies',
            type: 'item',
            url: '/313out41yap141/contisoftpannel/company',
          },
        ]
      },
      {
        id: 'bankdetails',
        title: 'Banking',
        type: 'collapse',

        icon: 'feather icon-monitor',
        classes: 'nav-item',
        children: [
          // {
          //   id: 'CustomerStatement',
          //   title: 'Customer Statement',
          //   type: 'item',
          //   url: '/313out41yap141/bank',
          // },
          {
            id: 'DP-Power-Calculation',
            title: 'DP Power Calculator',
            type: 'item',
            url: '/313out41yap141/dpcalculator',
          }
        ]
      },
      {
        id: 'log',
        title: 'Log',
        type: 'collapse',
        classes: 'nav-item',
        icon: 'feather icon-clipboard',
        children: [
          {
            id: 'alllog',
            title: 'Logs',
            type: 'item',
            url: '/313out41yap141/log/all',
          },
          {
            id: 'logbyuser',
            title: 'Logs by User',
            type: 'item',
            url: '/313out41yap141/log/byuser',
          },
        ]
      },
    ]
  },
];

const NavigationItemsAdmin = [
  {
    id: 'trackpayout',
    // title: 'Trackpayout',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'collapse',
        classes: 'nav-item',
        icon: 'feather icon-home',
        children: [
          {
            id: 'standard',
            title: 'Standard',
            type: 'item',
            url: '/313out41yap141/dashboard/standard',

          },
          {
            id: 'amount',
            title: 'By Amount',
            type: 'item',
            url: '/313out41yap141/dashboard/amount',
          },
          {
            id: 'invoices',
            title: 'By Invoices',
            type: 'item',
            url: '/313out41yap141/dashboard/invoice',
          },
          // {
          //   id: 'analysis-report',
          //   title: 'Analysis Report',
          //   type: 'item',
          //   url: '/313out41yap141/dashboard/analysis-report',
          // },
        ]
      },
      {
        id: 'invoice',
        title: 'Invoice',
        type: 'item',
        classes: 'nav-item',
        icon: 'feather icon-plus',
        url: '/313out41yap141/invoice/create',
      },
      {
        id: 'push-invoice',
        title: 'Invoice Submission',
        type: 'item',
        classes: 'nav-item',
        icon: 'feather icon-thumbs-up',
        url: '/313out41yap141/pushinvoice',
      },
      {
        id: 'report',
        title: 'Reports',
        type: 'collapse',
        classes: 'nav-item',
        icon: 'feather icon-file-text',
        children: [
          {
            id: 'statement',
            title: 'Ledger Statement',
            type: 'item',
            url: '/313out41yap141/report/statement',
          },
          {
            id: 'all',
            title: 'All Invoices',
            type: 'item',
            url: '/313out41yap141/report/all',
          },
          // {
          //   id: 'indue',
          //   title: 'Due Invoices',
          //   type: 'item',
          //   url: '/313out41yap141/report/indue',
          // },
          // {
          //   id: 'overdue',
          //   title: 'Overdue Invoices',
          //   type: 'item',
          //   url: '/313out41yap141/report/overdue',
          // },
          {
            id: 'paid',
            title: 'Paid Invoices',
            type: 'item',
            url: '/313out41yap141/report/paid',
          },
          {
            id: 'payment-receipts',
            title: 'Payment Receipts',
            type: 'item',
            url: '/313out41yap141/report/payment-receipts',
          },
          {
            id: 'payment-updation',
            title: 'Update Payment',
            type: 'item',
            url: '/313out41yap141/report/payment-updation',
          },
          {
            id: 'unpaid',
            title: 'Unpaid Invoices',
            type: 'item',
            url: '/313out41yap141/report/unpaid',
          },
          {
            id: 'byduedays',
            title: 'By Due Days',
            type: 'collapse',
            classes: 'nav-item',
            children: [
              {
                id: 'todays',
                // tslint:disable-next-line: quotemark
                title: "Today's Payment",
                type: 'item',
                url: '/313out41yap141/report/byduedays/todays',
              },
              {
                id: '7days',
                title: '7 Days',
                type: 'item',
                url: '/313out41yap141/report/byduedays/7',
              },
              {
                id: '15days',
                title: '15 Days',
                type: 'item',
                url: '/313out41yap141/report/byduedays/15',
              },
              {
                id: '30days',
                title: '30 Days',
                type: 'item',
                url: '/313out41yap141/report/byduedays/30',
              },
              {
                id: '60days',
                title: '60 Days',
                type: 'item',
                url: '/313out41yap141/report/byduedays/60',
              },
              {
                id: '90days',
                title: '90 Days',
                type: 'item',
                url: '/313out41yap141/report/byduedays/90',
              },
              {
                id: 'toofar',
                title: 'Too Far',
                type: 'item',
                url: '/313out41yap141/report/byduedays/toofar',
              },
            ]
          },
          {
            id: 'bymasters',
            title: 'By Masters',
            type: 'collapse',
            classes: 'nav-item',
            children: [
              {
                id: 'acmanager',
                // tslint:disable-next-line: quotemark
                title: "By Account Manager",
                type: 'item',
                url: '/313out41yap141/report/bymasters/acmanager',
              },
              {
                id: 'category',
                title: 'By Category',
                type: 'item',
                url: '/313out41yap141/report/bymasters/category',
              },
              {
                id: 'customer',
                title: 'By Customer',
                type: 'item',
                url: '/313out41yap141/report/bymasters/customer',
              },
              {
                id: 'location',
                title: 'By Location',
                type: 'item',
                url: '/313out41yap141/report/bymasters/location',
              },
            ]
          },
          {
            id: 'canceled',
            title: 'Inv. Cancelation Report',
            type: 'item',
            url: '/313out41yap141/report/cancelation-report',
          },
        ]
      },
      {
        id: 'ageing',
        title: 'Ageing Report',
        type: 'item',
        classes: 'nav-item',
        icon: 'feather icon-clipboard',
        url: '/313out41yap141/report/ageing',
      },
      {
        id: 'reminder',
        title: 'Reminder',
        type: 'item',
        classes: 'nav-item',
        icon: 'feather icon-bell-off',
        url: '/313out41yap141/reminder',
      },
      {
        id: 'followup',
        title: 'Follow Up',
        type: 'item',
        classes: 'nav-item',
        icon: 'feather icon-mail',
        url: '/313out41yap141/followup',
      },
      {
        id: 'clinets-performance',
        title: 'Clients Performance',
        type: 'item',
        classes: 'nav-item',
        icon: 'feather icon-activity',
        url: '/313out41yap141/clients',
      },
      {
        id: 'task',
        title: 'Task',
        type: 'item',
        classes: 'nav-item',
        icon: 'feather icon-clock',
        url: '/313out41yap141/task',
        badge: { type: 'badge badge-pill badge-danger', title: '5' }
      },
      {
        id: 'bill-discount',
        title: 'Bill Discount',
        type: 'item',
        classes: 'nav-item',
        icon: 'feather icon-percent',
        url: '/313out41yap141/bill-discount',
      },
      {
        id: 'statutory',
        title: 'Statutory',
        type: 'collapse',
        classes: 'nav-item',
        icon: 'feather icon-clipboard',
        children: [
          {
            id: 'msme',
            title: 'MSME',
            type: 'item',
            url: '/313out41yap141/administrator/statutory/msme',
          },
          {
            id: 'gst',
            title: 'GST',
            type: 'item',
            url: '/313out41yap141/administrator/statutory/gst',
          }
        ]
      },
      {
        id: 'administrator',
        title: 'Administrator',
        type: 'collapse',
        classes: 'nav-item',
        icon: 'feather icon-settings',
        children: [
          {
            id: 'acmanager',
            title: 'Account Manager',
            type: 'item',
            url: '/313out41yap141/administrator/acmanager',
          },
          {
            id: 'bankdetails',
            title: 'Bank Details',
            type: 'item',
            url: '/313out41yap141/administrator/bankdetails',
          },
          {
            id: 'category',
            title: 'Category',
            type: 'item',
            url: '/313out41yap141/administrator/category',
          },
          {
            id: 'company',
            title: 'Company',
            type: 'item',
            url: '/313out41yap141/administrator/company',
          },
          {
            id: 'customer',
            title: 'Customer',
            type: 'item',
            url: '/313out41yap141/administrator/customer',
          },
          {
            id: 'user',
            title: 'User',
            type: 'item',
            url: '/313out41yap141/administrator/user',
          },
          {
            id: 'enablenotification',
            title: 'Enable Notification',
            type: 'item',
            url: '/313out41yap141/administrator/enablenotification',
          },
        ]
      },
      {
        id: 'bankdetails',
        title: 'Banking',
        type: 'collapse',

        icon: 'feather icon-monitor',
        classes: 'nav-item',
        children: [
          // {
          //   id: 'CustomerStatement',
          //   title: 'Customer Statement',
          //   type: 'item',
          //   url: '/313out41yap141/bank',
          // },
          {
            id: 'DP-Power-Calculation',
            title: 'DP Power Calculator',
            type: 'item',
            url: '/313out41yap141/dpcalculator',
          }
        ]
      },
      {
        id: 'log',
        title: 'Log',
        type: 'collapse',
        classes: 'nav-item',
        icon: 'feather icon-clipboard',
        children: [
          {
            id: 'alllog',
            title: 'Logs',
            type: 'item',
            url: '/313out41yap141/log/all',
          },
          {
            id: 'logbyuser',
            title: 'Logs by User',
            type: 'item',
            url: '/313out41yap141/log/byuser',
          },
        ]
      },
    ]
  },
];

const NavigationItemsNonAdmin = [
  {
    id: 'trackpayout',
    // title: 'Trackpayout',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'collapse',
        classes: 'nav-item',
        icon: 'feather icon-home',
        children: [
          {
            id: 'standard',
            title: 'Standard',
            type: 'item',
            url: '/313out41yap141/dashboard/standard',
          },
          {
            id: 'amount',
            title: 'By Amount',
            type: 'item',
            url: '/313out41yap141/dashboard/amount',
          },
          {
            id: 'invoices',
            title: 'By Invoices',
            type: 'item',
            url: '/313out41yap141/dashboard/invoice',
          }
        ]
      },
      {
        id: 'invoice',
        title: 'Invoice',
        type: 'item',
        classes: 'nav-item',
        icon: 'feather icon-plus',
        url: '/313out41yap141/invoice/create',
      },
      {
        id: 'report',
        title: 'Report',
        type: 'collapse',
        classes: 'nav-item',
        icon: 'feather icon-file-text',
        children: [
          {
            id: 'statement',
            title: 'Ledger Statement',
            type: 'item',
            url: '/313out41yap141/report/statement',
          },
          {
            id: 'all',
            title: 'All Invoices',
            type: 'item',
            url: '/313out41yap141/report/all',
          },
          {
            id: 'indue',
            title: 'Due Invoices',
            type: 'item',
            url: '/313out41yap141/report/indue',
          },
          {
            id: 'overdue',
            title: 'Overdue Invoices',
            type: 'item',
            url: '/313out41yap141/report/overdue',
          },
          {
            id: 'paid',
            title: 'Paid Invoices',
            type: 'item',
            url: '/313out41yap141/report/paid',
          },
          {
            id: 'unpaid',
            title: 'Unpaid Invoices',
            type: 'item',
            url: '/313out41yap141/report/unpaid',
          },
          {
            id: 'byduedays',
            title: 'By Due Days',
            type: 'collapse',
            classes: 'nav-item',
            children: [
              {
                id: 'todays',
                // tslint:disable-next-line: quotemark
                title: "Today's Payment",
                type: 'item',
                url: '/313out41yap141/report/byduedays/todays',
              },
              {
                id: '7days',
                title: '7 Days',
                type: 'item',
                url: '/313out41yap141/report/byduedays/7',
              },
              {
                id: '15days',
                title: '15 Days',
                type: 'item',
                url: '/313out41yap141/report/byduedays/15',
              },
              {
                id: '30days',
                title: '30 Days',
                type: 'item',
                url: '/313out41yap141/report/byduedays/30',
              },
              {
                id: '60days',
                title: '60 Days',
                type: 'item',
                url: '/313out41yap141/report/byduedays/60',
              },
              {
                id: '90days',
                title: '90 Days',
                type: 'item',
                url: '/313out41yap141/report/byduedays/90',
              },
              {
                id: 'toofar',
                title: 'Too Far',
                type: 'item',
                url: '/313out41yap141/report/byduedays/toofar',
              },
            ]
          },
          {
            id: 'bymasters',
            title: 'By Masters',
            type: 'collapse',
            classes: 'nav-item',
            children: [
              {
                id: 'acmanager',
                // tslint:disable-next-line: quotemark
                title: "By Account Manager",
                type: 'item',
                url: '/313out41yap141/report/bymasters/acmanager',
              },
              {
                id: 'category',
                title: 'By Category',
                type: 'item',
                url: '/313out41yap141/report/bymasters/category',
              },
              {
                id: 'customer',
                title: 'By Customer',
                type: 'item',
                url: '/313out41yap141/report/bymasters/customer',
              },
              {
                id: 'location',
                title: 'By Location',
                type: 'item',
                url: '/313out41yap141/report/bymasters/location',
              },
            ]
          },
          {
            id: 'ageing',
            title: 'Ageing Reports',
            type: 'item',
            url: '/313out41yap141/report/ageing',
          }
        ]
      },
      {
        id: 'bankdetails',
        title: 'Banking',
        type: 'collapse',

        icon: 'feather icon-monitor',
        classes: 'nav-item',
        children: [
          // {
          //   id: 'CustomerStatement',
          //   title: 'Customer Statement',
          //   type: 'item',
          //   url: '/313out41yap141/bank',
          // },
          {
            id: 'DP-Power-Calculation',
            title: 'DP Power Calculator',
            type: 'item',
            url: '/313out41yap141/dpcalculator',
          }
        ]
      },
      {
        id: 'log',
        title: 'Log',
        type: 'collapse',
        classes: 'nav-item',
        icon: 'feather icon-clipboard',
        children: [
          {
            id: 'alllog',
            title: 'Logs',
            type: 'item',
            url: '/313out41yap141/log/all',
          },
        ]
      },
    ]
  },
];

const NavigationItemsAdminTallyContisoftPannel = [
  {
    id: 'trackpayout',
    // title: 'Trackpayout',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'collapse',
        classes: 'nav-item',
        icon: 'feather icon-home',
        children: [
          {
            id: 'standard',
            title: 'Standard',
            type: 'item',
            url: '/313out41yap141/dashboard/standard',
          },
          {
            id: 'amount',
            title: 'By Amount',
            type: 'item',
            url: '/313out41yap141/dashboard/amount',
          },
          {
            id: 'ledger',
            title: 'By Ledger',
            type: 'item',
            url: '/313out41yap141/dashboard/ledger',
          },
          {
            id: 'stock-item-report',
            title: 'By Stock Item',
            type: 'item',
            url: '/313out41yap141/dashboard/stock-item-report',
          },
          {
            id: 'weekly-report',
            title: 'Monthly Report',
            type: 'item',
            url: '/313out41yap141/dashboard/weekly-report',
          },


        ]
      },
      {
        id: 'stock-item',
        title: 'Stock Summary',
        icon: 'feather icon-file-text',
        type: 'item',
        url: '/313out41yap141/stock-item',
      },
      {
        id: 'report',
        title: 'Reports',
        type: 'collapse',
        classes: 'nav-item',
        icon: 'feather icon-file-text',
        children: [
          {
            id: 'groups-tally',
            title: 'Group Outstanding',
            type: 'item',
            url: '/313out41yap141/report-tally/groups-tally',
          },
          // {
          //   id: 'post-date',
          //   title: 'Post Dated Report',
          //   type: 'item',
          //   url: '/313out41yap141/report-tally/post-date',
          // },
          {
            id: 'sale-tally',
            title: 'Sale Register',
            type: 'item',
            url: '/313out41yap141/report-tally/sale-tally',
          },
          {
            id: 'receipt-tally',
            title: 'Receipt Register',
            type: 'item',
            url: '/313out41yap141/report-tally/receipt-tally',
          },
          {
            id: 'creditnote-tally',
            title: 'Credit Note Register',
            type: 'item',
            url: '/313out41yap141/report-tally/creditnote-tally',
          },
          {
            id: 'purchase-tally',
            title: 'Purchase Register',
            type: 'item',
            url: '/313out41yap141/report-tally/purchase-tally',
          },
          {
            id: 'payment-tally',
            title: 'Payment Register',
            type: 'item',
            url: '/313out41yap141/report-tally/payment-tally',
          },
          {
            id: 'debitnote-tally',
            title: 'Debit Note Register',
            type: 'item',
            url: '/313out41yap141/report-tally/debitnote-tally',
          },
          {
            id: 'journal-tally',
            title: 'Journal Register',
            type: 'item',
            url: '/313out41yap141/report-tally/journal-tally',
          },
        ]
      },
      {
        id: 'byduedays-tally',
        title: 'By Due Days',
        type: 'collapse',
        icon: 'feather icon-clipboard',
        classes: 'nav-item',
        children: [
          {
            id: 'sundry-debtor-duedays',
            // tslint:disable-next-line: quotemark
            title: "Sundry Debtor",
            type: 'item',
            url: '/313out41yap141/report-tally/byduedays-tally/sundry-debtor-duedays',
          },
          {
            id: 'sundry-creditor-duedays',
            // tslint:disable-next-line: quotemark
            title: "Sundry Creditor",
            type: 'item',
            url: '/313out41yap141/report-tally/byduedays-tally/sundry-creditor-duedays',
          },
        ]
      },
      {
        id: 'bill-tally',
        title: 'Bills Report',
        type: 'item',
        classes: 'nav-item',
        icon: 'feather icon-clipboard',
        url: '/313out41yap141/report-tally/bill-tally',
      },
      {
        id: 'ledger-tally',
        title: 'Ledger Statement',
        type: 'collapse',
        icon: 'feather icon-book',
        classes: 'nav-item',
        children: [
          {
            id: 'sundry-debtor',
            // tslint:disable-next-line: quotemark
            title: "Sundry Debtor",
            type: 'item',
            url: '/313out41yap141/report-tally/ledger-tally/sundry-debtor',
          },
          {
            id: 'sundry-creditor',
            // tslint:disable-next-line: quotemark
            title: "Sundry Creditor",
            type: 'item',
            url: '/313out41yap141/report-tally/ledger-tally/sundry-creditor',
          },
        ]
      },
      {
        id: 'ageing',
        title: 'Ageing Report',
        type: 'item',
        classes: 'nav-item',
        icon: 'feather icon-clipboard',
        url: '/313out41yap141/report/ageing',
      },
      {
        id: 'followup',
        title: 'Follow Up',
        type: 'item',
        classes: 'nav-item',
        icon: 'feather icon-mail',
        url: '/313out41yap141/followup',
      },
      {
        id: 'clinets-performance',
        title: 'Clients Performance',
        type: 'item',
        classes: 'nav-item',
        icon: 'feather icon-activity',
        url: '/313out41yap141/clients',
      },
      {
        id: 'task',
        title: 'Task',
        type: 'item',
        classes: 'nav-item',
        icon: 'feather icon-clock',
        url: '/313out41yap141/task',
        badge: { type: 'badge badge-pill badge-danger', title: '5' }
      },
      {
        id: 'bill-discount',
        title: 'Bill Discount',
        type: 'item',
        classes: 'nav-item',
        icon: 'feather icon-percent',
        url: '/313out41yap141/bill-discount',
      },
      {
        id: 'statutory',
        title: 'Statutory',
        type: 'collapse',
        classes: 'nav-item',
        icon: 'feather icon-clipboard',
        children: [
          {
            id: 'msme',
            title: 'MSME',
            type: 'item',
            url: '/313out41yap141/administrator/statutory/msme',
          },
          {
            id: 'gst',
            title: 'GST',
            type: 'item',
            url: '/313out41yap141/administrator/statutory/gst',
          }
        ]
      },
      {
        id: 'crm_new',
        title: 'CRM',
        type: 'collapse',
        classes: 'nav-item',
        icon: 'feather icon-users',
        children: [
          {
            id: 'crm_dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/313out41yap141/crm/crm_dashboard',
          },
          {
            id: 'saleperson',
            title: 'Sales Executive',
            type: 'item',
            url: '/313out41yap141/crm/saleperson',
          },
          // {
          //   id: 'customermaster',
          //   title: 'Customer',
          //   type: 'item',
          //   url: '/313out41yap141/crm/customer-master',
          // },
          {
            id: 'lead',
            title: 'Leads',
            type: 'item',
            url: '/313out41yap141/crm/leads'
          },
          {
            id: 'quotations',
            title: 'Quotations',
            type: 'item',
            url: '/313out41yap141/crm/quotations'
          },
          {
            id: 'reports',
            title: 'Reports',
            type: 'collapse',
            classes: 'nav-item',
            // icon: 'feather icon-clipboard',
            children: [

              {
                id: 'leadreport',
                title: 'Lead Report',
                type: 'item',
                url: '/313out41yap141/crm/reports/leadreport'

              },
              {
                id: 'reports',
                title: 'Sales Executive Analyses',
                type: 'item',
                url: '/313out41yap141/crm/reports/salespersonanalysis'
              }
            ]
          }

        ]
      },
      {
        id: 'legal-support',
        title: 'Legal Support',
        type: 'item',
        classes: 'nav-item',
        icon: 'feather icon-help-circle',
        url: '/313out41yap141/legal-support',
      },
      {
        id: 'contisoftpannel',
        title: 'Contisoft Pannel',
        type: 'collapse',
        classes: 'nav-item',
        icon: 'feather icon-clipboard',
        children: [
          {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/313out41yap141/contisoftpannel/dashboard',
          },
          {
            id: 'subscriptions',
            title: 'Subscriptions',
            type: 'item',
            url: '/313out41yap141/contisoftpannel/customer',
          },
          {
            id: 'companies',
            title: 'Companies',
            type: 'item',
            url: '/313out41yap141/contisoftpannel/company',
          },
        ]
      },
      {
        id: 'administrator',
        title: 'Administrator',
        type: 'collapse',
        classes: 'nav-item',
        icon: 'feather icon-settings',
        children: [
          {
            id: 'user-tally',
            title: 'User',
            type: 'item',
            url: '/313out41yap141/administrator-tally/user-tally',
          },
          {
            id: 'ledger-tally',
            title: 'Ledger',
            type: 'item',
            url: '/313out41yap141/administrator-tally/ledger-tally',
          },
          {
            id: 'vendors',
            title: 'OnBoard Masters',
            type: 'item',
            url: '/313out41yap141/administrator-tally/vendors-tally',
          },
          {
            id: 'bankdetails',
            title: 'Bank Details',
            type: 'item',
            url: '/313out41yap141/administrator-tally/bankdetails-tally',
          },
          {
            id: 'company',
            title: 'Company',
            type: 'item',
            url: '/313out41yap141/administrator-tally/company-tally',
          },
          {
            id: 'general-config',
            title: 'General Configuration',
            type: 'item',
            url: '/313out41yap141/administrator-tally/general-config',
          },
        ]
      },
      {
        id: 'bankdetails',
        title: 'Banking',
        type: 'collapse',

        icon: 'feather icon-monitor',
        classes: 'nav-item',
        children: [
          {
            id: 'CustomerStatement',
            title: 'Customer Statement',
            type: 'item',
            url: '/313out41yap141/bank',
          },
          {
            id: 'DP-Power-Calculation',
            title: 'DP Power Calculator',
            type: 'item',
            url: '/313out41yap141/dpcalculator',
          }
        ]
      },
      {
        id: 'log',
        title: 'Log',
        type: 'collapse',
        classes: 'nav-item',
        icon: 'feather icon-clipboard',
        children: [
          {
            id: 'alllog',
            title: 'Logs',
            type: 'item',
            url: '/313out41yap141/log/all',
          },
          {
            id: 'logbyuser',
            title: 'Logs by User',
            type: 'item',
            url: '/313out41yap141/log/byuser',
          },
        ]
      },
    ]
  },
];

const NavigationItemsAdminTally = [
  {
    id: 'trackpayout',
    // title: 'Trackpayout',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'collapse',
        classes: 'nav-item',
        icon: 'feather icon-home',
        children: [
          {
            id: 'standard',
            title: 'Standard',
            type: 'item',
            url: '/313out41yap141/dashboard/standard',

          },
          {
            id: 'amount',
            title: 'By Amount',
            type: 'item',
            url: '/313out41yap141/dashboard/amount',
          },
          {
            id: 'ledger',
            title: 'By Ledger',
            type: 'item',
            url: '/313out41yap141/dashboard/ledger',
            breadcrumbs: true
          },
          {
            id: 'stock-item-report',
            title: 'By Stock Item',
            type: 'item',
            url: '/313out41yap141/dashboard/stock-item-report',
          },
          {
            id: 'weekly-report',
            title: 'Monthly Report',
            type: 'item',
            url: '/313out41yap141/dashboard/weekly-report',
          },


        ]
      },
      {
        id: 'stock-item',
        title: 'Stock Summary',
        icon: 'feather icon-file-text',
        type: 'item',
        url: '/313out41yap141/stock-item',
      },

      {
        id: 'report',
        title: 'Reports',
        type: 'collapse',
        classes: 'nav-item',
        icon: 'feather icon-file-text',
        children: [
          {
            id: 'groups-tally',
            title: 'Group Outstanding',
            type: 'item',
            url: '/313out41yap141/report-tally/groups-tally',
          },
          // {
          //   id: 'ledger-tally',
          //   title: 'Ledger Statement',
          //   type: 'collapse',
          //   classes: 'nav-item',
          //   children: [
          //     {
          //       id: 'sundry-debtor',
          //       // tslint:disable-next-line: quotemark
          //       title: "Sundry Debtor",
          //       type: 'item',
          //       url: '/313out41yap141/report-tally/ledger-tally/sundry-debtor',
          //     },
          //     {
          //       id: 'sundry-creditor',
          //       // tslint:disable-next-line: quotemark
          //       title: "Sundry Creditor",
          //       type: 'item',
          //       url: '/313out41yap141/report-tally/ledger-tally/sundry-creditor',
          //     },
          //   ]
          // },
          // {
          //   id: 'post-date',
          //   title: 'Post Dated Report',
          //   type: 'item',
          //   url: '/313out41yap141/report-tally/post-date',
          // },
          {
            id: 'sale-tally',
            title: 'Sale Register',
            type: 'item',
            url: '/313out41yap141/report-tally/sale-tally',
          },
          {
            id: 'receipt-tally',
            title: 'Receipt Register',
            type: 'item',
            url: '/313out41yap141/report-tally/receipt-tally',
          },
          {
            id: 'creditnote-tally',
            title: 'Credit Note Register',
            type: 'item',
            url: '/313out41yap141/report-tally/creditnote-tally',
          },
          {
            id: 'purchase-tally',
            title: 'Purchase Register',
            type: 'item',
            url: '/313out41yap141/report-tally/purchase-tally',
          },
          {
            id: 'payment-tally',
            title: 'Payment Register',
            type: 'item',
            url: '/313out41yap141/report-tally/payment-tally',
          },
          {
            id: 'debitnote-tally',
            title: 'Debit Note Register',
            type: 'item',
            url: '/313out41yap141/report-tally/debitnote-tally',
          },
          {
            id: 'journal-tally',
            title: 'Journal Register',
            type: 'item',
            url: '/313out41yap141/report-tally/journal-tally',
          },


        ]
      },
      {
        id: 'byduedays-tally',
        title: 'By Due Days',
        type: 'collapse',
        icon: 'feather icon-clipboard',
        classes: 'nav-item',
        children: [
          {
            id: 'sundry-debtor-duedays',
            // tslint:disable-next-line: quotemark
            title: "Sundry Debtor",
            type: 'item',
            url: '/313out41yap141/report-tally/byduedays-tally/sundry-debtor-duedays',
          },
          {
            id: 'sundry-creditor-duedays',
            // tslint:disable-next-line: quotemark
            title: "Sundry Creditor",
            type: 'item',
            url: '/313out41yap141/report-tally/byduedays-tally/sundry-creditor-duedays',
          },
        ]
      },
      {
        id: 'bill-tally',
        title: 'Bills Report',
        type: 'item',
        classes: 'nav-item',
        icon: 'feather icon-clipboard',
        url: '/313out41yap141/report-tally/bill-tally',
      },
      {
        id: 'ledger-tally',
        title: 'Ledger Statement',
        type: 'collapse',
        icon: 'feather icon-book',
        classes: 'nav-item',
        children: [
          {
            id: 'sundry-debtor',
            // tslint:disable-next-line: quotemark
            title: "Sundry Debtor",
            type: 'item',
            url: '/313out41yap141/report-tally/ledger-tally/sundry-debtor',
          },
          {
            id: 'sundry-creditor',
            // tslint:disable-next-line: quotemark
            title: "Sundry Creditor",
            type: 'item',
            url: '/313out41yap141/report-tally/ledger-tally/sundry-creditor',
          },
        ]
      },
      {
        id: 'ageing',
        title: 'Ageing Report',
        type: 'item',
        classes: 'nav-item',
        icon: 'feather icon-clipboard',
        url: '/313out41yap141/report/ageing',
      },
      {
        id: 'followup',
        title: 'Follow Up',
        type: 'item',
        classes: 'nav-item',
        icon: 'feather icon-mail',
        url: '/313out41yap141/followup',
      },
      {
        id: 'clinets-performance',
        title: 'Clients Performance',
        type: 'item',
        classes: 'nav-item',
        icon: 'feather icon-activity',
        url: '/313out41yap141/clients',
      },
      {
        id: 'task',
        title: 'Task',
        type: 'item',
        classes: 'nav-item',
        icon: 'feather icon-clock',
        url: '/313out41yap141/task',
        badge: { type: 'badge badge-pill badge-danger', title: '5' }
      },
      {
        id: 'bill-discount',
        title: 'Bill Discount',
        type: 'item',
        classes: 'nav-item',
        icon: 'feather icon-percent',
        url: '/313out41yap141/bill-discount',
      },
      {
        id: 'statutory',
        title: 'Statutory',
        type: 'collapse',
        classes: 'nav-item',
        icon: 'feather icon-clipboard',
        children: [
          {
            id: 'msme',
            title: 'MSME',
            type: 'item',
            url: '/313out41yap141/administrator/statutory/msme',
          },
          {
            id: 'gst',
            title: 'GST',
            type: 'item',
            url: '/313out41yap141/administrator/statutory/gst',
          }
        ]
      },

      {
        id: 'crm_new',
        title: 'CRM',
        type: 'collapse',
        classes: 'nav-item',
        icon: 'feather icon-users',
        children: [
          {
            id: 'crm_dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/313out41yap141/crm/crm_dashboard',
          },
          {
            id: 'saleperson',
            title: 'Sales Executive',
            type: 'item',
            url: '/313out41yap141/crm/saleperson',
          },
          // {
          //   id: 'customermaster',
          //   title: 'Customer',
          //   type: 'item',
          //   url: '/313out41yap141/crm/customer-master',
          // },
          {
            id: 'lead',
            title: 'Leads',
            type: 'item',
            url: '/313out41yap141/crm/leads'
          },
          {
            id: 'quotations',
            title: 'Quotations',
            type: 'item',
            url: '/313out41yap141/crm/quotations'
          },
          {
            id: 'reports',
            title: 'Reports',
            type: 'collapse',
            classes: 'nav-item',
            // icon: 'feather icon-clipboard',
            children: [

              {
                id: 'leadreport',
                title: 'Lead Report',
                type: 'item',
                url: '/313out41yap141/crm/reports/leadreport'

              },
              {
                id: 'reports',
                title: 'Sales Executive Analyses',
                type: 'item',
                url: '/313out41yap141/crm/reports/salespersonanalysis'
              }
            ]
          }
        ]
      },
      {
        id: 'legal-support',
        title: 'Legal Support',
        type: 'item',
        classes: 'nav-item',
        icon: 'feather icon-help-circle',
        url: '/313out41yap141/legal-support',
      },
      {
        id: 'administrator',
        title: 'Administrator',
        type: 'collapse',
        classes: 'nav-item',
        icon: 'feather icon-settings',
        children: [
          {
            id: 'user-tally',
            title: 'User',
            type: 'item',
            url: '/313out41yap141/administrator-tally/user-tally',
          },
          {
            id: 'ledger-tally',
            title: 'Ledger',
            type: 'item',
            url: '/313out41yap141/administrator-tally/ledger-tally',
          },
          {
            id: 'bankdetails',
            title: 'Bank Details',
            type: 'item',
            url: '/313out41yap141/administrator-tally/bankdetails-tally',
          },
          {
            id: 'vendors',
            title: 'OnBoard Masters',
            type: 'item',
            url: '/313out41yap141/administrator-tally/vendors-tally',
          },

          {
            id: 'company',
            title: 'Company',
            type: 'item',
            url: '/313out41yap141/administrator-tally/company-tally',
          },
          {
            id: 'general-config',
            title: 'General Configuration',
            type: 'item',
            url: '/313out41yap141/administrator-tally/general-config',
          },
          // {
          //   id: 'quickbooks',
          //   title: 'Quickbooks',
          //   type: 'item',
          //   classes: 'nav-item',
          //   // icon: 'feather icon-clock',
          //   url: '/quickbooks/quickbooks-config',
          // }

        ]
      },
      {
        id: 'bankdetails',
        title: 'Banking',
        type: 'collapse',

        icon: 'feather icon-monitor',
        classes: 'nav-item',
        children: [
          // {
          //   id: 'CustomerStatement',
          //   title: 'Customer Statement',
          //   type: 'item',
          //   url: '/313out41yap141/bank',
          // },
          {
            id: 'DP-Power-Calculation',
            title: 'DP Power Calculator',
            type: 'item',
            url: '/313out41yap141/dpcalculator',
          }
        ]
      },
      {
        id: 'log',
        title: 'Log',
        type: 'collapse',
        classes: 'nav-item',
        icon: 'feather icon-clipboard',
        children: [
          {
            id: 'alllog',
            title: 'Logs',
            type: 'item',
            url: '/313out41yap141/log/all',
          },
          {
            id: 'logbyuser',
            title: 'Logs by User',
            type: 'item',
            url: '/313out41yap141/log/byuser',
          },
        ]
      },
    ]
  },
];

const NavigationItemsNonAdminTally = [
  {
    id: 'trackpayout',
    // title: 'Trackpayout',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'collapse',
        classes: 'nav-item',
        icon: 'feather icon-home',
        children: [
          {
            id: 'standard',
            title: 'Standard',
            type: 'item',
            url: '/313out41yap141/dashboard/standard',
          },
          {
            id: 'amount',
            title: 'By Amount',
            type: 'item',
            url: '/313out41yap141/dashboard/amount',
          },
          {
            id: 'ledger',
            title: 'By Ledger',
            type: 'item',
            url: '/313out41yap141/dashboard/ledger',
          },
          {
            id: 'stock-item-report',
            title: 'By Stock Item',
            type: 'item',
            url: '/313out41yap141/dashboard/stock-item-report',
          },
          {
            id: 'weekly-report',
            title: 'Monthly Report',
            type: 'item',
            url: '/313out41yap141/dashboard/weekly-report',
          },


        ]
      },
      {
        id: 'stock-item',
        title: 'Stock Summary',
        icon: 'feather icon-file-text',
        type: 'item',
        url: '/313out41yap141/stock-item',
      },
      {
        id: 'report',
        title: 'Reports',
        type: 'collapse',
        classes: 'nav-item',
        icon: 'feather icon-file-text',
        children: [
          {
            id: 'groups-tally',
            title: 'Group Outstanding',
            type: 'item',
            url: '/313out41yap141/report-tally/groups-tally',
          },
          // {
          //   id: 'ledger-tally',
          //   title: 'Ledger Statement',
          //   type: 'collapse',
          //   classes: 'nav-item',
          //   children: [
          //     {
          //       id: 'sundry-debtor',
          //       // tslint:disable-next-line: quotemark
          //       title: "Sundry Debtor",
          //       type: 'item',
          //       url: '/313out41yap141/report-tally/ledger-tally/sundry-debtor',
          //     },
          //     {
          //       id: 'sundry-creditor',
          //       // tslint:disable-next-line: quotemark
          //       title: "Sundry Creditor",
          //       type: 'item',
          //       url: '/313out41yap141/report-tally/ledger-tally/sundry-creditor',
          //     },
          //   ]
          // },
          // {
          //   id: 'post-date',
          //   title: 'Post Dated Report',
          //   type: 'item',
          //   url: '/313out41yap141/report-tally/post-date',
          // },
          {
            id: 'sale-tally',
            title: 'Sale Register',
            type: 'item',
            url: '/313out41yap141/report-tally/sale-tally',
          },
          {
            id: 'receipt-tally',
            title: 'Receipt Register',
            type: 'item',
            url: '/313out41yap141/report-tally/receipt-tally',
          },
          {
            id: 'creditnote-tally',
            title: 'Credit Note Register',
            type: 'item',
            url: '/313out41yap141/report-tally/creditnote-tally',
          },
          {
            id: 'purchase-tally',
            title: 'Purchase Register',
            type: 'item',
            url: '/313out41yap141/report-tally/purchase-tally',
          },
          {
            id: 'payment-tally',
            title: 'Payment Register',
            type: 'item',
            url: '/313out41yap141/report-tally/payment-tally',
          },
          {
            id: 'debitnote-tally',
            title: 'Debit Note Register',
            type: 'item',
            url: '/313out41yap141/report-tally/debitnote-tally',
          },
          {
            id: 'journal-tally',
            title: 'Journal Register',
            type: 'item',
            url: '/313out41yap141/report-tally/journal-tally',
          },


        ]
      },
      {
        id: 'byduedays-tally',
        title: 'By Due Days',
        type: 'collapse',
        icon: 'feather icon-clipboard',
        classes: 'nav-item',
        children: [
          {
            id: 'sundry-debtor-duedays',
            // tslint:disable-next-line: quotemark
            title: "Sundry Debtor",
            type: 'item',
            url: '/313out41yap141/report-tally/byduedays-tally/sundry-debtor-duedays',
          },
          {
            id: 'sundry-creditor-duedays',
            // tslint:disable-next-line: quotemark
            title: "Sundry Creditor",
            type: 'item',
            url: '/313out41yap141/report-tally/byduedays-tally/sundry-creditor-duedays',
          },
        ]
      },
      {
        id: 'bill-tally',
        title: 'Bills Report',
        type: 'item',
        classes: 'nav-item',
        icon: 'feather icon-clipboard',
        url: '/313out41yap141/report-tally/bill-tally',
      },
      {
        id: 'ledger-tally',
        title: 'Ledger Statement',
        type: 'collapse',
        icon: 'feather icon-book',
        classes: 'nav-item',
        children: [
          {
            id: 'sundry-debtor',
            // tslint:disable-next-line: quotemark
            title: "Sundry Debtor",
            type: 'item',
            url: '/313out41yap141/report-tally/ledger-tally/sundry-debtor',
          },
          {
            id: 'sundry-creditor',
            // tslint:disable-next-line: quotemark
            title: "Sundry Creditor",
            type: 'item',
            url: '/313out41yap141/report-tally/ledger-tally/sundry-creditor',
          },
        ]
      },
      {
        id: 'ageing',
        title: 'Ageing Report',
        type: 'item',
        classes: 'nav-item',
        icon: 'feather icon-clipboard',
        url: '/313out41yap141/report/ageing',
      },
      {
        id: 'followup',
        title: 'Follow Up',
        type: 'item',
        classes: 'nav-item',
        icon: 'feather icon-mail',
        url: '/313out41yap141/followup',
      },
      {
        id: 'clinets-performance',
        title: 'Clients Performance',
        type: 'item',
        classes: 'nav-item',
        icon: 'feather icon-activity',
        url: '/313out41yap141/clients',
      },
      {
        id: 'task',
        title: 'Task',
        type: 'item',
        classes: 'nav-item',
        icon: 'feather icon-clock',
        url: '/313out41yap141/task',
        badge: { type: 'badge badge-pill badge-danger', title: '5' }
      },
      {
        id: 'bill-discount',
        title: 'Bill Discount',
        type: 'item',
        classes: 'nav-item',
        icon: 'feather icon-percent',
        url: '/313out41yap141/bill-discount',
      },
      {
        id: 'statutory',
        title: 'Statutory',
        type: 'collapse',
        classes: 'nav-item',
        icon: 'feather icon-clipboard',
        children: [
          {
            id: 'msme',
            title: 'MSME',
            type: 'item',
            url: '/313out41yap141/administrator/statutory/msme',
          },
          {
            id: 'gst',
            title: 'GST',
            type: 'item',
            url: '/313out41yap141/administrator/statutory/gst',
          }
        ]
      },
      {
        id: 'legal-support',
        title: 'Legal Support',
        type: 'item',
        classes: 'nav-item',
        icon: 'feather icon-help-circle',
        url: '/313out41yap141/legal-support',
      },
      {
        id: 'bankdetails',
        title: 'Banking',
        type: 'collapse',

        icon: 'feather icon-monitor',
        classes: 'nav-item',
        children: [
          // {
          //   id: 'CustomerStatement',
          //   title: 'Customer Statement',
          //   type: 'item',
          //   url: '/313out41yap141/bank',
          // },
          {
            id: 'DP-Power-Calculation',
            title: 'DP Power Calculator',
            type: 'item',
            url: '/313out41yap141/dpcalculator',
          }
        ]
      },
      {
        id: 'log',
        title: 'Log',
        type: 'collapse',
        classes: 'nav-item',
        icon: 'feather icon-clipboard',
        children: [
          {
            id: 'alllog',
            title: 'Logs',
            type: 'item',
            url: '/313out41yap141/log/all',
          },
        ]
      },
    ]
  },
]
const NavigationItemsNonAdminCRMTally = [
  {
    id: 'trackpayout',
    // title: 'Trackpayout',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'collapse',
        classes: 'nav-item',
        icon: 'feather icon-home',
        children: [
          {
            id: 'standard',
            title: 'Standard',
            type: 'item',
            url: '/313out41yap141/dashboard/standard',
          },
          {
            id: 'amount',
            title: 'By Amount',
            type: 'item',
            url: '/313out41yap141/dashboard/amount',
          },
          {
            id: 'ledger',
            title: 'By Ledger',
            type: 'item',
            url: '/313out41yap141/dashboard/ledger',
          },
          {
            id: 'stock-item-report',
            title: 'By Stock Item',
            type: 'item',
            url: '/313out41yap141/dashboard/stock-item-report',
          },
          {
            id: 'weekly-report',
            title: 'Monthly Report',
            type: 'item',
            url: '/313out41yap141/dashboard/weekly-report',
          },


        ]
      },
      {
        id: 'report',
        title: 'Reports',
        type: 'collapse',
        classes: 'nav-item',
        icon: 'feather icon-file-text',
        children: [
          {
            id: 'groups-tally',
            title: 'Group Outstanding',
            type: 'item',
            url: '/313out41yap141/report-tally/groups-tally',
          },
          // {
          //   id: 'post-date',
          //   title: 'Post Dated Report',
          //   type: 'item',
          //   url: '/313out41yap141/report-tally/post-date',
          // },
          {
            id: 'sale-tally',
            title: 'Sale Register',
            type: 'item',
            url: '/313out41yap141/report-tally/sale-tally',
          },
          {
            id: 'receipt-tally',
            title: 'Receipt Register',
            type: 'item',
            url: '/313out41yap141/report-tally/receipt-tally',
          },
          {
            id: 'creditnote-tally',
            title: 'Credit Note Register',
            type: 'item',
            url: '/313out41yap141/report-tally/creditnote-tally',
          },
          {
            id: 'purchase-tally',
            title: 'Purchase Register',
            type: 'item',
            url: '/313out41yap141/report-tally/purchase-tally',
          },
          {
            id: 'payment-tally',
            title: 'Payment Register',
            type: 'item',
            url: '/313out41yap141/report-tally/payment-tally',
          },
          {
            id: 'debitnote-tally',
            title: 'Debit Note Register',
            type: 'item',
            url: '/313out41yap141/report-tally/debitnote-tally',
          },
          {
            id: 'journal-tally',
            title: 'Journal Register',
            type: 'item',
            url: '/313out41yap141/report-tally/journal-tally',
          },
          {
            id: 'ageing',
            title: 'Ageing Report',
            type: 'item',
            classes: 'nav-item',
            //icon: 'feather icon-clock',
            url: '/313out41yap141/report/ageing',
          },
        ]
      },
      {
        id: 'byduedays-tally',
        title: 'By Due Days',
        type: 'collapse',
        icon: 'feather icon-clipboard',
        classes: 'nav-item',
        children: [
          {
            id: 'sundry-debtor-duedays',
            // tslint:disable-next-line: quotemark
            title: "Sundry Debtor",
            type: 'item',
            url: '/313out41yap141/report-tally/byduedays-tally/sundry-debtor-duedays',
          },
          {
            id: 'sundry-creditor-duedays',
            // tslint:disable-next-line: quotemark
            title: "Sundry Creditor",
            type: 'item',
            url: '/313out41yap141/report-tally/byduedays-tally/sundry-creditor-duedays',
          },
        ]
      },
      {
        id: 'bill-tally',
        title: 'Bills Report',
        type: 'item',
        classes: 'nav-item',
        icon: 'feather icon-clipboard',
        url: '/313out41yap141/report-tally/bill-tally',
      },
      {
        id: 'ledger-tally',
        title: 'Ledger Statement',
        type: 'collapse',
        icon: 'feather icon-book',
        classes: 'nav-item',
        children: [
          {
            id: 'sundry-debtor',
            // tslint:disable-next-line: quotemark
            title: "Sundry Debtor",
            type: 'item',
            url: '/313out41yap141/report-tally/ledger-tally/sundry-debtor',
          },
          {
            id: 'sundry-creditor',
            // tslint:disable-next-line: quotemark
            title: "Sundry Creditor",
            type: 'item',
            url: '/313out41yap141/report-tally/ledger-tally/sundry-creditor',
          },
        ]
      },
      {
        id: 'ageing',
        title: ' Report',
        type: 'item',
        classes: 'nav-item',
        icon: 'feather icon-clipboard',
        url: '/313out41yap141/report/',
      },
      {
        id: 'followup',
        title: 'Follow Up',
        type: 'item',
        classes: 'nav-item',
        icon: 'feather icon-mail',
        url: '/313out41yap141/followup',
      },
      {
        id: 'clinets-performance',
        title: 'Clients Performance',
        type: 'item',
        classes: 'nav-item',
        icon: 'feather icon-activity',
        url: '/313out41yap141/clients',
      },
      {
        id: 'task',
        title: 'Task',
        type: 'item',
        classes: 'nav-item',
        icon: 'feather icon-clock',
        url: '/313out41yap141/task',
        badge: { type: 'badge badge-pill badge-danger', title: '5' }
      },
      {
        id: 'bill-discount',
        title: 'Bill Discount',
        type: 'item',
        classes: 'nav-item',
        icon: 'feather icon-percent',
        url: '/313out41yap141/bill-discount',
      },
      // {
      //   id: 'crm',
      //   title: 'CRM',
      //   type: 'collapse',
      //   classes: 'nav-item',
      //   icon: 'feather icon-users',
      //   children: [
      //     {
      //       id: 'saleperson',
      //       title: 'Sales Executive',
      //       type: 'item',
      //       url: '/313out41yap141/administrator-tally/salesperson',
      //     },
      //     {
      //       id: 'lead',
      //       title: 'Leads',
      //       type: 'item',
      //       url: '/313out41yap141/lead/leadview'
      //     },
      //     {
      //       id: 'quotations',
      //       title: 'Quotations',
      //       type: 'item',
      //       url: '/313out41yap141/lead/quote'
      //     }
      //   ]
      // },
      {
        id: 'crm_new',
        title: 'CRM',
        type: 'collapse',
        classes: 'nav-item',
        icon: 'feather icon-users',
        children: [
          {
            id: 'crm_dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/313out41yap141/crm/crm_dashboard',
          },
          {
            id: 'saleperson',
            title: 'Sales Executive',
            type: 'item',
            url: '/313out41yap141/crm/saleperson',
          },
          // {
          //   id: 'customermaster',
          //   title: 'Customer',
          //   type: 'item',
          //   url: '/313out41yap141/customer-master',
          // },
          {
            id: 'lead',
            title: 'Leads',
            type: 'item',
            url: '/313out41yap141/crm/leads'
          },
          {
            id: 'quotations',
            title: 'Quotations',
            type: 'item',
            url: '/313out41yap141/crm/quotations'
          },
          {
            id: 'reports',
            title: 'Reports',
            type: 'collapse',
            classes: 'nav-item',
            // icon: 'feather icon-clipboard',
            children: [

              {
                id: 'leadreport',
                title: 'Lead Report',
                type: 'item',
                url: '/313out41yap141/crm/reports/leadreport'

              },
              {
                id: 'reports',
                title: 'Sales Executive Analyses',
                type: 'item',
                url: '/313out41yap141/crm/reports/salespersonanalysis'
              }
            ]
          }
        ]
      },
      {
        id: 'statutory',
        title: 'Statutory',
        type: 'collapse',
        classes: 'nav-item',
        icon: 'feather icon-clipboard',
        children: [
          {
            id: 'msme',
            title: 'MSME',
            type: 'item',
            url: '/313out41yap141/administrator/statutory/msme',
          },
          {
            id: 'gst',
            title: 'GST',
            type: 'item',
            url: '/313out41yap141/administrator/statutory/gst',
          }
        ]
      },
      {
        id: 'legal-support',
        title: 'Legal Support',
        type: 'item',
        classes: 'nav-item',
        icon: 'feather icon-help-circle',
        url: '/313out41yap141/legal-support',
      },
      {
        id: 'bankdetails',
        title: 'Banking',
        type: 'collapse',

        icon: 'feather icon-monitor',
        classes: 'nav-item',
        children: [
          // {
          //   id: 'CustomerStatement',
          //   title: 'Customer Statement',
          //   type: 'item',
          //   url: '/313out41yap141/bank',
          // },
          {
            id: 'DP-Power-Calculation',
            title: 'DP Power Calculator',
            type: 'item',
            url: '/313out41yap141/dpcalculator',
          }
        ]
      },
      {
        id: 'log',
        title: 'Log',
        type: 'collapse',
        classes: 'nav-item',
        icon: 'feather icon-clipboard',
        children: [
          {
            id: 'alllog',
            title: 'Logs',
            type: 'item',
            url: '/313out41yap141/log/all',
          },
        ]
      },
    ]
  },
]


@Injectable()
export class NavigationItem {
  public getContisoftAdmin() {
    return NavigationItemsContisoftPannel;
  }
  public getAdmin() {
    return NavigationItemsAdmin;
  }
  public getNonAdmin() {
    return NavigationItemsNonAdmin;
  }
  public getAdminTally() {
    return NavigationItemsAdminTally;
  }
  public getNonAdminTally() {
    return NavigationItemsNonAdminTally;
  }
  public getNonAdminCRMTally() {
    return NavigationItemsNonAdminCRMTally;
  }
  public getContisoftAdminTally() {
    return NavigationItemsAdminTallyContisoftPannel
  }

}
