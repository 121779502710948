import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MarkasdeliveredService {

  TOKEN = 'Bearer ' + window.localStorage.getItem('token');

  constructor(private httpClient: HttpClient) { }

  get(invoice_no, company_id) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/deliveredstatus/get/?company_id=` + company_id + `&invoice_no=` + invoice_no, {headers: headers});
  }

  
  create(data) {
    //const companyId = window.localStorage.getItem('company_id');
    const headers = new HttpHeaders({ 'Authorization': this.TOKEN });
    return this.httpClient.post(`${environment.api_rul}/deliveredstatus/create` ,  data , {headers: headers});
  }

}
