import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { login_cred } from '../../../login-creds';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  TOKEN = 'Bearer ' + window.localStorage.getItem('token');
  constructor(private httpClient: HttpClient) { }

  create(data) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.post(`${environment.api_rul}/masters/user/create`, data, {headers: headers});
  }

  update(data) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.post(`${environment.api_rul}/masters/user/update`, data, {headers: headers});
  }

  changePassword(data) {
    const companyId = window.localStorage.getItem('company_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.post(`${environment.api_rul}/masters/user/change/password/?company_id=` + companyId, data, {headers: headers});
  }

  get() {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/masters/user/read/?company_id=` + companyId + '&user_id=' + userId, {headers: headers});
  }

  getpaginatedUser(offset,limit) {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/masters/user/read/pages/?company_id=` + companyId + '&user_id=' + userId + `&offset=`  + offset + `&limit=` + limit, {headers: headers});
  }

  getProfile() {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/profile/read/?company_id=` + companyId + '&logged_user=' + userId, {headers: headers});
  }

  remove(user_id) {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/masters/user/remove/?company_id=` + companyId + `&user_id=` + user_id + `&remover_id=` + userId, {headers: headers});
  }

}
