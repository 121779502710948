import { Component, ElementRef, OnInit, ViewChild,Renderer2  } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { QuotationstatusService } from './quotationstatus.service';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-quotationstatus',
  templateUrl: './quotationstatus.component.html',
  styleUrls: ['./quotationstatus.component.scss']
})
export class QuotationstatusComponent implements OnInit {
  @ViewChild('remark') remark: ElementRef;
  @ViewChild('groupModelSuccess') groupModelSuccess: ModalDirective;
  @ViewChild('groupModelError') groupModelError: ModalDirective;
  company_id: any;
  quote_no: any;
  fy: any;
  status: any;
  showremark:any
  showstatus:any
  showsuccessstatus:any
  showfailuestatus:any
  successMessage:any=''
  errorMessage:any=''
  constructor(private formBuilder: FormBuilder,
    public router: Router,
    private route: ActivatedRoute,
    private ngxService: NgxSpinnerService,private QuotationstatusService: QuotationstatusService,private renderer: Renderer2) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.company_id = params['company_id'];
      this.quote_no = params['quote_no'];
      this.fy=params['fy'];
      this.status=params['status'];
      this.showstatus=true
           
      let remark=""
      if(this.status==1){
    
        this.showremark=false
        this.showstatus=true

        this.QuotationstatusService.update_quotationstatus( this.company_id,this.quote_no,this.fy,this.status,remark).subscribe(result => {

          if (result['status'] == 200) {
            this.showsuccessstatus=true
            this.showfailuestatus=false
            this.successMessage="Quotation accepted successfully"
            this.groupModelSuccess.show();
            setTimeout(() => {

              this.groupModelSuccess.hide();
              this.successMessage = ''
              this.closeTab()
            }, 5000);
    
           
          } else if (result['status'] == 201) {
       
            this.showsuccessstatus=false
            this.showfailuestatus=true
            this.errorMessage='Quotaion status updation failed'
            this.groupModelError.show();
            setTimeout(() => {

              this.groupModelError.hide();
              this.errorMessage = ''
              this.closeTab()
            }, 5000);
    
          }
    
    
        }, error => {
          console.log('ERROR - ', error);
          this.ngxService.hide();
        });
  


      }else{
      console.log("else part")
      this.showremark=true
      }

     
      
    })

  }


  updaterejectstatus() {
    let remark=this.remark.nativeElement.value;
    if (remark.trim() === "") {
      alert("Please enter a remark.");
      return false; // Prevent form submission
    }
    this.QuotationstatusService.update_quotationstatus( this.company_id,this.quote_no,this.fy,this.status,remark).subscribe(result => {
  
      if (result['status'] == 200) {
        this.showremark=false
        this.showstatus=true
        this.showsuccessstatus=true
        this.showfailuestatus=false
        this.successMessage="Quotation rejected successfully"
        this.groupModelSuccess.show();
        setTimeout(() => {

          this.groupModelSuccess.hide();
          this.successMessage = ''
          this.closeTab()
        }, 5000);

     
      } else if (result['status'] == 201) {
        this.showremark=false
        this.showstatus=true
        this.showsuccessstatus=false
        this.showfailuestatus=true
        this.errorMessage='Quotaion status updation failed'
        this.groupModelError.show();
        setTimeout(() => {

          this.groupModelError.hide();
          this.errorMessage = ''
          this.closeTab()
        }, 5000);

      }
  
  
    }, error => {
      console.log('ERROR - ', error);
      this.ngxService.hide();
    });
  
  }

  closeTab() {
    const windowRef = this.renderer.selectRootElement(window);
    windowRef.close();
  }
  
}
