<div class="container-fluid">
    <div class="row">
        <div class="col-md-5 pl-lg-0">
            <ng-container *ngIf="isSearchEnabled">

                <select class="form-select select-cols " (change)="search(searchBox.value, searchBy.value);"
                    aria-label="Default select example" #searchBy>
                    <option selected value="all">Search In All</option>
                    <option *ngFor="let colName of (dataColumns | filterable)" [value]="colName.name">{{colName.value}}
                    </option>
                </select>

                <input type="text" id="searchBox" class="searchBox" name="searchBox" placeholder="Search Here"
                    [(ngModel)]="searchQuery" (keyup)="search(searchBox.value, searchBy.value, true);" #searchBox />
            </ng-container>
        </div>
        <div class="col-md-7 pr-lg-0">
            <div class="buttons-box">

                <select *ngIf="isActionsEnabled" class="form-select select-actions " aria-label="Default select example"
                    (change)="onActionSelected(actions.value);" #actions>
                    <option value="#all" selected>Select An Action</option>
                    <ng-container *ngFor="let action of actionsArray">
                        <option [value]="action.name" data-icon="bi bi-alarm"
                            [disabled]="((!action.multiple && selectedRowIds.length > 1) || (selectedRowIds.length == 0)) && !action.noSelection">

                            {{action.value}}</option>
                    </ng-container>
                </select>

                <div class="select-columns " *ngIf="isColumnSelectionEnabled">
                    <easy-selector [items]="dataColumns" [showSearch]="showSearch" [showStatus]="showStatus"
                        [showError]="showError" (itemChange)="onColumnEnableDisable($event)">
                    </easy-selector>
                </div>
                
                <ng-container id="searchByLedger" *ngIf="isLedgerSearchEnbled">
                    <select class="form-select select-cols " (change)="searchLedger(searchByLedger.value);"
                        aria-label="Default select example" #searchByLedger>
                        <option value="1$2#3&" selected>Search By Ledger</option>
                        <option *ngFor="let i of LedgerDataArray" [value]="i.id">{{i.ledger_name}}
                        </option>
                    </select>
                </ng-container>



                <!-- <div class="dropdown">
                    <button class="btn btn-block text-left dropdown-toggle button-ser" type="button" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false" data-display="static" [title]="selected"
                        [ngClass]="{ 'border border-danger': showError }">
                        <span>
                            {{selected ? selected : (placeholder ? placeholder : 'Select')}}
                        </span>
                    </button>
                    <div class="dropdown-menu">
                        <div *ngIf="showSearch" class="dropdown-item dropdown-item-search" onclick="event.stopPropagation();">
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="Search" [(ngModel)]="search">
                                <div class="input-group-append">
                                    <span class="input-group-text h-100">
                                        <img src="/assets/icons/search.svg" alt="" title="Bootstrap">
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="dropdown-item-checkboxes">
                            <div class="dropdown-item" onclick="event.stopPropagation();" *ngIf="showAll && all.visible"
                                [ngClass]="{ 'checked': all.isEnabled }">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" [id]="'chkItem' + all.name"
                                        (change)='onChange($event, all)' [checked]="all.isEnabled">
                                    <label class="custom-control-label" [for]="'chkItem' + all.name">
                                        <span class="pl-2" [title]="all.value">{{all.value}}</span>
                                    </label>
                                </div>
                            </div>
                
                            <ng-container *ngFor="let item of filtered; let i = index;">
                                <div class="dropdown-item" onclick="event.stopPropagation();" *ngIf="item.visible"
                                    [ngClass]="{ 'checked': item.isEnabled }">
                                  
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" [id]="'chkItem' + item.name"
                                            (change)='onChange($event, item)' [checked]="item.isEnabled">
                                        <label class="custom-control-label" [for]="'chkItem' + item.name">
                                            <span class="pl-2" [title]="item.value">{{item.value}}</span>
                                        </label>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                
                        
                        <div class="dropdown-item" *ngIf="isEmpty">
                            No Item Found
                        </div>
                
                     
                        <div *ngIf="showStatus" class="dropdown-count text-dark">
                            <span><b>Total:</b> {{items.length}}</span>
                            <span><b>Enabled:</b> {{isEnabled}}</span>
                            <span *ngIf="search"><b>Search Count:</b> {{filtered.length}}</span>
                        </div>
                    </div>
                </div> -->




                <button *ngIf='isExportEnabled' type="button" class="btn btn-primary download-icon inline"
                    (click)="downloadExcel()"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                        fill="white" class="bi bi-file-earmark-spreadsheet " viewBox="0 0 16 16">
                        <path
                            d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0  0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V9H3V2a1 1 0 0 1 1-1h5.5v2zM3 12v-2h2v2H3zm0 1h2v2H4a1 1 0 0 1-1-1v-1zm3 2v-2h3v2H6zm4 0v-2h3v1a1 1 0 0 1-1 1h-2zm3-3h-3v-2h3v2zm-7 0v-2h3v2H6z" />
                    </svg> Download</button>
            </div>
        </div>
    </div>
</div>



<div class="my-custom-scrollbar table-responsive">
    <table>
        <thead>
            <tr>
                <th *ngIf="srNoEnable" [ngStyle]="{'background-color': headerBgColor, 'color': headerTextColor}">S.No
                </th>
                <th *ngFor="let col of (dataColumns | columnFilter); let index = index;"
                    [ngStyle]="{'background-color': headerBgColor, 'color': headerTextColor}">
                    {{col.value}}
                    <ng-container *ngIf="col?.sortable && (sortOrder == 2)"><svg (click)="sort(index);"
                            xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-sort-down" viewBox="0 0 16 16">
                            <path
                                d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z" />
                        </svg></ng-container>
                    <ng-container *ngIf="col?.sortable && (sortOrder == 1)"><svg (click)="sort(index);"
                            xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-sort-up" viewBox="0 0 16 16">
                            <path
                                d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z" />
                        </svg></ng-container>
                    <ng-container *ngIf="col?.sortable && (sortOrder == 0)"><svg (click)="sort(index);"
                            xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-filter-left" viewBox="0 0 16 16">
                            <path
                                d="M2 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                        </svg></ng-container>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let row of filteredArray; let i = index">
                <td *ngIf="srNoEnable"
                    [ngStyle]="{'text-align': 'center', 'background-color': isSelected(row[primaryKey], i) ? selectedItemBgColor ? selectedItemBgColor : '#0059b3' : '', 'color': isSelected(row[primaryKey], i) ? selectedItemBgColor ? selectedItemBgColor : '#fff' : ''}">
                    {{i + 1}}</td>
                <td *ngFor="let colName of(dataColumns | columnFilter); trackBy: trackDataByPrimariKey"
                    [ngStyle]="{'text-align': colName.textAlign ? colName.textAlign : '', 'background-color': isSelected(row[primaryKey], i) ? selectedItemBgColor ? selectedItemBgColor : '#0059b3' : '', 'color': isSelected(row[primaryKey], i) ? selectedItemBgColor ? selectedItemBgColor : '#fff' : ''}"
                    (click)="selectedUnselectRow(row[primaryKey], i)">
                    <div *ngIf="colName.isDate" [innerHtml]="row[colName.name] | dateFilter: colName"></div>
                    <div *ngIf="!colName.isDate" [innerHtml]="row[colName.name]"></div>
                </td>
            </tr>
        </tbody>
    </table>
</div>



<!-- Pagination Part Goes Here -->
<div class="row">
    <div class="col-md-12 col-lg-6">
        <caption>Total Records - {{count}}</caption>
    </div>
    <div class="col-md-12 col-lg-6">
        <nav aria-label="Page navigation example">
            <ul class="pagination">
                <li class="page-item" *ngIf="isFirst"><a class="page-link" (click)="onFirst();">First</a></li>
                <li class="page-item"><a class="page-link" (click)="onPrevious();">Previous</a></li>
                <li class="page-item">
                    <select class="page-link select-nav" [(ngModel)]="itemsPerPage"
                        (ngModelChange)="onPageSizeChange(pageSize.value)" #pageSize>
                        <option [disabled]="true">Items Per Page</option>
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="250">250</option>
                        <option value="500">500</option>
                        <option value="1000">1000</option>
                        <!-- <option value="500">500</option> -->
                    </select>
                </li>
                <li class="page-item">
                    <select class="page-link select-nav" [(ngModel)]="currentPage"
                        (ngModelChange)="onPageChange(pages.value)" #pages>
                        <option [disabled]="true">Go To Page</option>
                        <option *ngFor="let page of totalPagesArray;" value="{{page}}">{{page}}</option>
                    </select>
                </li>
                <li class="page-item"><a class="page-link" (click)="onNext();">Next</a></li>
                <li class="page-item" *ngIf="isLast"><a class="page-link" (click)="onLast();">Last</a></li>
                <!-- <li class="page-item"><a class="page-link">Total - {{tempDataArray.length}}</a></li> -->
            </ul>
        </nav>
    </div>
</div>