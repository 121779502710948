

<div>
<div *ngIf="showremark"> 
    <body>
    <h1>Reject Quotation Remark</h1>
    <div class="container">
      <form>
        <div class="form-group">
          <label for="remark">Remark:</label>
          <textarea id="remark" name="remark" rows="4" required #remark></textarea>
        </div>
        <div class="form-group">
          <input type="submit" value="Submit" (click)="updaterejectstatus()">
        </div>
      </form>
    </div>
  </body>
</div>



<!-- <div *ngif="!showremark">
    <body>
        <div class="popup" id="statussuccess">
          <img class="success-img" src="./assets/images/success-img.jpg" alt="Success">
          <h2>Quotation Status Updated Successfully</h2>
          <p>Thank you for the feedback.</p>
        </div>
           </body>
</div> -->

</div>



<app-ui-modal #groupModelSuccess [dialogClass]="'modal-md'">
    <div class="app-modal-body">
        <div class="success-box">
            <img src="./assets/images/success-img.jpg" alt="">
            <p>{{successMessage}}</p>
        </div>
    </div>
    <div class="app-modal-footer m-0">
        <button type="button" class="btn save-btn mr-0" data-dismiss="modal" style="margin: 3px;"
            (click)="groupModelSuccess.hide();closeTab()">OK</button>
    </div>
</app-ui-modal>

<app-ui-modal #groupModelError [dialogClass]="'modal-md'">
    <div class="app-modal-body">
        <div class="success-box">
            <img src="./assets/images/error-msg.png" alt="">
            <p>{{errorMessage}}</p>
        </div>
    </div>
    <div class="app-modal-footer m-0">
        <button type="button" class="btn save-btn mr-0" data-dismiss="modal" style="margin: 3px;"
            (click)="groupModelError.hide();closeTab()">OK</button>
    </div>
</app-ui-modal>