import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { login_cred } from '../../login-creds';
import { data } from 'jquery';


@Injectable({
  providedIn: 'root'
})
export class ReportService {

  TOKEN = 'Bearer ' + window.localStorage.getItem('token');
  constructor(private httpClient: HttpClient) { }

  getAll(offset, limit) {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/reports/all/?company_id=${companyId}&logged_user=${userId}&fy=${fy}&offset=${offset}&limit=${limit}`, { headers: headers });
  }

  getAllbyDate(fromDate, toDate, offset, limit) {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/reports/allbydatewise/?company_id=${companyId}&logged_user=${userId}&fy=${fy}&fromDate=${fromDate}&toDate=${toDate}&offset=${offset}&limit=${limit}`, { headers: headers });
  }

  getAllbyCustomer(customerName) {

    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/reports/allbycustomerwise/?company_id=` + companyId + `&logged_user=` + userId + '&fy=' + fy + `&customerName=` + customerName, { headers: headers });

  }
  getPaidbyDate(fromDate, toDate) {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/reports/paidbydatewise/?company_id=` + companyId + `&logged_user=` + userId + '&fy=' + fy + `&fromDate=` + fromDate + `&toDate=` + toDate, { headers: headers });
  }

  getpagenatedPaidbyDate(fromDate, toDate, offset, limit) {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/reports/paidbydatewise/pages/?company_id=` + companyId + `&logged_user=` + userId + '&fy=' + fy + `&fromDate=` + fromDate + `&toDate=` + toDate + `&offset=` + offset + `&limit=` + limit, { headers: headers });
  }

  getUnPaidbyDate(fromDate, toDate) {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/reports/unpaidbydatewise/?company_id=` + companyId + `&logged_user=` + userId + '&fy=' + fy + `&fromDate=` + fromDate + `&toDate=` + toDate, { headers: headers });
  }

  getPagenatedUnPaidbyDate(fromDate, toDate, offset, limit) {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/reports/unpaidbydatewise/pages/?company_id=` + companyId + `&logged_user=` + userId + '&fy=' + fy + `&fromDate=` + fromDate + `&toDate=` + toDate + `&offset=` + offset + `&limit=` + limit, { headers: headers });
  }

  getUnpaid() {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/reports/unpaid/?company_id=` + companyId + `&logged_user=` + userId + '&fy=' + fy, { headers: headers });
  }

  getPagenatedUnpaid(offset, limit) {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/reports/unpaid/pages/?company_id=` + companyId + `&logged_user=` + userId + '&fy=' + fy + `&offset=` + offset + `&limit=` + limit, { headers: headers });
  }


  getUnpaidByCustomer(customer_id) {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/reports/unpaid/bycustomer/?company_id=` + companyId + `&logged_user=` + userId + `&customer_id=` + customer_id + '&fy=' + fy, { headers: headers });
  }

  getPaid() {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/reports/paid/?company_id=` + companyId + `&logged_user=` + userId + '&fy=' + fy, { headers: headers });
  }

  getpagenatedPaid(offset, limit) {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/reports/paid/pages/?company_id=` + companyId + `&logged_user=` + userId + '&fy=' + fy + `&offset=` + offset + `&limit=` + limit, { headers: headers });
  }

  getPaymentReceipts() {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/reports/payment-receipts/?company_id=` + companyId + `&logged_user=` + userId + '&fy=' + fy, { headers: headers });
  }

  getPagenatedPaymentReceipts(offset, limit) {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/reports/payment-receipts/pages/?company_id=` + companyId + `&logged_user=` + userId + '&fy=' + fy + `&offset=` + offset + `&limit=` + limit, { headers: headers });
  }


  getPartiallyPaid() {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/reports/partiallypaid/?company_id=` + companyId + `&logged_user=` + userId + '&fy=' + fy, { headers: headers });
  }

  getPagenatedPartiallyPaid(offset, limit) {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/reports/partiallypaid/pages/?company_id=` + companyId + `&logged_user=` + userId + '&fy=' + fy + `&offset=` + offset + `&limit=` + limit, { headers: headers });
  }

  getOverDue() {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/reports/overdue/?company_id=` + companyId + `&logged_user=` + userId + '&fy=' + fy, { headers: headers });
  }
  getOverDuebycust() {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/reports/overduebycust/?company_id=` + companyId + `&logged_user=` + userId + '&fy=' + fy, { headers: headers });
  }
  getOverduecustmoerId(customer_id) {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/reports/getbycustid/?company_id=` + companyId + `&customer_id=` + customer_id + `&logged_user=` + userId + '&fy=' + fy, { headers: headers });
  }
  getOverDuebyInvoice(invoice_no) {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/reports/overduebyinvoice/?company_id=` + companyId + `&logged_user=` + userId + '&fy=' + fy + '&invoice_no=' + invoice_no, { headers: headers });
  }

  getInDue() {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/reports/indue/?company_id=` + companyId + `&logged_user=` + userId + '&fy=' + fy, { headers: headers });
  }

  getByMasters(id, master_type) {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/reports/bymasters/?company_id=` + companyId + `&master_type=` + master_type + `&id=` + id + `&logged_user=` + userId + '&fy=' + fy, { headers: headers });
  }

  updatePayment(data) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.post(`${environment.api_rul}/payment-updation/updatepayment`, data, { headers: headers });;
  }

  getByMastersLocation(country, state, city) {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/reports/bymasters/?company_id=` + companyId + `&master_type=location&country=` + country + `&state=` + state + `&city=` + city + `&logged_user=` + userId + '&fy=' + fy, { headers: headers });
  }

  getByDueDays(min_days, max_days) {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/reports/byduedays/?company_id=` + companyId + `&max_days=` + max_days + `&min_days=` + min_days + `&logged_user=` + userId + '&fy=' + fy, { headers: headers });
  }

  getPagenatedByDueDays(min_days, max_days, offset, limit) {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/reports/byduedays/pages/?company_id=` + companyId + `&max_days=` + max_days + `&min_days=` + min_days + `&logged_user=` + userId + '&fy=' + fy + `&offset=` + offset + `&limit=` + limit, { headers: headers });
  }
  //replaced
  // getAgeingReport(type, limit) {
  //   const companyId = window.localStorage.getItem('company_id');
  //   const userId = window.localStorage.getItem('user_id');
  //   const fy = window.localStorage.getItem('fy');
  //   const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
  //   return this.httpClient.get(`${environment.api_rul}/reports/ageing/`+ type +`?company_id=` + companyId + `&logged_user=` + userId + `&customer_limit=` + limit + '&fy=' + fy, {headers: headers}); 
  // }

  getAgeingReport(type, limit, datefilter,searchvalue,basedon) {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
       
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    // return this.httpClient.get(`${environment.api_rul}/reports/ageing/`+ type +`?company_id=` + companyId + `&logged_user=` + userId + `&customer_limit=` + limit + '&fy=' + fy, {headers: headers}); 
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/ageing/bill_debtors` + `?company_id=` + companyId + `&logged_user=` + userId + `&customer_limit=` + limit + '&fy=' + fy + `&filter_date=` + datefilter+`&ledger_id=` + searchvalue+`&basedon=`+basedon, { headers: headers });
  }

  getsummarygReport(userId) {
    const companyId = window.localStorage.getItem('company_id');
    //const userId = 1;
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/summary/monthly?company_id=` + companyId + `&id=` + userId, { headers: headers });
  }

  searcgInvoices(query) {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/reports/search/?company_id=` + companyId + `&logged_user=` + userId + `&query=` + query + '&fy=' + fy, { headers: headers });

  }

  getAcManagers() {
    const companyId = window.localStorage.getItem('company_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/masters/acmanager/read/?company_id=` + companyId + '&fy=' + fy, { headers: headers });
  }

  getCategories() {
    const companyId = window.localStorage.getItem('company_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/masters/category/read/?company_id=` + companyId + '&fy=' + fy, { headers: headers });
  }

  getCustomers() {
    const companyId = window.localStorage.getItem('company_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/masters/customer/read/?company_id=` + companyId + '&fy=' + fy, { headers: headers });
  }

  removeInvoice(invoice_id) {
    const company_id = window.localStorage.getItem('company_id');
    const user_id = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/invoice/remove/?company_id=${company_id}&invoice_id=` + invoice_id + '&user_id=' + user_id + '&fy=' + fy, { headers: headers });
  }

  getMarkAsPaidNotifications() {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/reports/emailpaidnotification/?company_id=` + companyId + `&logged_user=` + userId + '&fy=' + fy, { headers: headers });
  }

  getCustomersForPaymentUpdation(offset, limit) {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/payment-updation/getcustomers/?company_id=` + companyId + `&logged_user=` + userId + '&fy=' + fy + `&offset=` + offset + `&limit=` + limit, { headers: headers });
  }

  getInvoicesByCustomer(customer_id) {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/payment-updation/getinvoicesbycustomer/?company_id=` + companyId + `&logged_user=` + userId + '&fy=' + fy + '&customer_id=' + customer_id, { headers: headers });
  }

  getInvCancelationReport() {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/reports/inv-cacelation-report?company_id=` + companyId + `&logged_user=` + userId + '&fy=' + fy, { headers: headers });
  }

  markAsPaid(invoice_no) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/reports/invoicemarkaspaid/?invoice_no=` + invoice_no, { headers: headers });
  }

  downloadAttachments(invoice_id) {
    const companyId = window.localStorage.getItem('company_id');
    const user_id = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    window.open(`${environment.api_rul}/download/?invoice_id=` + invoice_id + `&company_id=` + companyId + '&user_id=' + user_id);
  }

  getLastTallySyncDetail() {
    const companyId = window.localStorage.getItem('company_id');
    const subscription_id = window.localStorage.getItem('subscription_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN })
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/company/get_last_company/?company_id=${companyId}&subscription_id=${subscription_id}`, { headers: headers })
  }

  getAllCustomerStatement(offset, limit) {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/reports/customerstatement/?company_id=${companyId}&logged_user=${userId}&fy=${fy}&offset=${offset}&limit=${limit}`, { headers: headers });
  }

  getByCustomerStatement(customer_id) {
    const companyId = window.localStorage.getItem('company_id');;
    const fy = window.localStorage.getItem('fy');
    const userid = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/reports/byCustomerStatement/?company_id=${companyId}&customer_id=${customer_id}&fy=${fy}`, { headers: headers });
  }


  get_due_days_bill_oustanding_debtors(offset, limit, min, max) {
    const companyId = window.localStorage.getItem('company_id');
    const fy = window.localStorage.getItem('fy');
    const userid = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/bill/get_new_ref_bill_oustanding_debtors/?company_id=` + companyId + `&user_id=` + userid + `&offset=` + offset + `&limit=` + limit + `&min_days=` + min + `&max_days=` + max + `&fy=` + fy, { headers: headers });
  }


  

}
