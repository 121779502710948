
<app-ui-modal #groupModelSuccess [dialogClass]="'modal-md'">
    <div class="app-modal-body">
        <div class="success-box">
            <img src="./assets/images/success-img.jpg" alt="">
            <p>{{successMessage}}</p>
        </div>
    </div>
    <div class="app-modal-footer m-0">
        <button type="button" class="btn save-btn mr-0" data-dismiss="modal" style="margin: 3px;"
            (click)="groupModelSuccess.hide();closeTab()">OK</button>
    </div>
</app-ui-modal>

<app-ui-modal #groupModelError [dialogClass]="'modal-md'">
    <div class="app-modal-body">
        <div class="success-box">
            <img src="./assets/images/error-msg.png" alt="">
            <p>{{errorMessage}}</p>
        </div>
    </div>
    <div class="app-modal-footer m-0">
        <button type="button" class="btn save-btn mr-0" data-dismiss="modal" style="margin: 3px;"
            (click)="groupModelError.hide();closeTab()">OK</button>
    </div>
</app-ui-modal>