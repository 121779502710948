import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { login_cred } from '../../login-creds';

@Injectable({
  providedIn: 'root'
})
export class ReportTallyService {

  TOKEN = 'Bearer ' + window.localStorage.getItem('token');
  constructor(private httpClient: HttpClient) { }

  saleInvoiceGet(offset, limit, search_query: string, search_by: string, searchByLedger: string) {
    const companyId = window.localStorage.getItem('company_id');
    const fy = window.localStorage.getItem('fy');
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/transaction/get_all_sale/?company_id=` + companyId + '&user_id=' + userId + `&fy=` + fy + `&offset=` + offset + `&limit=` + limit + '&search_query=' + search_query + '&search_col=' + search_by + `&searchByLedger=` + searchByLedger, { headers: headers });
  }

  sales_by_id(id: any) {
    const companyId = window.localStorage.getItem('company_id');
    const fy = window.localStorage.getItem('fy');
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/transaction/get_sale_by_id/?company_id=` + companyId + '&user_id=' + userId + `&fy=` + fy + `&voucher_id=` + id, { headers: headers });
  }
  purchaseInvoiceGet(offset, limit, search_query: string, search_by: string, searchByLedger: string) {
    const companyId = window.localStorage.getItem('company_id');
    const fy = window.localStorage.getItem('fy');
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/transaction/get_all_purchase/?company_id=` + companyId + '&user_id=' + userId + `&fy=` + fy + `&offset=` + offset + `&limit=` + limit + '&search_query=' + search_query + '&search_col=' + search_by + `&searchByLedger=` + searchByLedger, { headers: headers });
  }
  paymentInvoiceGet(offset, limit, search_by: string = "", search_query: string = "") {
    const companyId = window.localStorage.getItem('company_id');
    const fy = window.localStorage.getItem('fy');
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/transaction/get_all_payment/?company_id=` + companyId + '&user_id=' + userId + `&fy=` + fy + `&offset=` + offset + `&limit=` + limit + '&search_query=' + search_query + '&search_col=' + search_by, { headers: headers });
  }
  postDatepaymentInvoiceGet(offset, limit, search_by: string = "", search_query: string = "") {
    const companyId = window.localStorage.getItem('company_id');
    const fy = window.localStorage.getItem('fy');
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/transaction/get_all_post_date_payment/?company_id=` + companyId + '&user_id=' + userId + `&fy=` + fy + `&offset=` + offset + `&limit=` + limit + '&search_query=' + search_query + '&search_col=' + search_by, { headers: headers });
  }

  get_all_ledger(offset: string | number, limit: string | number, type: string, ledger_parent: string, search_query: string = "", search_by: string = "") {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/masters/get_all_ledger/?company_id=${companyId}&user_id=${userId}&offset=${offset}&limit=${limit}&type=${type}&ledger_parent=${ledger_parent}&search_query=${search_query}&search_col=${search_by}`, { headers: headers });
  }

  get_ledger_statement_debtors_by_id(offset: string | number, limit: string | number, type: string, ledger_parent: string, ledger_id: number, search_query: string = "", search_by: string = "") {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    // return this.httpClient.get(`${environment.api_rul}/tallytcpV2/statement/get_ledger_statement_debtors/?company_id=${companyId}&user_id=${userId}&offset=${offset}&limit=${limit}&type=${type}&ledger_parent=${ledger_parent}&ledger_id=${ledger_id}&search_query=${search_query}&search_col=${search_by}&request_type=${request_type}`, { headers: headers });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/statement/get_ledger_statement_debtors/?company_id=${companyId}&user_id=${userId}&offset=${offset}&limit=${limit}&type=${type}&ledger_parent=${ledger_parent}&ledger_id=${ledger_id}&search_query=${search_query}&search_col=${search_by}`, { headers: headers });
  }

  get_ledger_statement_debtors_by_id_new_api(offset: string | number, limit: string | number, type: string, ledger_parent: string, ledger_id: number, search_query: string = "", search_by: string = "", request_type) {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/ledger_statement/get_debtors/?company_id=${companyId}&user_id=${userId}&offset=${offset}&limit=${limit}&type=${type}&ledger_parent=${ledger_parent}&ledger_id=${ledger_id}&search_query=${search_query}&search_col=${search_by}&request_type=${request_type}`, { headers: headers });

  }


  get_ledger_statement_debtors_by_id_by_date(offset: string | number, limit: string | number, type: string, ledger_parent: string, ledger_id: number, search_query: string = "", search_by: string = "", fromDate, toDate) {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    // return this.httpClient.get(`${environment.api_rul}/tallytcpV2/statement/get_ledger_statement_debtors/?company_id=${companyId}&user_id=${userId}&offset=${offset}&limit=${limit}&type=${type}&ledger_parent=${ledger_parent}&ledger_id=${ledger_id}&search_query=${search_query}&search_col=${search_by}&request_type=${request_type}`, { headers: headers });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/statement/get_ledger_statement_debtors/?company_id=${companyId}&user_id=${userId}&offset=${offset}&limit=${limit}&type=${type}&ledger_parent=${ledger_parent}&ledger_id=${ledger_id}&search_query=${search_query}&search_col=${search_by}&from_date=${fromDate}&to_date=${toDate}`, { headers: headers });
  }
  get_ledger_statement_debtors_by_request_type(offset: string | number, limit: string | number, type: string, ledger_parent: string, ledger_id: number, search_query: string = "", search_by: string = "", request_type, fromDate, toDate) {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/ledger_statement/get_debtors/?company_id=${companyId}&user_id=${userId}&offset=${offset}&limit=${limit}&type=${type}&ledger_parent=${ledger_parent}&ledger_id=${ledger_id}&search_query=${search_query}&search_col=${search_by}&request_type=${request_type}&from_date=${fromDate}&to_date=${toDate}`, { headers: headers });
  }


  get_ledger_statement_creditors_by_id(offset: string | number, limit: string | number, type: string, ledger_parent: string, ledger_id: number, search_query: string = "", search_by: string = "") {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/statement/get_ledger_statement_creditors/?company_id=${companyId}&user_id=${userId}&offset=${offset}&limit=${limit}&type=${type}&ledger_parent=${ledger_parent}&ledger_id=${ledger_id}&search_query=${search_query}&search_col=${search_by}`, { headers: headers });
  }

  get_ledger_statement_creditors_by_id_new_api(offset: string | number, limit: string | number, type: string, ledger_parent: string, ledger_id: number, search_query: string = "", search_by: string = "", request_type: string) {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/ledger_statement/get_creditors/?company_id=${companyId}&user_id=${userId}&offset=${offset}&limit=${limit}&type=${type}&ledger_parent=${ledger_parent}&ledger_id=${ledger_id}&search_query=${search_query}&search_col=${search_by}&request_type=${request_type}`, { headers: headers });
  }


  get_ledger_statement_creditors_by_id_by_date(offset: string | number, limit: string | number, type: string, ledger_parent: string, ledger_id: number, search_query: string = "", search_by: string = "", fromDate, toDate) {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/statement/get_ledger_statement_creditors/?company_id=${companyId}&user_id=${userId}&offset=${offset}&limit=${limit}&type=${type}&ledger_parent=${ledger_parent}&ledger_id=${ledger_id}&search_query=${search_query}&search_col=${search_by}&from_date=${fromDate}&to_date=${toDate}`, { headers: headers });
  }

  get_ledger_statement_creditors_by_request_type(offset: string | number, limit: string | number, type: string, ledger_parent: string, ledger_id: number, search_query: string = "", search_by: string = "", request_type,fromDate, toDate) {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/ledger_statement/get_creditors/?company_id=${companyId}&user_id=${userId}&offset=${offset}&limit=${limit}&type=${type}&ledger_parent=${ledger_parent}&ledger_id=${ledger_id}&search_query=${search_query}&search_col=${search_by}&request_type=${request_type}&from_date=${fromDate}&to_date=${toDate}`, { headers: headers });
  }



  get_new_ref_bill_oustanding_debtors(offset, limit, search_query: string, search_by: string, searchByLedger: string) {
    const companyId = window.localStorage.getItem('company_id');
    const fy = window.localStorage.getItem('fy');
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/bill/get_new_ref_bill_oustanding_debtors/?company_id=${companyId}&user_id=${userId}&offset=${offset}&limit=${limit}&fy=${fy}&search_query=${search_query}&search_col=${search_by}&searchByLedger=${searchByLedger}`, { headers: headers });
  }

  get_new_ref_bill_oustanding_creditors(offset, limit, search_query: string, search_by: string, searchByLedger: string) {
    const companyId = window.localStorage.getItem('company_id');
    const fy = window.localStorage.getItem('fy');
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/bill/get_new_ref_bill_oustanding_creditors/?company_id=${companyId}&user_id=${userId}&offset=${offset}&limit=${limit}&fy=${fy}&search_query=${search_query}&search_col=${search_by}&searchByLedger=${searchByLedger}`, { headers: headers });
  }

  get_due_days_bill_oustanding_debtors(offset: string | number, limit: string | number, min: string | number, max: string | number, search_by: string, search_query: string) {
    const companyId = window.localStorage.getItem('company_id');
    const fy = window.localStorage.getItem('fy');
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/bill/get_new_ref_bill_oustanding_debtors/?company_id=` + companyId + '&user_id=' + userId + `&fy=` + fy + `&offset=` + offset + `&limit=` + limit + `&min_days=` + min + `&max_days=` + max + '&search_query=' + search_query + '&search_col=' + search_by, { headers: headers });
  }

  get_due_days_bill_oustanding_creditors(offset: string, limit: string, min: string | number, max: string | number, search_by: string, search_query: string) {
    const companyId = window.localStorage.getItem('company_id');
    const fy = window.localStorage.getItem('fy');
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/bill/get_new_ref_bill_oustanding_creditors/?company_id=` + companyId + '&user_id=' + userId + `&fy=` + fy + `&offset=` + offset + `&limit=` + limit + `&min_days=` + min + `&max_days=` + max + '&search_query=' + search_query + '&search_col=' + search_by, { headers: headers });
  }

  receiptInvoiceGet(offset: string, limit: string, search_by: string, search_query: string) {
    const companyId = window.localStorage.getItem('company_id');
    const fy = window.localStorage.getItem('fy');
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/transaction/get_all_receipt?company_id=` + companyId + '&user_id=' + userId + `&fy=` + fy + `&offset=` + offset + `&limit=` + limit + '&search_query=' + search_query + '&search_col=' + search_by, { headers: headers });
  }
  postDatereceiptInvoiceGet(offset: string, limit: string, search_by: string, search_query: string) {
    const companyId = window.localStorage.getItem('company_id');
    const fy = window.localStorage.getItem('fy');
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/transaction/get_all_post_date_receipt?company_id=` + companyId + '&user_id=' + userId + `&fy=` + fy + `&offset=` + offset + `&limit=` + limit + '&search_query=' + search_query + '&search_col=' + search_by, { headers: headers });
  }

  journalInvoiceGet(offset, limit, search_by: string, search_query: string) {
    const companyId = window.localStorage.getItem('company_id');
    const fy = window.localStorage.getItem('fy');
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/transaction/get_all_journal?company_id=` + companyId + '&user_id=' + userId + `&fy=` + fy + `&offset=` + offset + `&limit=` + limit + '&search_query=' + search_query + '&search_col=' + search_by, { headers: headers });
  }

  debitnoteInvoiceGet(offset, limit, search_by: string = "", search_query: string = "") {
    const companyId = window.localStorage.getItem('company_id');
    const fy = window.localStorage.getItem('fy');
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/transaction/get_all_debitnote?company_id=` + companyId + '&user_id=' + userId + `&fy=` + fy + `&offset=` + offset + `&limit=` + limit + '&search_query=' + search_query + '&search_col=' + search_by, { headers: headers });
  }
  creditnoteInvoiceGet(offset, limit, search_by: string = "", search_query: string = "") {
    const companyId = window.localStorage.getItem('company_id');
    const fy = window.localStorage.getItem('fy');
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/transaction/get_all_creditnote?company_id=` + companyId + '&user_id=' + userId + `&fy=` + fy + `&offset=` + offset + `&limit=` + limit + '&search_query=' + search_query + '&search_col=' + search_by, { headers: headers });
  }

  get_all_group(offset, limit, search_query: string = "", search_by: string = "") {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/masters/get_all_group?company_id=${companyId}&user_id=${userId}&offset=${offset}&limit=${limit}&search_query=${search_query}&search_col=${search_by}`, { headers: headers });
  }

  //bydate
  getcreditnotebyDate(offset, limit, fromDt, toDt) {
    const companyId = window.localStorage.getItem('company_id');
    const fy = window.localStorage.getItem('fy');
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/transaction/get_all_creditnote?company_id=` + companyId + '&user_id=' + userId + `&offset=` + offset + `&limit=` + limit + `&fromdate=` + fromDt + `&todate=` + toDt + `&fy=` + fy, { headers: headers });
  }

  getdebitnotebyDate(offset, limit, fromDt, toDt) {
    const companyId = window.localStorage.getItem('company_id');
    const fy = window.localStorage.getItem('fy');
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/transaction/get_all_debitnote?company_id=` + companyId + '&user_id=' + userId + `&offset=` + offset + `&limit=` + limit + `&fromdate=` + fromDt + `&todate=` + toDt + `&fy=` + fy, { headers: headers });
  }

  getpaymentbydate(offset, limit, fromDt, toDt) {
    const companyId = window.localStorage.getItem('company_id');
    const fy = window.localStorage.getItem('fy');
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/transaction/get_all_payment?company_id=` + companyId + '&user_id=' + userId + `&offset=` + offset + `&limit=` + limit + `&fromdate=` + fromDt + `&todate=` + toDt + `&fy=` + fy, { headers: headers });
  }

  getpurchasebydate(offset, limit, fromDt, toDt, search_query: string, search_by: string, searchByLedger: string) {
    const companyId = window.localStorage.getItem('company_id');
    const fy = window.localStorage.getItem('fy');
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/transaction/get_all_purchase?company_id=` + companyId + '&user_id=' + userId + `&offset=` + offset + `&limit=` + limit + `&fromdate=` + fromDt + `&todate=` + toDt + `&fy=` + fy + '&search_query=' + search_query + '&search_col=' + search_by + `&searchByLedger=` + searchByLedger, { headers: headers });
  }

  getsalebydate(offset, limit, fromDt, toDt, search_query: string, search_by: string, searchByLedger: string) {
    const companyId = window.localStorage.getItem('company_id');
    const fy = window.localStorage.getItem('fy');
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/transaction/get_all_sale?company_id=` + companyId + '&user_id=' + userId + `&offset=` + offset + `&limit=` + limit + `&fromdate=` + fromDt + `&todate=` + toDt + `&fy=` + fy + '&search_query=' + search_query + '&search_col=' + search_by + `&searchByLedger=` + searchByLedger, { headers: headers });
  }

  getreceiptbydate(offset, limit, fromDt, toDt) {
    const companyId = window.localStorage.getItem('company_id');
    const fy = window.localStorage.getItem('fy');
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/transaction/get_all_receipt?company_id=` + companyId + '&user_id=' + userId + `&offset=` + offset + `&limit=` + limit + `&fromdate=` + fromDt + `&todate=` + toDt + `&fy=` + fy, { headers: headers });
  }

  getjournalbydate(offset, limit, fromDt, toDt) {
    const companyId = window.localStorage.getItem('company_id');
    const fy = window.localStorage.getItem('fy');
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/transaction/get_all_journal?company_id=` + companyId + '&user_id=' + userId + `&offset=` + offset + `&limit=` + limit + `&fromdate=` + fromDt + `&todate=` + toDt + `&fy=` + fy, { headers: headers });
  }

  getbilloutstandingdebtorsbydate(offset, limit, fromDt, toDt) {
    const companyId = window.localStorage.getItem('company_id');
    const fy = window.localStorage.getItem('fy');
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/bill/get_new_ref_bill_oustanding_debtors?company_id=` + companyId + '&user_id=' + userId + `&offset=` + offset + `&limit=` + limit + `&fromdate=` + fromDt + `&todate=` + toDt + `&fy=` + fy, { headers: headers });
  }

  getbilloutstandingcreditorsbydate(offset, limit, fromDt, toDt) {
    const companyId = window.localStorage.getItem('company_id');
    const fy = window.localStorage.getItem('fy');
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/bill/get_new_ref_bill_oustanding_creditors?company_id=` + companyId + '&user_id=' + userId + `&offset=` + offset + `&limit=` + limit + `&fromdate=` + fromDt + `&todate=` + toDt + `&fy=` + fy, { headers: headers });
  }

  downloadLedger(ledger_id, group_type) {
    const type = 'Download';
    const company_id = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const subscription_id = window.localStorage.getItem('subscription_id')
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    window.open(`${environment.api_rul}/download/download_ledger_statement/?company_id=${company_id}&user_id=${userId}&ledger_id=${ledger_id}&type=${type}&group_type=${group_type}&subscription_id=${subscription_id}`);
  }

  searchInvoices(searchkey, offset, limit) {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/transaction/get_all_sale?company_id=` + companyId + `&logged_user=` + userId + `&searchkey=` + searchkey + '&fy=' + fy + `&offset=` + offset + `&limit=` + limit, { headers: headers });
  }

  downloadAttachments(invoice_id) {
    const companyId = window.localStorage.getItem('company_id');
    const user_id = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    window.open(`${environment.api_rul}/download/?invoice_id=` + invoice_id + `&company_id=` + companyId + '&user_id=' + user_id);
  }

  removeInvoice(invoice_id) {
    const company_id = window.localStorage.getItem('company_id');
    const user_id = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/invoice/remove/?company_id=${company_id}&invoice_id=` + invoice_id + '&user_id=' + user_id + '&fy=' + fy, { headers: headers });
  }

  saleDownloadPDF() {
    const companyId = window.localStorage.getItem('company_id');
    const user_id = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    window.open(`${environment.api_rul}/download_sale/?&company_id=` + companyId + '&user_id=' + user_id + '&fy=' + fy);
  }

  purchaseDownloadPDF() {
    const companyId = window.localStorage.getItem('company_id');
    const user_id = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    window.open(`${environment.api_rul}/download_purchase/?&company_id=` + companyId + '&user_id=' + user_id + '&fy=' + fy);
  }

  paymentDownloadPDF() {
    const companyId = window.localStorage.getItem('company_id');
    const user_id = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    window.open(`${environment.api_rul}/download_payment/?&company_id=` + companyId + '&user_id=' + user_id + '&fy=' + fy);
  }

  receiptDownloadPDF() {
    const companyId = window.localStorage.getItem('company_id');
    const user_id = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    window.open(`${environment.api_rul}/download_receipt/?&company_id=` + companyId + '&user_id=' + user_id + '&fy=' + fy);
  }

  journalDownloadPDF() {
    const companyId = window.localStorage.getItem('company_id');
    const user_id = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    window.open(`${environment.api_rul}/download_journal/?&company_id=` + companyId + '&user_id=' + user_id + '&fy=' + fy);
  }

  debitnoteDownloadPDF() {
    const companyId = window.localStorage.getItem('company_id');
    const user_id = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    window.open(`${environment.api_rul}/download_debitnote/?&company_id=` + companyId + '&user_id=' + user_id + '&fy=' + fy);
  }

  creditnoteDownloadPDF() {
    const companyId = window.localStorage.getItem('company_id');
    const user_id = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    window.open(`${environment.api_rul}/download_creditnote/?&company_id=` + companyId + '&user_id=' + user_id + '&fy=' + fy);
  }

  groupDownloadPDF() {
    const companyId = window.localStorage.getItem('company_id');
    const user_id = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    window.open(`${environment.api_rul}/download_group/?&company_id=` + companyId + '&user_id=' + user_id + '&fy=' + fy);
  }

  billDebtorDownloadPDF() {
    const companyId = window.localStorage.getItem('company_id');
    const user_id = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    window.open(`${environment.api_rul}/download_billdebtor/?&company_id=` + companyId + '&user_id=' + user_id + '&fy=' + fy);
  }

  billCreditorDownloadPDF() {
    const companyId = window.localStorage.getItem('company_id');
    const user_id = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    window.open(`${environment.api_rul}/download_billcreditor/?&company_id=` + companyId + '&user_id=' + user_id + '&fy=' + fy);
  }

  get_due_days_bill_oustanding_debtors_DownloadPDF(min, max) {
    const companyId = window.localStorage.getItem('company_id');
    const fy = window.localStorage.getItem('fy');
    const userId = window.localStorage.getItem('user_id');
    window.open(`${environment.api_rul}/download_byduedaysdebtor/?company_id=` + companyId + '&user_id=' + userId + `&fy=` + fy + `&min_days=` + min + `&max_days=` + max);
  }

  get_due_days_bill_oustanding_creditors_DownloadPDF(min, max) {
    const companyId = window.localStorage.getItem('company_id');
    const fy = window.localStorage.getItem('fy');
    const userId = window.localStorage.getItem('user_id');
    window.open(`${environment.api_rul}/download_byduedayscreditor/?company_id=` + companyId + '&user_id=' + userId + `&fy=` + fy + `&min_days=` + min + `&max_days=` + max);
  }

  download_get_all_ledger(type: string, ledger_parent: string) {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const fy = window.localStorage.getItem('fy');
    window.open(`${environment.api_rul}/download_ledgerStatement/?company_id=` + companyId + '&user_id=' + userId + `&type=` + type + `&ledger_parent=` + ledger_parent);
  }

  upload_sale_file(data) {
    const company_id = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Authorization': this.TOKEN });
    return this.httpClient.post(`${environment.api_rul}/tallytcpV2/transaction/upload_sale_files`, data, { headers: headers });
  }

  download_attchment(voucher_id, file_id) {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    window.open(`${environment.api_rul}/tallytcpV2/transaction/download_selected_attachment_sales_File?company_id=` + companyId + '&user_id=' + userId + `&voucher_id=` + voucher_id + `&file_id=` + file_id);
  }

  getuploadedfiles(id) {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/transaction/get_all_attach_sales_File?company_id=` + companyId + '&user_id=' + userId + `&voucher_id=` + id, { headers: headers });
  }

  removeAttachment(voucher_id, file_id) {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/transaction/remove_selected_attachment_sales_File?company_id=` + companyId + '&user_id=' + userId + `&file_id=` + file_id + `&voucher_id=` + voucher_id, { headers: headers });
  }

  get_bill_debtors_count() {

    const companyId = window.localStorage.getItem('company_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/settlements/bill_debtors_count?company_id=${companyId}&fy=${fy}`, { headers: headers });


  }

  get_billduedaywise_overdue(ledgergroup) {

    const companyId = window.localStorage.getItem('company_id');
    const fy = window.localStorage.getItem('fy');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/billoverdue_agingreport?company_id=${companyId}&fy=${fy}&ledgergroup=${ledgergroup}&offset=1&limit=99999`, { headers: headers });


  }





  download_get_all_ledger_excel(ledger_id, group_type) {
    const type = 'Download';
    const company_id = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const subscription_id = window.localStorage.getItem('subscription_id')
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    window.open(`${environment.api_rul}/download/download_ledger_statement_excel/?company_id=${company_id}&user_id=${userId}&ledger_id=${ledger_id}&type=${type}&group_type=${group_type}&subscription_id=${subscription_id}`);
  }


}
