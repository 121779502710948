import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { LegalsupportResponseService } from './legalsupport-response.service';

@Component({
  selector: 'app-legalsupport-response',
  templateUrl: './legalsupport-response.component.html',
  styleUrls: ['./legalsupport-response.component.scss']
})
export class LegalsupportResponseComponent implements OnInit {
  @ViewChild('groupModelSuccess') groupModelSuccess: ModalDirective;
  @ViewChild('groupModelError') groupModelError: ModalDirective;
  successMessage:any=''
  errorMessage:any=''
  company_id: any;
  Kredxcustname: any;
  showsuccessstatus: boolean;
  showfailuestatus: boolean;
  constructor(private formBuilder: FormBuilder,
    public router: Router,
    private route: ActivatedRoute,
    private ngxService: NgxSpinnerService,private renderer: Renderer2,private LegalsupportResponseService: LegalsupportResponseService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.company_id = params['company_id'];    
      this.Kredxcustname=params['Kredxcustname'];
      
 
      this.LegalsupportResponseService.billlegal_accept( this.company_id).subscribe(result => {

        if (result['status'] == 200) {
          this.showsuccessstatus=true
          this.showfailuestatus=false
          this.successMessage="Thank you for your valuable feedback. Our associate will reach out to you soon"
          this.groupModelSuccess.show();
          setTimeout(() => {

            this.groupModelSuccess.hide();
            this.successMessage = ''
            this.closeTab()
          }, 5000);
  
         
        } else if (result['status'] == 201) {
     
          this.showsuccessstatus=false
          this.showfailuestatus=true
          this.errorMessage='An error occurred while updating feedback.'
          this.groupModelError.show();
          setTimeout(() => {

            this.groupModelError.hide();
            this.errorMessage = ''
            this.closeTab()
          }, 5000);
  
        }
  
  
      }, error => {
        console.log('ERROR - ', error);
        this.ngxService.hide();
      });




    })
  }
  closeTab() {
    const windowRef = this.renderer.selectRootElement(window);
    windowRef.close();
  }
}
