<ul class="navbar-nav ml-auto" style="background-color: #003366;">
    <!-- <li>
    <a href="https://github.com/phoenixcoded/flat-able-free-angular-admin-template" target="_blank" class="">
      <i class="icon feather icon-github pulse-button"></i>
    </a>
  </li> -->
    <li class="nav-item">
        <marquee behavior=scroll width="auto" direction="let" height="50px" scrollamount="5">

            <a *ngIf='integration_type_manual' class="app-marquee" style="color:white;font-size: 15px;"
                (click)="showInvoiceModal.show()" href="#">
                <!-- {{i+1}} -->

                Note :- <b>{{InvoiceCount }} </b> Invoices Are Due With Amount ₹ <b>{{InvoiceAmount | number}}</b> In
                Upcoming 15 Days
            </a>
            <a *ngIf='!integration_type_manual' class="app-marquee" style="color:white;font-size: 15px;"
                (click)="showInvoiceModal.show()" href="#">
                <!-- {{i+1}} -->
                <!-- <Span style="color:yellow;font-size: 15px;font-weight: bold;">
                    ( We Apologize For The Inconvenience, But We're Performing Some Maintenance On 6th August 2022. We'll be back up soon!)
               </Span> -->
               
                Note :- <b>{{InvoiceCount }} </b> Invoices Are Due With Amount ₹ <b>{{InvoiceAmount | number}}</b> In
                Upcoming 15 Days <span style="color : white;font-weight: bold;"> Last Sync With Tally
                    {{lastTallySyncDetail | date:'dd-MM-yyyy'}}</span> 
                
            </a>

        </marquee>

        <!-- <marquee behavior=scroll width="auto" direction="left" height="50px" scrollamount="4">
            <a style="color:red;font-size: 15px;font-weight: bold;">
                
            </a>
        </marquee> -->

    </li>
    <app-ui-modal #showInvoiceModal [containerClick]="false" [dialogClass]="'modal-lg'">
        <div class="app-modal-header bl-color" *ngIf='integration_type_manual'>
            Due Invoices in Upcoming Days
            <span class="btn btn-outline-primary rounded_" title="Download to Excel" (click)="exportToCSV();">
                <i class="feather icon-download"></i></span>

            <button type="button" class="close basic-close" (click)="showInvoiceModal.hide()" data-dismiss="modal"
                aria-label="Close"><span aria-hidden="true">&times;</span></button>
        </div>
        <div class="app-modal-header bl-color" *ngIf='!integration_type_manual'>
            Due Bills in Upcoming Days
            <span class="btn btn-outline-primary rounded_" title="Download to Excel" (click)="exportToCSV();">
                <i class="feather icon-download"></i></span>

            <button type="button" class="close basic-close" (click)="showInvoiceModal.hide()" data-dismiss="modal"
                aria-label="Close"><span aria-hidden="true">&times;</span></button>
        </div>
        <div class="app-modal-body" *ngIf='integration_type_manual'>
            <div class="table-responsive">
                <table class="table-bordered table table-striped text-center gr-color">
                    <thead>
                        <tr class="gr_color">
                            <th>#</th>
                            <th>Customer</th>
                            <th>Category</th>
                            <th>PO No</th>
                            <th>PO Date</th>
                            <th>Invoice No</th>
                            <th>Invoice Date</th>
                            <th style="padding-bottom: 1px;">Amount <br><small>(Incl. all taxes)</small></th>
                            <th>Due Date</th>
                            <th>Remaining Days</th>
                            <th>Status</th>
                            <th>Inserted At</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let item of Invoices | paginate: { itemsPerPage: pageSize, currentPage: p }; let i = index;">
                            <td>{{i+1}}</td>
                            <td>{{item.customer_master.customer_name}}</td>
                            <td>{{item.category_master.category_name}}</td>
                            <td>{{item.po_no}}</td>
                            <td>{{item.po_date | date:'MM-dd-yyyy'}}</td>
                            <td>{{item.invoice_no}}</td>
                            <td>{{item.invoice_date | date:'MM-dd-yyyy'}}</td>
                            <td>{{handlingnan(item.sgst, item.cgst, item.igst, item.invoice_amount) |
                                rupeeConverter | number:'1.2-2'}}</td>
                            <td>{{item.payment_due_date | date:'MM-dd-yyyy'}}</td>
                            <td>{{item.remaining_days}}</td>
                            <td>{{item.payment_status | paymentStatus}}</td>
                            <td>{{item.created_at | date:'MM-dd-yyyy'}}</td>
                        <tr>
                    </tbody>
                </table>
                <!-- <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
                        <button type="button" class="btn btn-danger" data-dismiss="modal"
                    (click)="showInvoiceModal.hide()">Close</button> -->

                <div class="row">
                    <div class="col-md-2">
                        <div class="text-left">
                            <select class="rounded_ custom-select mb-2" [(ngModel)]="pageSize">
                                <option>1</option>
                                <option>5</option>
                                <option>10</option>
                                <option>20</option>
                                <option>50</option>
                                <option>100</option>
                                <option>200</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-10 text-right" style="color:black">
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                    </div>
                </div>
            </div>
        </div>
        <div class="app-modal-body" *ngIf='!integration_type_manual'>
            <div class="table-responsive">
                <table class="gr-color table-bordered table table-striped text-center">
                    <thead>
                        <tr class="gr-color">
                            <th>#</th>
                            <th>Ledger Name</th>
                            <th>Bill Type</th>
                            <th>Bill Date</th>
                            <th>Bill Name</th>
                            <th>Bill Due Date</th>
                            <th>Bill Amount</th>
                            <th>Balance Amount</th>
                            <!-- <th>Last Date of Adj.</th> -->
                            <!-- <th>Share</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let item of Invoices  |  paginate: { itemsPerPage: pageSize, currentPage: p }; let i = index;">
                            <td>{{pageSize *(p-1)+i+1}}</td>
                            <td>{{item.tally_ledger_detail.ledger_name}}</td>
                            <td>{{item.bill_type}}</td>
                            <td>{{item.bill_date | date:'dd-MM-yyyy'}}</td>
                            <td>{{item.bill_name}}</td>
                            <td>{{item.bill_due_date | date:'dd-MM-yyyy'}}</td>
                            <td>{{item.bill_amount}}</td>
                            <td *ngIf='item.bill_Is_cr_dr == "Yes"'>{{item.bill_balance_amount}} Dr</td>
                            <td *ngIf='item.bill_Is_cr_dr == "No"'>{{item.bill_balance_amount}} Cr</td>
                            <!-- <td>{{item.last_payment_date | date:'dd-MM-yyyy'}}</td> -->
                            <!-- <td style="text-align: center;">
                                <button type="button" class="btn rounded_ btn-outline-info btn-sm">
                                    <i class="feather icon-mail"></i> Email
                                </button>
                            </td> -->
                        </tr>
                    </tbody>
                </table>
                <!-- <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
                        <button type="button" class="btn btn-danger" data-dismiss="modal"
                    (click)="showInvoiceModal.hide()">Close</button> -->

                <div class="row">
                    <div class="col-md-2">
                        <div class="text-left">
                            <select class="rounded_ custom-select mb-2" [(ngModel)]="pageSize">
                                <option>1</option>
                                <option>5</option>
                                <option>10</option>
                                <option>20</option>
                                <option>50</option>
                                <option>100</option>
                                <option>200</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-10 text-right" style="color:black">
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal"
                    (click)="showInvoiceModal.hide()">Close</button>
            </div> -->
    </app-ui-modal>



    <!-- <li class="nav-item" style="height: 30pt;padding: 0px;width: 140px;">

        <div class="form-group" >

            <ng-multiselect-dropdown
             id="item_id"


            [placeholder]="'Select Years'"
            [settings]="dropdownSettings"
            [data]="dropdownList"
            [(ngModel)]="selectedItems"
            (onSelect)="onItemSelect($event)"
            (onSelectAll)="onSelectAll($event)">
          </ng-multiselect-dropdown>


        </div>
    </li> -->
    <li class="nav-item">
        <div class="form-group">
            <select class=" custom-select"
                style="border: none; height: 44px;padding: 8px;width:  410px; font-size: 14px; border-radius: 3px;"
                (change)="onCompanyChange(company.value);" [disabled]="CompanyEnabled" #company>
                <option value="">Select company</option>
                <option *ngFor="let company of Companies" [selected]="CurrentCompany == company.company_id"
                    value="{{company.company_id}}">{{company.company_name}}
                    <small *ngIf="company.is_active == 1"> <b>(Active)</b></small>
                    <small *ngIf="company.is_active == 0"> <b>(Inactive)</b></small>
                </option>
            </select>
        </div>
    </li>
    <li class="nav-item">
        <div *ngIf='!integration_type_manual' class="form-group">
            <select class="  custom-select"
                style="border: none; height: 44px;padding: 8px;width: 129px;  border-radius: 3px; font-size: 14px;"
                (change)="onFinancialYearChange(financialYear.value);" [disabled]="FYEnabled" #financialYear>
                <option value="">Financial year</option>
                <!-- <option value="all" [selected]="CurrentFinancialYear == 'all'">All Years</option> -->
                <option *ngFor="let year of FinancialYears" [selected]="CurrentFinancialYear == year.financial_year"
                    value="{{year.financial_year}}">{{year.financial_year}}</option>
            </select>
        </div>
        <div *ngIf='integration_type_manual' class="form-group">
            <select class="gr-color rounded_ custom-select" style="height: 44px;padding: 8px;width: 129px;"
                (change)="onFinancialYearChange(financialYear.value);" [disabled]="FYEnabled" #financialYear>
                <option value="">Financial year</option>
                <!-- <option value="all" [selected]="CurrentFinancialYear == 'all'">All Years</option> -->
                <option *ngFor="let year of FinancialYears" [selected]="CurrentFinancialYear == year.financial_year"
                    value="{{year.financial_year}}">{{year.financial_year}}</option>
            </select>
        </div>
    </li>
    <li class="nav-item">
        <app-nav-search></app-nav-search>
    </li>
    <li [hidden]="TodaysCount <= 0">
        <div *ngIf='integration_type_manual' class="dropdown" ngbDropdown placement="auto">
            <a ngbDropdownToggle routerLink="/313out41yap141/report/byduedays/todays" title="Todays Expiry">
                <i style="color: white;" class="icon feather icon-bell"></i>
                <span class="badge badge-pill badge-danger">{{TodaysCount}}</span>
            </a>
        </div>
        <div *ngIf='!integration_type_manual' class="dropdown" ngbDropdown placement="auto">
            <a ngbDropdownToggle routerLink="/313out41yap141/report-tally/byduedays-tally/sundry-debtor-duedays"
                title="Todays Expiry">
                <i style="color: white;" class="icon feather icon-bell"></i>
                <span class="badge badge-pill badge-danger">{{TodaysCount}}</span>
            </a>
        </div>
    </li>
    <li [hidden]="PaidNotificationCount <= 0">
        <div class="dropdown" ngbDropdown placement="auto">
            <a ngbDropdownToggle routerLink="/313out41yap141/report/mapnotifications" title="Mark As Paid Notifications">
                <i style="color: white;" class="icon feather icon-mail"></i>
                <span class="badge badge-pill badge-danger">{{PaidNotificationCount}}</span>
            </a>
        </div>
    </li>
    <li>
        <a class="dud-profile" title="Profile" [routerLink]="['/313out41yap141/profile']">
            <i style="color: white;" class="feather icon-user"></i>
        </a>
    </li>
    <li>
        <a href="javascript:" class="dud-logout" title="Logout" (click)="updateInvoiceModal.show();">
            <i style="color: white;" class="feather icon-log-out"></i>
        </a>
    </li>
    <!-- <li>
        <div class="dropdown" ngbDropdown placement="auto">
            <a ngbDropdownToggle href="javascript:">
                <i class="icon feather icon-bell"></i>
                <span class="badge badge-pill badge-danger">5</span>
            </a>
            <div ngbDropdownMenu class="dropdown-menu-right notification">
                <div class="noti-head">
                    <h6 class="d-inline-block m-b-0">Notifications</h6>
                    <div class="float-right">
                        <a href="javascript:" class="m-r-10">mark as read</a>
                        <a href="javascript:">clear all</a>
                    </div>
                </div>
                <ul class="noti-body">
                    <li class="n-title">
                        <p class="m-b-0">NEW</p>
                    </li>
                    <li class="notification">
                        <div class="media">
                            <img class="img-radius" src="assets/images/user/avatar-1.jpg" alt="Generic placeholder image">
                            <div class="media-body">
                                <p><strong>John Doe</strong><span class="n-time text-muted"><i class="icon feather icon-clock m-r-10"></i>30 min</span></p>
                                <p>New ticket Added</p>
                            </div>
                        </div>
                    </li>
                    <li class="n-title">
                        <p class="m-b-0">EARLIER</p>
                    </li>
                    <li class="notification">
                        <div class="media">
                            <img class="img-radius" src="assets/images/user/avatar-2.jpg" alt="Generic placeholder image">
                            <div class="media-body">
                                <p><strong>Joseph William</strong><span class="n-time text-muted"><i class="icon feather icon-clock m-r-10"></i>30 min</span></p>
                                <p>Prchace New Theme and make payment</p>
                            </div>
                        </div>
                    </li>
                    <li class="notification">
                        <div class="media">
                            <img class="img-radius" src="assets/images/user/avatar-3.jpg" alt="Generic placeholder image">
                            <div class="media-body">
                                <p><strong>Sara Soudein</strong><span class="n-time text-muted"><i class="icon feather icon-clock m-r-10"></i>30 min</span></p>
                                <p>currently login</p>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="noti-footer">
                    <a href="javascript:">show all</a>
                </div>
            </div>
        </div>
    </li> -->
    <!-- <li>
        <div class="drp-user dropdown" ngbDropdown placement="auto">
            <a href="javascript:" ngbDropdownToggle>
                <i class="feather icon-user"></i>
            </a>
            <div class=" dropdown-menu-right profile-notification" ngbDropdownMenu>
                <div class="pro-head">
                    <img src="assets/images/user/avatar-1.jpg" class="img-radius" alt="User-Profile-Image">
                    <span>John Doe</span>
                    <a href="javascript:" class="dud-logout" title="Logout">
                        <i class="feather icon-log-out"></i>
                    </a>
                </div>
                <ul class="pro-body">
                    <li><a href="javascript:" class="dropdown-item"><i class="feather icon-user"></i> Profile</a></li>
                    <li><a href="javascript:" class="dropdown-item"><i class="feather icon-mail"></i> My Messages</a></li>
                    <li><a href="javascript:" class="dropdown-item"><i class="feather icon-lock"></i> Lock Screen</a></li>
                </ul>
            </div>
        </div>
    </li> -->
</ul>


<app-ui-modal #updateInvoiceModal [containerClick]="false">
    <div class="app-modal-header">
        <h5 class="modal-title">Log Out</h5>
        <button type="reset" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="updateInvoiceModal.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <div class="center">
            <img src="./assets/images/logout.png" alt="" class="logout">
            <p style="font-size: 16px; color: black; ">Do you really want to Log Out ?</p>
        </div>
    </div>
    <div class="app-modal-footer m-0">
        <button type="reset" class="rounded_ btn grey-btn mr-2" (click)="updateInvoiceModal.hide()">No</button>
        <button type="submit" class="rounded_ btn save-btn mr-0"
            (click)="updateInvoiceModal.hide();logout();">Yes</button>
    </div>
</app-ui-modal>

<!-- line 22 : (click)="navrightModal.hide()" -->