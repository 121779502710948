import { AfterViewInit, Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { NavigationItem } from '../navigation';
import { NextConfig } from '../../../../../app-config';
import { Location } from '@angular/common';
import { UserService } from 'src/app/trackpayout/administrator/user/user.service';
import { TaskService } from 'src/app/trackpayout/task/task.service';
import { ResourceLoader } from '@angular/compiler';

@Component({
  selector: 'app-nav-content',
  templateUrl: './nav-content.component.html',
  styleUrls: ['./nav-content.component.scss']
})
export class NavContentComponent implements OnInit, AfterViewInit {
  public flatConfig: any;
  public navigation: any = [];
  public prevDisabled: string;
  public nextDisabled: string;
  public contentWidth: number;
  public wrapperWidth: any;
  public scrollWidth: any;
  public windowWidth: number;
  public isNavProfile: boolean;

  @Output() onNavMobCollapse = new EventEmitter();


  @ViewChild('navbarContent') navbarContent: ElementRef;
  @ViewChild('navbarWrapper') navbarWrapper: ElementRef;
  legalSupport = {
    id: 'legal-support',
    title: 'Legal Support',
    type: 'item',
    classes: 'nav-item',
    icon: 'feather icon-help-circle',
    url: '/313out41yap141/legal-support',
  };
  crm: any = {
    id: 'crm',
    title: 'CRM',
    type: 'collapse',
    classes: 'nav-item',
    icon: 'feather icon-clipboard',
    children: [
      {
        id: 'saleperson',
        title: 'Sales Executive',
        type: 'item',
        url: '/313out41yap141/administrator-tally/salesperson',
      },
      {
        id: 'lead',
        title: 'Leads',
        type: 'item',
        url: '/313out41yap141/lead/leadview'
      },
      {
        id: 'quotations',
        title: 'Quotations',
        type: 'item',
        url: '/313out41yap141/lead/quote'
      }
    ]
  };
  task = {
    id: 'task',
    title: 'Task',
    type: 'item',
    classes: 'nav-item',
    icon: 'feather icon-clock',
    url: '/313out41yap141/task',
    badge: { type: 'badge badge-pill badge-danger', title: '5' }
  };
  onboardMasters = {
    id: 'vendors',
    title: 'OnBoard Masters',
    type: 'item',
    classes: 'nav-item',
    icon: 'feather icon-clipboard',
    url: '/313out41yap141/administrator-tally/vendors-tally',
  }

  constructor(public nav: NavigationItem,
    private zone: NgZone,
    private location: Location,
    private userService: UserService,
    private taskservice: TaskService) {

    this.flatConfig = NextConfig.config;
    this.windowWidth = window.innerWidth;

    this.navigation = [];
    this.prevDisabled = 'disabled';
    this.nextDisabled = '';
    this.scrollWidth = 0;
    this.contentWidth = 0;

    this.isNavProfile = false;
  }

  ngOnInit() {

    //this.getActiveTask();
    this.userService.getProfile().subscribe(result => {
      this.navigation = []

      console.log(result['user']['is_admin']);
      if (result['status'] == 200) {
        
        if (result['user']['is_admin'] == 1 && (result['user']['company_id'] == 10031) &&
          (result['user']['subscription_detail']['company_integration_type'] == 2 || result['user']['subscription_detail']['company_integration_type'] == 3)) {

          this.navigation = this.nav.getContisoftAdminTally();

        } else if (result['user']['is_admin'] == 1 && result['user']['subscription_detail']['company_integration_type'] == 1) {
          this.navigation = this.nav.getAdmin();

        } else if (result['user']['is_admin'] == 0 && result['user']['subscription_detail']['company_integration_type'] == 1) {
          this.navigation = this.nav.getNonAdmin();

        } else if (result['user']['is_admin'] == 1 && (result['user']['subscription_detail']['company_integration_type'] == 2 || result['user']['subscription_detail']['company_integration_type'] == 3)) {
          this.navigation = this.nav.getAdminTally();

        }
        else if (result['user']['is_admin'] == 0 && (result['user']['subscription_detail']['company_integration_type'] == 2 || result['user']['subscription_detail']['company_integration_type'] == 3)) {
       //   console.log(this.navigation);




          if (result['user']['is_crm'] == 1) {
            this.navigation = this.nav.getNonAdminCRMTally();

          }
          else {
            this.navigation = this.nav.getNonAdminTally();
          }
  
        }

      }
    }, error => {
      console.log(error);
    });

    if (this.windowWidth < 992) {
      this.flatConfig['layout'] = 'vertical';
      setTimeout(() => {
        document.querySelector('.pcoded-navbar').classList.add('menupos-static');
        (document.querySelector('#nav-ps-flat-able') as HTMLElement).style.maxHeight = '100%';
      }, 500);
    }
  }

  ngAfterViewInit() {
    if (this.flatConfig['layout'] === 'horizontal') {
      this.contentWidth = this.navbarContent.nativeElement.clientWidth;
      this.wrapperWidth = this.navbarWrapper.nativeElement.clientWidth;
    }
  }

  scrollPlus() {
    this.scrollWidth = this.scrollWidth + (this.wrapperWidth - 80);
    if (this.scrollWidth > (this.contentWidth - this.wrapperWidth)) {
      this.scrollWidth = this.contentWidth - this.wrapperWidth + 80;
      this.nextDisabled = 'disabled';
    }
    this.prevDisabled = '';
    if (this.flatConfig.rtlLayout) {
      (document.querySelector('#side-nav-horizontal') as HTMLElement).style.marginRight = '-' + this.scrollWidth + 'px';
    } else {
      (document.querySelector('#side-nav-horizontal') as HTMLElement).style.marginLeft = '-' + this.scrollWidth + 'px';
    }
  }

  scrollMinus() {
    this.scrollWidth = this.scrollWidth - this.wrapperWidth;
    if (this.scrollWidth < 0) {
      this.scrollWidth = 0;
      this.prevDisabled = 'disabled';
    }
    this.nextDisabled = '';
    if (this.flatConfig.rtlLayout) {
      (document.querySelector('#side-nav-horizontal') as HTMLElement).style.marginRight = '-' + this.scrollWidth + 'px';
    } else {
      (document.querySelector('#side-nav-horizontal') as HTMLElement).style.marginLeft = '-' + this.scrollWidth + 'px';
    }

  }

  fireLeave() {
    const sections = document.querySelectorAll('.pcoded-hasmenu');
    for (let i = 0; i < sections.length; i++) {
      sections[i].classList.remove('active');
      sections[i].classList.remove('pcoded-trigger');
    }

    let current_url = this.location.path();
    if (this.location['_baseHref']) {
      current_url = this.location['_baseHref'] + this.location.path();
    }
    const link = "a.nav-link[ href='" + current_url + "' ]";
    const ele = document.querySelector(link);
    if (ele !== null && ele !== undefined) {
      const parent = ele.parentElement;
      const up_parent = parent.parentElement.parentElement;
      const last_parent = up_parent.parentElement;
      if (parent.classList.contains('pcoded-hasmenu')) {
        parent.classList.add('active');
      } else if (up_parent.classList.contains('pcoded-hasmenu')) {
        up_parent.classList.add('active');
      } else if (last_parent.classList.contains('pcoded-hasmenu')) {
        last_parent.classList.add('active');
      }
    }
  }

  navMob() {
    if (this.windowWidth < 992 && document.querySelector('app-navigation.pcoded-navbar').classList.contains('mob-open')) {
      this.onNavMobCollapse.emit();
    }
  }

  fireOutClick() {
    let current_url = this.location.path();
    if (this.location['_baseHref']) {
      current_url = this.location['_baseHref'] + this.location.path();
    }
    const link = "a.nav-link[ href='" + current_url + "' ]";
    const ele = document.querySelector(link);
    if (ele !== null && ele !== undefined) {
      const parent = ele.parentElement;
      const up_parent = parent.parentElement.parentElement;
      const last_parent = up_parent.parentElement;
      if (parent.classList.contains('pcoded-hasmenu')) {
        if (this.flatConfig['layout'] === 'vertical') {
          parent.classList.add('pcoded-trigger');
        }
        parent.classList.add('active');
      } else if (up_parent.classList.contains('pcoded-hasmenu')) {
        if (this.flatConfig['layout'] === 'vertical') {
          up_parent.classList.add('pcoded-trigger');
        }
        up_parent.classList.add('active');
      } else if (last_parent.classList.contains('pcoded-hasmenu')) {
        if (this.flatConfig['layout'] === 'vertical') {
          last_parent.classList.add('pcoded-trigger');
        }
        last_parent.classList.add('active');
      }
    }
  }

  getActiveTask() {

    this.taskservice.getActiveTask().subscribe(result => {
      if (result['status'] == "200") {
        this.navigation = this.nav.task = "12";

        // console.log(this.nav.task);

      }
    })
  }
}
