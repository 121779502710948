import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { ModalModule } from 'ngx-bootstrap/modal';


import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './theme/shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppComponent } from './app.component';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { NavigationComponent } from './theme/layout/admin/navigation/navigation.component';
import { NavContentComponent } from './theme/layout/admin/navigation/nav-content/nav-content.component';
import { NavGroupComponent } from './theme/layout/admin/navigation/nav-content/nav-group/nav-group.component';
import { NavCollapseComponent } from './theme/layout/admin/navigation/nav-content/nav-collapse/nav-collapse.component';
import { NavItemComponent } from './theme/layout/admin/navigation/nav-content/nav-item/nav-item.component';
import { NavBarComponent } from './theme/layout/admin/nav-bar/nav-bar.component';
import { NavLeftComponent } from './theme/layout/admin/nav-bar/nav-left/nav-left.component';
import { NavSearchComponent } from './theme/layout/admin/nav-bar/nav-left/nav-search/nav-search.component';

import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';

import { NavRightComponent } from './theme/layout/admin/nav-bar/nav-right/nav-right.component';
import { ConfigurationComponent } from './theme/layout/admin/configuration/configuration.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ToggleFullScreenDirective } from './theme/shared/full-screen/toggle-full-screen';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { TooltipModule } from 'ngx-bootstrap/tooltip';


/* Menu Items */
import { NavigationItem } from './theme/layout/admin/navigation/navigation';
import { NgbButtonsModule, NgbDropdownModule, NgbTabsetModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { MarkasdeliveredComponent } from '../app/markasdelivered/markasdelivered/markasdelivered.component';
import { ExcelService } from './excel.service';
import { DatePipe } from '@angular/common';
import { EnablenotificationRoutingModule } from './trackpayout/administrator/enablenotification/enablenotification-routing.module';
//import { QuotationstatusfeedbackComponent } from './quotationstatusfeedback/quotationstatusfeedback.component';
import { QuotationstatusComponent } from '../app/quotationstatus/quotationstatus.component';
import { BilldiscountResponseComponent } from './billdiscount-response/billdiscount-response.component';
import { LegalsupportResponseComponent } from './legalsupport-response/legalsupport-response.component';

//import { ContextMenuModule } from './context-menu/context-menu.module';
 
@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    NavigationComponent,
    NavContentComponent,
    NavGroupComponent,
    NavCollapseComponent,
    NavItemComponent,
    NavBarComponent,
    NavLeftComponent,
    NavSearchComponent,
    NavRightComponent,
    ConfigurationComponent,
    ToggleFullScreenDirective,
    MarkasdeliveredComponent,QuotationstatusComponent, BilldiscountResponseComponent, LegalsupportResponseComponent
   // QuotationstatusfeedbackComponent,
    
  
    
  
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    NgbModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbButtonsModule,
    NgbTabsetModule,
    NgIdleKeepaliveModule,
    FormsModule,
    ModalModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    AngularMultiSelectModule,
    Ng2SearchPipeModule,
    TooltipModule,
     
  ],
  providers: [NavigationItem,
    ExcelService,DatePipe,],
  bootstrap: [AppComponent]
})
export class AppModule { }
