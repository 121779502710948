<!-- <a href="javascript:" class="pop-search" (click)="isSearch = true" title="Search invoices"><i
    class="feather icon-search"></i></a>
<div class="search-bar wd_mrgl" *ngIf="isSearch">
  <input type="text" class="form-control border-0 shadow-none rounded_" placeholder="Search Here..."
    (keyup.enter)="search($event);">
  <button type="button" class="close" aria-label="Close" (click)="isSearch = false">
    <span aria-hidden="true">&times;</span>
  </button>
</div> -->

<a href="javascript:" class="pop-search" (click)="isSearch = true" title="Search invoices">
  <i style="color: white;" class="feather icon-search"></i>
</a>
<div id="form-control-bg-wd_mrgl_rounded_" class="search-bar wd_mrgl rounded_" style="position: relative;
top: -55px;
width: 250px !important;
left: -130px;" *ngIf="isSearch">
  <input type="text" id="form-control-height" class="form-control border-0 shadow-none rounded_ msearch"
    placeholder="Search By Invoice No." (keyup.enter)="search($event);">
  <button type="button" class="close" aria-label="Close" (click)="isSearch = false">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<style>
  #form-control-bg-wd_mrgl_rounded_ {
    /* background-color:#2c3e50; */
    background-color: transparent;
  }

  #form-control-height {
    height: 45px;
  }
</style>