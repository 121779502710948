import { Injectable } from '@angular/core';
import { ExportToCsv } from 'export-to-csv';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { isNull } from 'util';

const options = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useTextFile: false,
  filename: 'Report',
  useBom: true,
  useKeysAsHeaders: false,
  headers: ['ID', 'CUSTOMER NAME', 'MORE RECEIPENT', 'CATEGORY NAME', 'PO NUMBER', 'PO DATE', 'INVOICE NUMBER',
    'INVOICE DATE', 'INVOICE AMOUNT', 'IGST', 'SGST', 'CGST', 'DESCRIPTION', 'PAYMENT TERM (in days)',
    'PAYMENT DUE DATE', 'START REMINDER FROM', 'PAYMENT STATUS', 'PAYMENT TYPE', 'PAYMENT DATE',
    'REMAING DUE DAYS', 'ATTACHMENT (1 for YES / 0 for NO)']
};
const csvExporter = new ExportToCsv(options);

const companyCustomer = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useTextFile: false,
  filename: 'Company Customer Report',
  useBom: true,
  useKeysAsHeaders: false,
  headers: ['COMPANY NAME', 'SUBSCRIPTION ID', 'INTEGRATION TYPE', 'SUBSCRIPTION START', 'SUBSCRIPTION END',
    'REMAINING DAYS', 'CONTACT NO', 'COMPANY CATEGORY', 'COMPANY EMAIL']
};
const companyCustomerCSVExporter = new ExportToCsv(companyCustomer);

//Tally 

const Tallyoptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useTextFile: false,
  filename: 'Report',
  useBom: true,
  useKeysAsHeaders: false,
  headers: ['VOUCHER NUMBER', 'VOUCHER TYPE', 'LEDGER NAME', 'AMOUNT', 'DATE']
};
const csvTallyExporter = new ExportToCsv(Tallyoptions);

const Paymentoptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useTextFile: false,
  filename: 'PaymentReport',
  useBom: true,
  useKeysAsHeaders: false,
  headers: ['DATE', 'VOUCHER NUMBER', 'VOUCHER TYPE', 'LEDGER NAME', 'DEBIT AMOUNT', 'CREDIT AMOUNT']
};
const csvPaymentExporter = new ExportToCsv(Paymentoptions);

const Purchaseoptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useTextFile: false,
  filename: 'PurchaseReport',
  useBom: true,
  useKeysAsHeaders: false,
  headers: ['DATE', 'VOUCHER NUMBER', 'VOUCHER TYPE', 'LEDGER ID', 'IGST AMOUNT', 'CGST AMOUNT', 'SGST AMOUNT', 'TOTAL AMOUNT']
};
const csvPurchaseExporter = new ExportToCsv(Purchaseoptions);

const Journaloptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useTextFile: false,
  filename: 'JournalReport',
  useBom: true,
  useKeysAsHeaders: false,
  headers: ['DATE', 'VOUCHER NUMBER', 'VOUCHER TYPE', 'LEDGER NAME', 'DEBIT AMOUNT', 'CREDIT AMOUNT']
};
const csvJournalExporter = new ExportToCsv(Journaloptions);

const Receiptoptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useTextFile: false,
  filename: 'ReceiptReport',
  useBom: true,
  useKeysAsHeaders: false,
  headers: ['DATE', 'VOUCHER NUMBER', 'VOUCHER TYPE', 'LEDGER NAME', 'DEBIT AMOUNT', 'CREDIT AMOUNT']
};
const csvReceiptExporter = new ExportToCsv(Receiptoptions);

const Groupoptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useTextFile: false,
  filename: 'GroupOutstandingReport',
  useBom: true,
  useKeysAsHeaders: false,
  headers: ['PARTICULARS', 'OPENING AMOUNT', 'DEBIT AMOUNT', 'CREDIT AMOUNT', 'CLOSING AMOUNT']
};
const csvGroupExporter = new ExportToCsv(Groupoptions);

const DebitNoteoptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useTextFile: false,
  filename: 'DebitNoteReport',
  useBom: true,
  useKeysAsHeaders: false,
  headers: ['DATE', 'VOUCHER NUMBER', 'VOUCHER TYPE', 'LEDGER NAME', 'AMOUNT']
};
const csvDebitNoteExporter = new ExportToCsv(DebitNoteoptions);

const CreditNoteoptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useTextFile: false,
  filename: 'CreditNoteReport',
  useBom: true,
  useKeysAsHeaders: false,
  headers: ['DATE', 'VOUCHER NUMBER', 'VOUCHER TYPE', 'LEDGER NAME', 'AMOUNT']
};
const csvCreditNoteExporter = new ExportToCsv(CreditNoteoptions);

const LedgerTallyOptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useTextFile: false,
  filename: 'LedgerReport',
  useBom: true,
  useKeysAsHeaders: false,
  headers: ['LEDGER NAME', 'LEDGER OPENING BALANCE', 'CURRENT DEDIT AMOUNT', 'CURRENT CREDIT AMOUNT', 'LEDGER CLOSING BALANCE']
};
const csvLedgerTallyExporter = new ExportToCsv(LedgerTallyOptions);

const SaleTallyOptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useTextFile: false,
  filename: 'SaleReport',
  useBom: true,
  useKeysAsHeaders: false,
  headers: ['DATE', 'VOUCHER NUMBER', 'VOUCHER TYPE', 'PARTY NAME', 'IGST AMOUNT', 'CGST AMOUNT', 'SGST AMOUNT', 'TOTAL AMOUNT']
};
const csvSaleTallyExporter = new ExportToCsv(SaleTallyOptions);

const BillTallyOptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useTextFile: false,
  filename: 'BillReport',
  useBom: true,
  useKeysAsHeaders: false,
  headers: ['STATUS', 'LEDGER NAME', 'BILL TYPE', 'VOUCHER TYPE', 'BILL DATE', 'BILL NAME', 'BILL DUE DATE', 'BILL AMOUNT', 'BILL BALANCE AMOUNT', 'LAST DATE OF ADJ.', 'AVG. PAYMENT DAYS']
};
const csvTallyBillExporter = new ExportToCsv(BillTallyOptions);

const UpComingDueBillTallyOptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useTextFile: false,
  filename: 'BillReport',
  useBom: true,
  useKeysAsHeaders: false,
  headers: ['LEDGER NAME', 'BILL TYPE', 'VOUCHER TYPE', 'BILL DATE', 'BILL NAME', 'BILL DUE DATE', 'BILL AMOUNT', 'BILL BALANCE AMOUNT']
};
const csvUpComingDueBillTallyBillExporter = new ExportToCsv(UpComingDueBillTallyOptions);


const BillTallyClearedOptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useTextFile: false,
  filename: 'ClearedBillReport',
  useBom: true,
  useKeysAsHeaders: false,
  headers: ['LEDGER NAME', 'BILL TYPE', 'VOUCHER TYPE', 'BILL DATE', 'BILL NAME', 'BILL DUE DATE', 'BILL AMOUNT', 'LAST DATE OF ADJ.', 'AVG. PAYMENT DAYS']
};
const csvTallyBillClearedExporter = new ExportToCsv(BillTallyClearedOptions);

const BillDueDayOptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useTextFile: false,
  filename: 'BillReport',
  useBom: true,
  useKeysAsHeaders: false,
  headers: ['LEDGER NAME', 'BILL TYPE', 'BILL DATE', 'BILL NAME', 'BILL DUE DATE', 'BILL AMOUNT', 'BILL BALANCE AMOUNT']
};
const csvBillDueDayExporter = new ExportToCsv(BillDueDayOptions);

const MonthPurchaseoptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useTextFile: false,
  filename: 'PurchaseReport',
  useBom: true,
  useKeysAsHeaders: false,
  headers: ['DATE', 'VOUCHER NUMBER', 'VOUCHER TYPE', 'PARTY NAME', 'GSTIN No', 'NET AMOUNT', 'IGST AMOUNT', 'CGST AMOUNT', 'SGST AMOUNT', 'TOTAL AMOUNT']
};
const csvMonthPurchaseExporter = new ExportToCsv(MonthPurchaseoptions);

const MonthSaleTallyOptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useTextFile: false,
  filename: 'SaleReport',
  useBom: true,
  useKeysAsHeaders: false,
  headers: ['DATE', 'VOUCHER NUMBER', 'VOUCHER TYPE', 'PARTY NAME', 'GSTIN No', 'NET AMOUNT', 'IGST AMOUNT', 'CGST AMOUNT', 'SGST AMOUNT', 'TOTAL AMOUNT']
};
const csvMonthSaleTallyExporter = new ExportToCsv(MonthSaleTallyOptions);

// const allInvoices = {
//   fieldSeparator: ',',
//   quoteStrings: '"',
//   decimalSeparator: '.',
//   showLabels: true,
//   useTextFile: false,
//   filename: 'Report',
//   useBom: true,
//   useKeysAsHeaders: false,
//   headers: ['ID', 'CUSTOMER NAME', 'CATEGORY NAME', 'PO NUMBER','PO DATE', 'INVOICE NUMBER',
//     'INVOICE DATE', 'INVOICE AMOUNT', 'IGST', 'SGST', 'CGST', 'DESCRIPTION',
//      'PAYMENT STATUS', 'PAYMENT TYPE', 'PAYMENT DATE']
// };

// const csvExporterForAllInvouce = new ExportToCsv(allInvoices);

const ageingOptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useTextFile: false,
  filename: 'Report',
  useBom: true,
  useKeysAsHeaders: false,
  headers: ['CUSTOMER NAME', 'NOT_DUE', '0-30 Days', '31-60 Days', '61-90 Days', '91-120 Days', '121-180 Days', '181-365 Days', 'Abv 365 Days',
    'TOTAL',]
};
const ageingExporter = new ExportToCsv(ageingOptions);

@Injectable({
  providedIn: 'root'
})
export class ExportToCsvService {

  constructor(private datePipe: DatePipe) { }

  public exportCSV(data) {

    let exportArray = [];

    data.forEach(element => {
      let tempObject = {
        id: element.id,
        customer_name: element.customer_master.customer_name,
        more_receipent: element.more_receipents,
        category_name: element.category_master.category_name,
        po_no: element.po_no,
        po_date: this.datePipe.transform(element.po_date),
        invoice_no: element.invoice_no,
        invoice_date: this.datePipe.transform(element.invoice_date),
        invoice_amount: element.invoice_amount,
        igst: element.igst,
        sgst: element.sgst,
        cgst: element.cgst,
        description: element.description,
        payment_term: element.payment_term,
        payment_due_date: this.datePipe.transform(element.payment_due_date),
        start_reminder_from: element.start_reminder_from,
        payment_status: element.payment_status,
        payment_type: element.payment_type,
        payment_date: this.datePipe.transform(element.payment_date),
        remaining_days: element.remaining_days,
        attachments: element.is_attachment
      };
      exportArray.push(tempObject)
    });

    exportArray.forEach(function (element) {
      for (var key in element) {
        if (element[key] == null) {
          element[key] = '';
        }
      }
    })
    csvExporter.generateCsv(exportArray);
  }

  public exportTallyCSV(data) {

    let exportArray = [];

    data.forEach(element => {
      let tempObject = {
        voucher_number: element.voucher_number,
        voucher_type: element.voucher_type,
        ledger_name: element.tally_ledger_detail.ledger_name,
        amount: element.amount,
        date: this.datePipe.transform(element.date)
      };
      exportArray.push(tempObject)
    });

    exportArray.forEach(function (element) {
      for (var key in element) {
        if (element[key] == null) {
          element[key] = '';
        }
      }
    })

    csvTallyExporter.generateCsv(exportArray);
  }

  public exportDebitNoteTallyCSV(data) {
    let exportArray = [];

    data.forEach(element => {
      let tempObject = {
        date: this.datePipe.transform(element.date),
        voucher_number: element.voucher_number,
        voucher_type: element.voucher_type,
        ledger_name: element.tally_ledger_detail.ledger_name,
        amount: element.amount,
      };
      exportArray.push(tempObject)
    });

    exportArray.forEach(function (element) {
      for (var key in element) {
        if (element[key] == null) {
          element[key] = '';
        }
      }
    })

    csvDebitNoteExporter.generateCsv(exportArray);
  }

  public exportCreditNoteTallyCSV(data) {
    let exportArray = [];

    data.forEach(element => {
      let tempObject = {
        voucher_number: element.voucher_number,
        voucher_type: element.voucher_type,
        ledger_name: element.tally_ledger_detail.ledger_name,
        amount: element.amount,
        date: this.datePipe.transform(element.date)
      };
      exportArray.push(tempObject)
    });

    exportArray.forEach(function (element) {
      for (var key in element) {
        if (element[key] == null) {
          element[key] = '';
        }
      }
    })

    csvCreditNoteExporter.generateCsv(exportArray);
  }

  public exportLedgerTallyCSV(data) {
    let exportArray = [];

    data.forEach(element => {
      let tempObject = {
        ledger_name: element.ledger_name,
        ledger_opening_balance: element.opening_balance,
        current_debit_amount: element.debit_balance,
        current_credit_amount: element.credit_balance,
        ledger_closing_balance: element.closing_balance
      };
      exportArray.push(tempObject)
    });

    exportArray.forEach(function (element) {
      for (var key in element) {
        if (element[key] == null) {
          element[key] = '';
        }
      }
    })
    csvLedgerTallyExporter.generateCsv(exportArray);
  }

  public exportSaleTallyCSV(data) {
    let exportArray = [];

    data.forEach(element => {
      let tempObject = {
        date: this.datePipe.transform(element.date),
        voucher_number: element.voucher_number,
        voucher_type: element.voucher_type,
        ledger_name: element.tally_ledger_detail.ledger_name,
        igst_balance: element.igst_amount,
        cgst_amount: element.cgst_amount,
        sgst_amount: element.sgst_amount,
        amount: element.amount
      };
      exportArray.push(tempObject)
    });

    exportArray.forEach(function (element) {
      for (var key in element) {
        if (element[key] == null) {
          element[key] = '';
        }
      }
    })
    csvSaleTallyExporter.generateCsv(exportArray);
  }

  public exportTallyBillCSV(data) {
    let exportArray = [];

    data.forEach(element => {
      let tempObject = {
        bill_pay: element.bill_pay,
        ledger_name: element.tally_ledger_detail.ledger_name,
        bill_type: element.bill_type,
        voucher_type: element.voucher_type,
        bill_date: this.datePipe.transform(element.bill_date),
        bill_name: element.bill_name,
        bill_due_date: this.datePipe.transform(element.bill_due_date),
        bill_amount: element.bill_amt,
        balance_amount: element.balance_amount,
        last_payment_date: element.last_payment_date,
        avg_payment_days: element.avg_payment_days
      };
      exportArray.push(tempObject)
    });

    exportArray.forEach(function (element) {
      for (var key in element) {
        if (element[key] == null) {
          element[key] = '';
        }
      }
    })
    csvTallyBillExporter.generateCsv(exportArray);
  }

  public exportTallyUpComingDueBillCSV(data) {
    let exportArray = [];

    data.forEach(element => {
      let tempObject = {
        //status: 'Unpaid',
        ledger_name: element.tally_ledger_detail.ledger_name,
        bill_type: element.bill_type,
        voucher_type: element.voucher_type,
        bill_date: this.datePipe.transform(element.bill_date),
        bill_name: element.bill_name,
        bill_due_date: this.datePipe.transform(element.bill_due_date),
        bill_amount: element.bill_amount,
        balance_amount: element.bill_balance_amount,
        //last_payment_date:element.last_payment_date
      };
      exportArray.push(tempObject)
    });

    exportArray.forEach(function (element) {
      for (var key in element) {
        if (element[key] == null) {
          element[key] = '';
        }
      }
    })
    csvUpComingDueBillTallyBillExporter.generateCsv(exportArray);
  }

  public exportTallyBillClearedCSV(data) {
    let exportArray = [];

    data.forEach(element => {
      let tempObject = {
        ledger_name: element.tally_ledger_detail.ledger_name,
        bill_type: element.bill_type,
        voucher_type: element.voucher_type,
        bill_date: this.datePipe.transform(element.bill_date),
        bill_name: element.bill_name,
        bill_due_date: this.datePipe.transform(element.bill_due_date),
        bill_amount: element.bill_amount,
        last_payment_date: element.last_payment_date,
        avg_payment_days: element.avg_payment_days
      };
      exportArray.push(tempObject)
    });

    exportArray.forEach(function (element) {
      for (var key in element) {
        if (element[key] == null) {
          element[key] = '';
        }
      }
    })
    csvTallyBillClearedExporter.generateCsv(exportArray);
  }

  public exportBillDueDayCSV(data) {
    let exportArray = [];

    data.forEach(element => {
      let tempObject = {
        ledger_name: element.tally_ledger_detail.ledger_name,
        bill_type: element.bill_type,
        bill_date: this.datePipe.transform(element.bill_date),
        bill_name: element.bill_name,
        bill_due_date: this.datePipe.transform(element.bill_due_date),
        bill_amount: element.bill_amount,
        balance_amount: element.balance_amount
      };
      exportArray.push(tempObject)
    });

    exportArray.forEach(function (element) {
      for (var key in element) {
        if (element[key] == null) {
          element[key] = '';
        }
      }
    })
    csvBillDueDayExporter.generateCsv(exportArray);
  }

  public exportCreditNoteCSV(data) {
    let exportArray = [];

    data.forEach(element => {
      let tempObject = {
        date: this.datePipe.transform(element.date),
        voucher_number: element.voucher_number,
        voucher_type: element.voucher_type,
        ledger_name: element.tally_ledger_detail.ledger_name,
        // amount: element.amount
        // debit_amount: element.debit_amount,
        credit_amount: element.credit_amount,
      };
      exportArray.push(tempObject)
    });

    exportArray.forEach(function (element) {
      for (var key in element) {
        if (element[key] == null) {
          element[key] = '';
        }
      }
    })
    csvCreditNoteExporter.generateCsv(exportArray);
  }

  public exportDebitNoteCSV(data) {
    let exportArray = [];

    data.forEach(element => {
      let tempObject = {
        date: this.datePipe.transform(element.date),
        voucher_number: element.voucher_number,
        voucher_type: element.voucher_type,
        ledger_name: element.tally_ledger_detail.ledger_name,
        // amount: element.amount
        debit_amount: element.debit_amount,
        // credit_amount: element.credit_amount,
      };
      exportArray.push(tempObject)
    });

    exportArray.forEach(function (element) {
      for (var key in element) {
        if (element[key] == null) {
          element[key] = '';
        }
      }
    })
    csvDebitNoteExporter.generateCsv(exportArray);
  }

  public exportGroupCSV(data) {
    let exportArray = [];

    data.forEach(element => {
      let tempObject = {
        particulars: element.group_name,
        opening_amount: element.opening_balance,
        debit_amount: element.debit_balance,
        credit_amount: element.credit_balance,
        closing_amount: element.closing_balance
      };
      exportArray.push(tempObject)
    });

    exportArray.forEach(function (element) {
      for (var key in element) {
        if (element[key] == null) {
          element[key] = '';
        }
      }
    })
    csvGroupExporter.generateCsv(exportArray);
  }

  public exportReceiptCSV(data) {
    let exportArray = [];

    data.forEach(element => {
      let tempObject = {
        date: this.datePipe.transform(element.date),
        voucher_number: element.voucher_number,
        voucher_type: element.voucher_type,
        ledger_name: element.tally_ledger_detail.ledger_name,
        // amount: element.amount,
        debit_amount: element.debit_amount,
        credit_amount: element.credit_amount,
      };
      exportArray.push(tempObject)
    });

    exportArray.forEach(function (element) {
      for (var key in element) {
        if (element[key] == null) {
          element[key] = '';
        }
      }
    })
    csvReceiptExporter.generateCsv(exportArray);
  }

  public exportJournalCSV(data) {
    let exportArray = [];

    data.forEach(element => {
      let tempObject = {
        date: this.datePipe.transform(element.date),
        voucher_number: element.voucher_number,
        voucher_type: element.voucher_type,
        ledger_name: element.tally_ledger_detail.ledger_name,
        // amount: element.amount
        debit_amount: element.debit_amount,
        credit_amount: element.credit_amount,
      };
      exportArray.push(tempObject)
    });

    exportArray.forEach(function (element) {
      for (var key in element) {
        if (element[key] == null) {
          element[key] = '';
        }
      }
    })
    csvJournalExporter.generateCsv(exportArray);
  }

  public exportPurchaseCSV(data) {
    let exportArray = [];

    data.forEach(element => {
      let tempObject = {
        date: this.datePipe.transform(element.date),
        voucher_number: element.voucher_number,
        voucher_type: element.voucher_type,
        ledger_name: element.tally_ledger_detail.ledger_name,
        igst_amount: element.igst_amount,
        cgst_amount: element.cgst_amount,
        sgst_amount: element.sgst_amount,
        amount: element.amount
      };
      exportArray.push(tempObject)
    });

    exportArray.forEach(function (element) {
      for (var key in element) {
        if (element[key] == null) {
          element[key] = '';
        }
      }
    })
    csvPurchaseExporter.generateCsv(exportArray);
  }

  public exportPaymentCSV(data) {
    let exportArray = [];

    data.forEach(element => {
      let tempObject = {
        date: this.datePipe.transform(element.date),
        voucher_number: element.voucher_number,
        voucher_type: element.voucher_type,
        ledger_name: element.tally_ledger_detail.ledger_name,
        // amount: element.amount
        debit_amount: element.debit_amount,
        credit_amount: element.credit_amount,
      };
      exportArray.push(tempObject)
    });

    exportArray.forEach(function (element) {
      for (var key in element) {
        if (element[key] == null) {
          element[key] = '';
        }
      }
    })
    csvPaymentExporter.generateCsv(exportArray);
  }

  public exportcompanyCustomerCSV(data) {
    let exportArray = [];
    data.forEach(element => {
      let tempObject = {
        company_name: element.company_name,
        subscription_id: element.subscription_id,
        integration_type: element.company_integration_type,
        subscription_start: this.datePipe.transform(element.subscription_start),
        subscription_end: this.datePipe.transform(element.subscription_end),
        remaining_days: element.remaining_days,
        company_contact: element.company_contact,
        company_category: element.company_category,
        company_email: element.company_email
      };
      exportArray.push(tempObject);
    });
    exportArray.forEach(function (element) {
      for (var key in element) {
        if (element[key] == null) {
          element[key] = '';
        }
      }
    })
    companyCustomerCSVExporter.generateCsv(exportArray);
  }

  public exportAgeingCSV(data, type) {

    let exportArray = [];
    if (type == 'byamount') {
      let counter = 0;
      data.forEach(element => {
        let tempObject = {
          customer_name: element['ledger_name'],
          Not_due: element['not_due'],
          days_0_30: element['thiry_days'],
          days_31_60: element['sixty_days'],
          days_61_90: element['ninty_days'],
          days_91_120: element['one_twenty_days'],
          days_121_180: element['one_eighty_days'],
          days_180_365: element['three_sixty_five_days'],
          days_365_plus: element['abv_three_sixty_six_days'],
          total_amount: element['total_amount'],
        };
        exportArray.push(tempObject)
        counter++;
      });
    } else {
      let counter = 0;
      data.forEach(element => {
        let tempObject = {
          customer_name: element['ledger_name'],
          Not_due: element['not_due'],
          days_0_30: element['thiry_days'],
          days_31_60: element['sixty_days'],
          days_61_90: element['ninty_days'],
          days_91_120: element['one_twenty_days'],
          days_121_180: element['one_eighty_days'],
          days_180_365: element['three_sixty_five_days'],
          days_365_plus: element['abv_three_sixty_six_days'],
          total_amount: element['total_amount'],
        };
        exportArray.push(tempObject);
        counter++;
      });
    }
    exportArray.forEach(function (element) {
      for (var key in element) {
        if (element[key] == null) {
          element[key] = '0';
        }
      }
    })
    ageingExporter.generateCsv(exportArray);
  }


  public exportMonthSaleTallyCSV(data) {
    let exportArray = [];

    data.forEach(element => {
      let tempObject = {
        date: this.datePipe.transform(element.date),
        voucher_number: element.voucher_number,
        voucher_type: element.voucher_type,
        ledger_name: element.tally_ledger_detail.ledger_name,
        gstin: element.buyer_gstin_number,
        net: element.invoice_amount,
        igst_balance: element.igst_amount,
        cgst_amount: element.cgst_amount,
        sgst_amount: element.sgst_amount,
        amount: element.amount
      };
      exportArray.push(tempObject)
    });

    exportArray.forEach(function (element) {
      for (var key in element) {
        if (element[key] == null) {
          element[key] = '';
        }
      }
    })
    csvMonthSaleTallyExporter.generateCsv(exportArray);
  }

  public exportMonthPurchaseCSV(data) {
    let exportArray = [];

    data.forEach(element => {
      let tempObject = {
        date: this.datePipe.transform(element.date),
        voucher_number: element.voucher_number,
        voucher_type: element.voucher_type,
        ledger_name: element.tally_ledger_detail.ledger_name,
        gstin: element.buyer_gstin_number,
        net: element.invoice_amount,
        igst_amount: element.igst_amount,
        cgst_amount: element.cgst_amount,
        sgst_amount: element.sgst_amount,
        amount: element.amount
      };
      exportArray.push(tempObject)
    });

    exportArray.forEach(function (element) {
      for (var key in element) {
        if (element[key] == null) {
          element[key] = '';
        }
      }
    })
    csvMonthPurchaseExporter.generateCsv(exportArray);
  }

}
