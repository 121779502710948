import { Component, NgZone, OnInit } from '@angular/core';
import { NextConfig } from '../../../app-config';
import { Location } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { ReportService } from 'src/app/trackpayout/report/report.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  public flatConfig: any;
  public navCollapsed: boolean;
  public navCollapsedMob: boolean;
  public windowWidth: number;
  name = 'Angular 6';
  p: number = 1;
  /* pagination Info */
  pageSize = 10;
  pageNumber = 1;

  itemsPerPage = 10;
  config: any = {
  
    itemsPerPage: this.itemsPerPage,
    currentPage: 1,
    totalItems: 0
  };
  noData = false;
  removeSuccess = false;
  removeError = false;
  InvoiceId = 0;
  Invoiceindex = 0;

  is_edit = false;
  is_delete = false;

  Invoices = [];

  constructor(private zone: NgZone, 
    private location: Location,
    private formBuilder: FormBuilder,
    private reportService: ReportService,
    private modalService: NgbModal,
    private ngxService: NgxSpinnerService,
    ) {
    this.flatConfig = NextConfig.config;
    let currentURL = this.location.path();
    const baseHerf = this.location['_baseHref'];
    if (baseHerf) {
      currentURL = baseHerf + this.location.path();
    }

    this.windowWidth = window.innerWidth;

    if (currentURL === baseHerf + '/layout/collapse-menu'
      || currentURL === baseHerf + '/layout/box'
      || (this.windowWidth >= 992 && this.windowWidth <= 1024)) {
      this.flatConfig.collapseMenu = false;
    }

    this.navCollapsed = (this.windowWidth >= 992) ? this.flatConfig.collapseMenu : false  ;
    this.navCollapsedMob = false;

  }

  ngOnInit() {
    if (this.windowWidth < 992) {
      this.flatConfig.layout = 'vertical';
      setTimeout(() => {
        document.querySelector('.pcoded-navbar').classList.add('menupos-static');
        (document.querySelector('#nav-ps-flat-able') as HTMLElement).style.maxHeight = '100%'; // 100% amit
      }, 500);
    }

    this.readInvoices();
  }

  messages = [
    'Message alert is real',
  ]
  remainingDays(date) {
    let date1 = new Date(date);
    let date2 = new Date();
    var diff = (date1.getTime() - date2.getTime());
    return Math.ceil(diff / (1000 * 3600 * 24));
  }
  readInvoices() {
    this.ngxService.show();
    this.reportService.getByDueDays(1, 15).subscribe(result => {
      if (result['status'] === 200) {
        this.Invoices = result['data'];
        if (this.Invoices.length <= 0) {
          this.noData = true;
          setTimeout(() => {
            this.noData = false;
          }, 10000);
        }
        for (var i = 0; i < this.Invoices.length; i++) {
          this.Invoices[i]['remaining_days'] = this.remainingDays(this.Invoices[i]['payment_due_date']);
          this.Invoices[i]['invoice_date'] = this.Invoices[i]['invoice_date'].substring(0, 10);
          this.Invoices[i]['payment_due_date'] = this.Invoices[i]['payment_due_date'].substring(0, 10);
          if (this.Invoices[i]['payment_date'] != null) {
            this.Invoices[i]['payment_date'] = this.Invoices[i]['payment_date'].substring(0, 10);
          }
        }
        this.ngxService.hide();
      } else {
        this.ngxService.hide();
      }
    }, error => {

    });
  }
  pageChanged(event) {
    this.config.currentPage = event;
  }

  itemsPerPageChanged() {
    this.config.itemsPerPage = this.itemsPerPage;
  }
  navMobClick() {
    if (this.windowWidth < 992) {
      if (this.navCollapsedMob && !(document.querySelector('app-navigation.pcoded-navbar').classList.contains('mob-open'))) {
        this.navCollapsedMob = !this.navCollapsedMob;
        setTimeout(() => {
          this.navCollapsedMob = !this.navCollapsedMob;
        }, 100);
      } else {
        this.navCollapsedMob = !this.navCollapsedMob;
      }
    }
  }

}
