import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuotationsService {

  constructor() { }

  public EventValue = new Subject<any>();


  EventValueChange(data) {  
 
    //this.EventValue.complete()
    this.EventValue.next(data)
    console.log("Event 1 ==>>>>", this.EventValue);    
   
  }

  EventValueclear() {  
       this.EventValue.observers.forEach(observer => {
      observer.complete();
    });
  }
}
