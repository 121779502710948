import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { login_cred } from '../../login-creds';

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  TOKEN = 'Bearer ' + window.localStorage.getItem('token');
  constructor(private httpClient: HttpClient) { }

  getTallyLedgers(offset: number = 0, limit: number = 10, search_query: string = "", search_col: string = "") {
    const type = 'TaskReport';
    const ledger_parent = 'Sundry Debtors';
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/tallytcpV2/masters/get_all_ledger/?company_id=` + companyId + '&user_id=' + userId + `&offset=` + offset + `&limit=` + limit + `&search_query=` + search_query + `&search_col=` + search_col + `&type=` + type + `&ledger_parent=` + ledger_parent, { headers: headers });
  }

  getTaskHistory(offset: number, limit: number, ledger_id: string = "", reminder_date: string = "", search_query: string = "", search_col: string = "") {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/task/get-task/?company_id=` + companyId + '&user_id=' + userId + `&offset=` + offset + `&limit=` + limit + `&search_query=` + search_query + `&search_col=` + search_col + `&ledger_id=` + ledger_id + `&followup_by=` + userId + `&reminder_date=` + reminder_date, { headers: headers });
  }
  getActiveTask(offset: number = 0, limit: number = 10, ledger_id: string = "", reminder_date: string = "", search_query: string = "", search_col: string = "") {
    //console.log(search_col, search_query)
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.get(`${environment.api_rul}/task/get-active-task/?company_id=` + companyId + '&user_id=' + userId + `&followup_by=` + userId, { headers: headers });
  }

  setTask(formData: any) {
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.post(`${environment.api_rul}/task/set-task`, formData, { headers: headers });
  }
  send_email(data) {
    const companyId = window.localStorage.getItem('company_id');
    const userId = window.localStorage.getItem('user_id');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.TOKEN });
    return this.httpClient.post(`${environment.api_rul}/task/sent_email/?company_id=` + companyId + '&user_id=' + userId, data, { headers: headers });
  }

}
