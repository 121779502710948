import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nvePveConverter'
})
export class NvePveConverterPipe implements PipeTransform {

  transform(value: number) {
    return value * -1;
  }

}
