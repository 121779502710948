<div class="container" *ngIf="this.flatConfig.layout === 'horizontal' && this.flatConfig.subLayout === 'horizontal-2'; else mainHeader">
  <ng-container *ngTemplateOutlet="mainHeader"></ng-container>
</div>
<ng-template #mainHeader style="background-color: #003366;">
  <div class="m-header">
    <a href="javascript:" class="mobile-menu" id="mobile-collapse" (click)="navCollapse()"><span></span></a>
    <a [routerLink]="['/dashboard/analytics']" class="b-brand" style="font-size: larger;">
      <!-- TRACKPAYOUT -->
      <img class="mlogo" src="assets/images/TPO_Logo2 png.png" alt="">
      <!-- <img id="main-logo" src="assets/images/logo.png" alt="" class="logo">
      <img src="assets/images/logo-icon.png" alt="" class="logo-thumb"> -->
    </a>
    <a class="mob-toggler" [ngClass]="{'on' : this.menuClass}" href="javascript:" (click)="toggleMobOption()"><i class="feather icon-more-vertical"></i></a>
  </div>
  <div class="collapse navbar-collapse" [style.display]="this.collapseStyle">
    <app-nav-left class="mr-auto"></app-nav-left>
    <app-nav-right class="ml-auto"></app-nav-right>
  </div>
</ng-template>
