import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { MarkasdeliveredService } from '../markasdelivered.service';
import { login_cred } from 'src/app/login-creds';


@Component({
  selector: 'app-markasdelivered',
  templateUrl: './markasdelivered.component.html',
  styleUrls: ['./markasdelivered.component.scss']
})
export class MarkasdeliveredComponent implements OnInit {
  markasdeliveredFormGroup: FormGroup;
  submitted = false;
  deliveredformSuccess = false;
  deliveredformInvalid = false;
  deliveredformExist = false;
  deliveredformError = false;
  invoiceno: any = '';
  companyid: any = '';

  constructor(
    private formBuilder: FormBuilder,
    private markasdelivered: MarkasdeliveredService,
    private route: ActivatedRoute,
    private ngxService: NgxSpinnerService,

  ) { }

  ngOnInit(): void {
    this.markasdeliveredFormGroup = this.formBuilder.group({
      received_txt: ['', Validators.required],
      company_id: [],
      invoice_no: ['']
    });
    this.route.queryParams.subscribe(params => {
      this.invoiceno = params['invoice_no'];
      this.companyid = params['company_id'];
    })


    this.getInvoice_no();

  }

  acknowledgedelivery() {
    this.submitted = true;
    if (this.markasdeliveredFormGroup.valid) {

      this.ngxService.show();
      
      this.markasdeliveredFormGroup.controls['company_id'].setValue(this.companyid);
      this.markasdeliveredFormGroup.controls['invoice_no'].setValue(this.invoiceno);
      const formData = new FormData();

      formData.append('data', JSON.stringify(this.markasdeliveredFormGroup.value));
      this.markasdelivered.create(formData).subscribe(result => {
        
        if (result['status'] === 200) {
          this.deliveredformSuccess = true;
          this.deliveredformError = false;
          this.markasdeliveredFormGroup.reset();
          this.submitted = false;
          this.ngxService.hide();
        } else if (result['status'] === 201) {
          this.deliveredformError = true;
          this.deliveredformSuccess = false;
          this.ngxService.hide();
        } else {
          this.ngxService.hide();
        }

        setTimeout(() => { this.deliveredformError = false; this.deliveredformSuccess = false; }, 10000);

      }, error => {
        console.log('ERROR - ', error);
      });
    }


  }

  get getFormControls() {
    return this.markasdeliveredFormGroup.controls;
  }

  getInvoice_no() {
    this.ngxService.show();
    this.markasdelivered.get(this.invoiceno, this.companyid).subscribe(result => {
      if (result['status'] === 200) {
        this.ngxService.hide();
      } else if (result['status'] === 201) {
        this.deliveredformSuccess = false;
        this.deliveredformExist = true;
        this.deliveredformInvalid = false;
        this.deliveredformError = false;
        this.ngxService.hide();
      } else if (result['status'] == 202) {
        this.deliveredformSuccess = false;
        this.deliveredformExist = false;
        this.deliveredformInvalid = true;
        this.deliveredformError = false;
        this.ngxService.hide();
      }
    }, error => {
      console.log('ERROR - ', error);
    });
  }

  markAsDelivered() {
    this.markasdeliveredFormGroup.controls['company_id'].setValue(window.localStorage.getItem('company_id'));
    this.markasdeliveredFormGroup.controls['invoice_no'].setValue(this.invoiceno);
    const formData = new FormData();

    formData.append('data', JSON.stringify(this.markasdeliveredFormGroup.value));
    console.log("hellooooo mahi",formData)

    this.markasdelivered.create(this.markasdeliveredFormGroup.value).subscribe(result => {
      if (result['status'] == 200) {
        this.deliveredformError = false;
        this.deliveredformExist = false;
        this.deliveredformInvalid = false;
        this.deliveredformSuccess = true;
      } else {
        this.deliveredformError = true;
        this.deliveredformExist = false;
        this.deliveredformSuccess = false;
        this.deliveredformInvalid = false;
      }
    }, error => {
      console.log();
    })
  }


}


