import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AuthService } from './trackpayout/shared/service/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  ngOnInit() {
    // this.authService.autoLogin();
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  title = 'angular-idle-timeout';

  public modalRef: BsModalRef;

  // @ViewChild('childModal', { static: false }) childModal: ModalDirective;

  constructor(private idle: Idle, private keepalive: Keepalive,
    private router: Router, private modalService: BsModalService,
    private authService: AuthService) {

    router.events.subscribe(value => {
      // sets an idle timeout of 5 seconds, for testing purposes.
      idle.setIdle(5);
      // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
      idle.setTimeout(60 * 30);
      // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
      idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

      idle.onIdleEnd.subscribe(() => {
        this.idleState = 'No longer idle.'
        console.log(this.idleState);
        this.reset();
      });

      idle.onTimeout.subscribe(() => {
        // this.childModal.hide();
        this.idleState = 'Timed out!';
        this.timedOut = true;
        // console.log(this.idleState);
        window.localStorage.clear();
        this.router.navigate(['/auth/signin']);
      });

      idle.onIdleStart.subscribe(() => {
        this.idleState = 'You\'ve gone idle!'
        // console.log(this.idleState);
        // this.childModal.show();
      });

      idle.onTimeoutWarning.subscribe((countdown) => {
        this.idleState = 'You will time out in ' + countdown + ' seconds!'
        // console.log(this.idleState);
      });

      // sets the ping interval to 15 seconds
      keepalive.interval(15);

      keepalive.onPing.subscribe(() => this.lastPing = new Date());

      if (window.localStorage.getItem('is_authenticated')) {
        idle.watch()
        this.timedOut = false;
      } else {
        idle.stop();
      }
    });
    // this.reset();
  }



  reset() {
    this.idle.watch();
    //xthis.idleState = 'Started.';
    this.timedOut = false;
  }

  hideChildModal(): void {
    // this.childModal.hide();
  }

  stay() {
    // this.childModal.hide();
    this.reset();
  }

  logout() {
    // this.childModal.hide();
    if (confirm("Are you sure you want to logout? ")) {
      window.localStorage.clear();
      this.router.navigate(['/auth/signin']);
    }

  }



}
