import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paymentStatus'
})
export class PaymentStatusPipe implements PipeTransform {

  transform(value: number) {
    if (value === 1) {
      return 'Paid';
    } else {
      return 'Unpaid';
    }
  }

}
